import React from 'react';
import {
  ErrorBoundary as ReactErrorBoundary,
  FallbackProps,
} from 'react-error-boundary';

export const ErrorFallback: React.FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => (
  <div>
    <h1>We're sorry, but something went wrong </h1>
    <p>{error}</p>
    <button onClick={resetErrorBoundary}>Retry</button>
  </div>
);

const ErrorBoundary: React.FC = ({ children }) => {
  const handleReset = () => {
    window.location.reload();
  };

  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback} onReset={handleReset}>
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
