import React from 'react';
import { FastField, Field, FieldArray } from 'formik';

import { Project } from '../../types/project';
import { useProjects } from '../../hooks/useProjects';
import { useBrands } from '../../hooks/useBrands';

import SelectProjectToMonth from './SelectProjectToMonth';
import FormikCheckbox from '../FormikCheckbox';

type PlanMonthFormProps = {
  projects: Project[];
  nextMonth: string;
};

const PlanMonthForm: React.FC<PlanMonthFormProps> = props => {
  const projects = useProjects();
  const brands = useBrands();

  return (
    <div className="px-4 divide-y divide-gray-200 sm:px-6 space-y-6">
      <div className="space-y-4 mt-6">
        <p className="font-medium text-gray-900 select-none">
          {props.nextMonth}
        </p>

        <FastField
          label="Populate using Previous Month"
          name="populateActiveProjectsFromPreviousMonth"
          component={FormikCheckbox}
        />

        <Field
          label="Use existing values"
          name="populateHoursFromPreviousMonth"
          component={FormikCheckbox}
        />
      </div>

      {projects.data && brands.data ? (
        <FieldArray
          name="activeProjects"
          render={props => (
            <SelectProjectToMonth
              {...props}
              brands={brands.data}
              projects={projects.data}
            />
          )}
        />
      ) : null}
    </div>
  );
};

export default PlanMonthForm;
