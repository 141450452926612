import React, { FormEvent, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useQueryClient } from 'react-query';

import CardDetails from '../Checkout/CardDetails';
import Spinner from '../../Spinner';

type ChangePaymentFormProps = {
  onClose: () => void;
};

const ChangePaymentForm: React.FC<ChangePaymentFormProps> = props => {
  const stripe = useStripe();
  const elements = useElements();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState<boolean>(false);

  const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      setLoading(false);
      toast.error('Update failed! Try again!');
      return;
    }

    const { error } = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
    });

    await queryClient.refetchQueries('payment-method');

    if (error && error.message) {
      setLoading(false);
      toast.error(error.message);
      return;
    }

    props.onClose();
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="pb-4">
        <CardDetails />
      </div>
      <div className="flex pt-4">
        <button
          className="btn btn-primary ml-auto"
          type="submit"
          disabled={loading}
        >
          {loading ? (
            <>
              <Spinner className="h-5 w-5 text-white" aria-hidden="true" />
              Loading
            </>
          ) : (
            'Update'
          )}
        </button>
      </div>
    </form>
  );
};

export default ChangePaymentForm;
