import { FieldArray, Form, Formik, FormikProps } from 'formik';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/solid';
import toast from 'react-hot-toast';

import strategicPlanService from '../../services/strategicPlanService';
import { strategicPlannerKeys } from '../../config';
import {
  FormikAnswer,
  PlanDTO,
  Question,
  SPSection,
  StrategicPlan,
} from '../../types/strategic-plan';

import WYSIWYG from './Flexibles/WYSIWYG';
import Checklist from './Flexibles/Checklist';
import FourGrid from './Flexibles/FourGrid';
import Venn from './Flexibles/Venn';
import Pillars from './Flexibles/Pillars';
import TwoColLineByLine from './Flexibles/TwoColLineByLine';
import Table from './Flexibles/Table';
import MultiSelect from './Flexibles/MultiSelect';

type FlexibleContainerProps = {
  questions: Question[];
  plan: StrategicPlan;
  sections: SPSection[];
  nextSection: string;
  previousSection: string;
};

const FlexibleContainer: React.FC<FlexibleContainerProps> = props => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const { sectionId, SPId } = useParams<{ sectionId: string; SPId: string }>();

  const updateSection = useMutation<StrategicPlan, any, PlanDTO>(
    data => strategicPlanService.savePlan(SPId, data),
    {
      onSuccess: data => {
        queryClient.setQueryData(strategicPlannerKeys.plan(SPId), data);
        if (props.nextSection) {
          history.push(props.nextSection);
        }
      },
      onError: () => {
        toast.error('Something went wrong! Try again!');
      },
    }
  );
  // set default values to the answers state
  const initialAnswers: FormikAnswer[] = props.questions?.map(question => {
    let formData = {};

    // if its already answered, get it for the initial state
    const savedAnswer = props.plan.answers?.find(
      answer => answer.question === question.id
    );

    // define initial state
    // each question type can have their own structure
    switch (question.type) {
      case 'WYSIWYG':
        formData = savedAnswer
          ? savedAnswer.formData
          : {
              text: '',
            };
        break;
      case 'CHECKLIST':
        formData = savedAnswer
          ? savedAnswer.formData
          : {
              checkedItems: [],
            };
        break;
      case '4_GRID':
        formData = savedAnswer
          ? savedAnswer.formData
          : {
              // title values
              title1Val:
                question.questionSpecifics?.fourGridPrefilledValues
                  ?.title1Val ?? '',
              title2Val:
                question.questionSpecifics?.fourGridPrefilledValues
                  ?.title2Val ?? '',
              title3Val:
                question.questionSpecifics?.fourGridPrefilledValues
                  ?.title3Val ?? '',
              title4Val:
                question.questionSpecifics?.fourGridPrefilledValues
                  ?.title4Val ?? '',
              // body values
              body1Val: '',
              body2Val: '',
              body3Val: '',
              body4Val: '',
            };
        break;
      case 'VENN':
        formData = savedAnswer
          ? savedAnswer.formData
          : {
              // title values
              title1Val: '',
              title2Val: '',
              title3Val: '',
              // body values
              body1Val: '',
              body2Val: '',
              body3Val: '',
            };
        break;
      case 'PILLARS':
        formData = savedAnswer
          ? savedAnswer.formData
          : {
              roof: '',
              pillars: [''],
            };
        break;
      case '2_COL_LINE_BY_LINE': {
        if (
          question.questionSpecifics?.twoColPrefilledValues &&
          question.questionSpecifics?.twoColPrefilledValues?.length > 0
        ) {
          formData = savedAnswer
            ? savedAnswer.formData
            : {
                rows: question.questionSpecifics?.twoColPrefilledValues.map(
                  item => ({
                    col1Val: item.col1Val || '',
                    col2Val: item.col2Val || '',
                  })
                ),
              };
        } else {
          formData = savedAnswer
            ? savedAnswer.formData
            : {
                rows: [{ col1Val: '', col2Val: '' }],
              };
        }
        break;
      }
      case 'TABLE': {
        // number of cols equals (either):
        //  - length of predefined column headers (prio #1)
        //  - predefined number of columns (prio #2)
        //  - a default column number of 2 (prio #3)
        const numberOfCols =
          question.questionSpecifics?.tableHeadings?.length ??
          question.questionSpecifics?.tableColumns ??
          2;

        const cols: string[] = new Array(numberOfCols).fill('');

        if (
          question.questionSpecifics &&
          Array.isArray(question.questionSpecifics.tableHeadings) &&
          question.questionSpecifics.tableHeadings.length > 0
        ) {
          formData = savedAnswer
            ? savedAnswer.formData
            : {
                numberOfCols,
                rows: [
                  // first row of pre-defined table heading columns
                  cols.reduce(
                    (obj, current, index) => ({
                      ...obj,
                      [`col${index}Val`]:
                        question.questionSpecifics!.tableHeadings![index],
                    }),
                    {}
                  ),
                  // 2nd row of empty columns
                  cols.reduce(
                    (obj, current, index) => ({
                      ...obj,
                      [`col${index}Val`]: '',
                    }),
                    {}
                  ),
                ],
              };
        } else {
          formData = savedAnswer
            ? savedAnswer.formData
            : {
                numberOfCols,
                rows: [
                  cols.reduce(
                    (obj, current, index) => ({
                      ...obj,
                      [`col${index}Val`]: '',
                    }),
                    {}
                  ),
                ],
              };
        }
        break;
      }
      case 'MULTI_SELECT':
        formData = savedAnswer
          ? savedAnswer.formData
          : {
              selectedItems: [],
            };
        break;
      default:
        break;
    }

    return {
      questionId: question.id,
      formData: formData,
    };
  });

  const handleOnSubmit = async ({
    questions,
  }: {
    questions: FormikAnswer[];
  }) => {
    try {
      await toast.promise(
        updateSection.mutateAsync({
          finishedSections: [sectionId],
          answers: questions.map(question => ({
            section: sectionId,
            question: question.questionId,
            formData: question.formData,
          })),
        }),
        {
          loading: 'Updating..',
          success: (
            <span>
              The plan has been <b>successfully</b> updated!
            </span>
          ),
          error: (
            <span>
              <b>Error</b> occurred during updating.
            </span>
          ),
        }
      );
    } catch (e) {
      //
    }
  };

  return (
    <Formik
      initialValues={{ questions: initialAnswers }}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
      onSubmit={handleOnSubmit}
    >
      {({
        isSubmitting,
      }: FormikProps<{
        questions: FormikAnswer[];
      }>) => (
        <Form>
          <FieldArray
            name="questions"
            render={() => (
              <div className="space-y-6">
                {props.questions.map((question, index) => {
                  switch (question.type) {
                    case 'WYSIWYG':
                      return (
                        <WYSIWYG
                          index={index}
                          question={question}
                          key={question.id}
                        />
                      );
                    case 'CHECKLIST':
                      return (
                        <Checklist
                          index={index}
                          question={question}
                          key={question.id}
                        />
                      );
                    case '4_GRID':
                      return (
                        <FourGrid
                          index={index}
                          question={question}
                          key={question.id}
                        />
                      );
                    case 'VENN':
                      return (
                        <Venn
                          index={index}
                          question={question}
                          key={question.id}
                        />
                      );
                    case 'PILLARS':
                      return (
                        <Pillars
                          index={index}
                          question={question}
                          key={question.id}
                        />
                      );
                    case '2_COL_LINE_BY_LINE':
                      return (
                        <TwoColLineByLine
                          index={index}
                          question={question}
                          key={question.id}
                        />
                      );
                    case 'TABLE':
                      return (
                        <Table
                          index={index}
                          question={question}
                          key={question.id}
                        />
                      );
                    case 'MULTI_SELECT':
                      return (
                        <MultiSelect
                          index={index}
                          question={question}
                          key={question.id}
                        />
                      );
                    default:
                      return null;
                  }
                })}
              </div>
            )}
          />
          <div className="flex justify-between mt-6 mb-12 px-4 sm:px-0">
            {props.previousSection ? (
              <Link className="btn btn-white" to={props.previousSection}>
                <ArrowLeftIcon className="mr-2 w-4 h-4" />
                Back
              </Link>
            ) : null}
            <button
              className="btn btn-primary"
              type="submit"
              disabled={isSubmitting}
            >
              {props.nextSection ? ' Save & Continue' : 'Save'}
              {props.nextSection ? (
                <ArrowRightIcon className="ml-2 w-4 h-4" />
              ) : null}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FlexibleContainer;
