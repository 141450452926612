import React from 'react';
import clsx from 'clsx';

import DashboardMenu from './Menu/DashboardMenu';

export interface DashboardLayoutProps {
  fullWidth?: boolean;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = props => {
  return (
    <div className="min-h-full bg-gray-50">
      <DashboardMenu />
      <main
        className={clsx(
          'mx-auto pb-10 sm:py-6 lg:px-8',
          props.fullWidth ? 'w-full' : 'max-w-screen-xl'
        )}
      >
        {props.children}
      </main>
    </div>
  );
};

export default DashboardLayout;
