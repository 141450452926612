import React from 'react';
import { FieldArrayRenderProps } from 'formik';
import xor from 'lodash/xor';
import uniq from 'lodash/uniq';

import { Brand } from '../../types/brand';
import { Project } from '../../types/project';

type SelectProjectToMonthProps = {
  projects: Array<Project>;
  brands: Brand[];
} & FieldArrayRenderProps;

const SelectProjectToMonth: React.ComponentType<SelectProjectToMonthProps> =
  props => {
    const formValue = props.form.values.activeProjects;
    const isAllProjectSelected = (): boolean => {
      if (formValue.length < 1 || props.projects.length < 1) return false;
      return props.projects.every(project => formValue.includes(project.id));
    };

    const handleSelectAllProject = () => {
      if (isAllProjectSelected()) {
        props.form.setFieldValue('activeProjects', []);

        return;
      }

      props.form.setFieldValue(
        'activeProjects',
        uniq([...formValue, ...props.projects?.map(p => p.id)])
      );
    };

    const BrandItem = ({ data }: { data: Brand }) => {
      const projectsForBrand = props.projects?.filter(
        project => project.brandId === data.id
      );
      const allProjectIds = projectsForBrand.map(proj => proj.id);

      const isChecked = (projectId: string): boolean => {
        return formValue.includes(projectId);
      };

      const isAllChecked = (): boolean => {
        if (formValue.length < 1 || allProjectIds.length < 1) return false;
        return allProjectIds.every((v: string) => formValue.includes(v));
      };

      const handleSelectAll = () => {
        if (isAllChecked()) {
          props.form.setFieldValue(
            'activeProjects',
            xor(formValue, allProjectIds)
          );
          return;
        }

        props.form.setFieldValue(
          'activeProjects',
          uniq([...formValue, ...allProjectIds])
        );
      };

      const handleSingleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.form.setFieldValue(
          'activeProjects',
          xor(formValue, [e.target.name])
        );
      };

      return (
        <div className="pt-8 space-y-4">
          <div className="relative flex items-start">
            <label
              htmlFor={`brand-${data.id}`}
              className="font-medium text-gray-900 select-none	cursor-pointer"
            >
              {data.name}
            </label>
            <div className="ml-auto flex items-center h-5">
              <input
                id={`brand-${data.id}`}
                name={`brand-${data.id}`}
                type="checkbox"
                checked={isAllChecked()}
                onChange={handleSelectAll}
                style={{ boxShadow: 'none' }}
                className="form-checkbox h-4 w-4 text-secondary-700 border-gray-300 rounded cursor-pointer focus:ring-0"
              />
            </div>
          </div>

          {projectsForBrand?.map(project => (
            <div className="relative flex items-start" key={project.id}>
              <label
                htmlFor={`project-${project.id}`}
                className="text-gray-700 select-none text-sm cursor-pointer"
              >
                {project.name}{' '}
                {project.isSecret ? (
                  <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                    Private
                  </span>
                ) : null}
              </label>
              <div className="ml-auto flex items-center h-5">
                <input
                  id={`project-${project.id}`}
                  name={project.id}
                  type="checkbox"
                  checked={isChecked(project.id)}
                  onChange={handleSingleChange}
                  style={{ boxShadow: 'none' }}
                  className="form-checkbox outline-checkbox h-4 w-4 text-secondary-50 border-gray-300 checked:border-secondary-700 focus:checked:border-secondary-700 rounded cursor-pointer focus:ring-0"
                />
              </div>
            </div>
          ))}
        </div>
      );
    };

    // if we are populating the month based on the previous, we dont need this selection input
    if (props.form.values.populateActiveProjectsFromPreviousMonth) {
      return null;
    }

    return (
      <fieldset className="py-8">
        <p className="text-gray-700 font-semibold">Projects</p>
        <div className="relative flex justify-between items-center border-b border-gray-200 pb-4">
          <div className="text-sm">
            <label
              htmlFor="select-all"
              id="select-all-description"
              className="text-gray-500 cursor-pointer"
            >
              View All Projects and Brands
            </label>
          </div>
          <div className="ml-3 flex items-center h-5">
            <input
              id="select-all"
              aria-describedby="select-all-description"
              type="checkbox"
              onChange={handleSelectAllProject}
              checked={isAllProjectSelected()}
              className="form-checkbox focus:ring-secondary-700 h-4 w-4 text-secondary-700 border-gray-300 rounded cursor-pointer"
            />
          </div>
        </div>
        <div className="space-y-8 divide-y divide-gray-200">
          {props.brands?.map(brand => (
            <BrandItem data={brand} key={brand.id} />
          ))}
        </div>
      </fieldset>
    );
  };

export default SelectProjectToMonth;
