import { FastField } from 'formik';
import React from 'react';

import { Question } from '../../../types/strategic-plan';
import FormikInput from '../../FormikInput';
import FormikTextarea from '../../FormikTextarea';
import QuestionContainer from '../QuestionContainer';

type VennProps = {
  index: number;
  question: Question;
};

const Venn: React.FC<VennProps> = props => {
  return (
    <QuestionContainer
      name={props.question.name}
      description={props.question.description}
    >
      <fieldset>
        <div className="grid gap-8 grid-cols-1">
          <div className="grid gap-2 grid-cols-1">
            {/* 1 */}
            <FastField
              name={`questions[${props.index}].formData.title1Val`}
              type="text"
              component={FormikInput}
              placeholder="Title here"
              label="Venn Title 1"
            />
            <FastField
              name={`questions[${props.index}].formData.body1Val`}
              type="text"
              component={FormikTextarea}
            />
          </div>

          <div className="grid gap-2 grid-cols-1">
            {/* 2 */}
            <FastField
              name={`questions[${props.index}].formData.title2Val`}
              type="text"
              component={FormikInput}
              placeholder="Title here"
              label="Venn Title 2"
            />
            <FastField
              name={`questions[${props.index}].formData.body2Val`}
              type="text"
              component={FormikTextarea}
            />
          </div>

          <div className="grid gap-2 grid-cols-1">
            {/* 3 */}
            <FastField
              name={`questions[${props.index}].formData.title3Val`}
              type="text"
              component={FormikInput}
              placeholder="Title here"
              label="Venn Title 3"
            />
            <FastField
              name={`questions[${props.index}].formData.body3Val`}
              type="text"
              component={FormikTextarea}
            />
          </div>
        </div>
      </fieldset>
    </QuestionContainer>
  );
};

export default Venn;
