import React from 'react';

import logo from '../assets/logo-primary.svg';
import { APP_TITLE } from '../config';

const Maintenance: React.FC = () => {
  return (
    <div className="text-secondary-600 fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden flex flex-col items-center justify-center">
      <img className="h-12 w-auto mb-8" src={logo} alt={APP_TITLE} />
      <h1 className="text-2xl font-bold text-center">
        We're down for maintenance
      </h1>
      <h2 className="text-xl font-bold mt-2 text-center">Be right back...</h2>
    </div>
  );
};

export default Maintenance;
