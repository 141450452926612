import React from 'react';
import format from 'date-fns/format';
import fromUnixTime from 'date-fns/fromUnixTime';

import { useSubscription } from '../../hooks/useSubscription';
import DeleteOrganisation from './DeleteOrganisation';

const BillingDetails: React.FC = () => {
  const details = useSubscription();

  if (details.isError && details.error.code === 'billing/org-plan-invalid') {
    return (
      <section className="border border-gray-200 bg-white mb-6 sm:rounded-md">
        <div className="p-4 sm:p-6 pt-0">
          <DeleteOrganisation />
        </div>
      </section>
    );
  }

  return (
    <section className="border border-gray-200 bg-white mb-6 sm:rounded-md divide-y sm:divide-y-0 sm:divide-x grid sm:grid-cols-3">
      <div className="p-4 sm:p-6">
        <p className="text-base leading-6 text-gray-900">Seats</p>
        {details.isLoading ? (
          <span className="block bg-primary-200 animate-pulse rounded w-7 h-7" />
        ) : (
          <p>
            <span className="text-primary-600 text-2xl leading-8 font-semibold capitalize">
              {details.data?.seats.used ? details.data?.seats.used : 0}
            </span>
            <span className="text-sm text-gray-500 leading-5 font-medium">
              {' '}
              of{' '}
              {details.data?.seats.nbOrganisationSeats
                ? details.data?.seats.nbOrganisationSeats
                : 0}
            </span>
          </p>
        )}
      </div>
      <div className="p-4 sm:p-6">
        <p className="text-base leading-6 text-gray-900">Billing Frequency</p>
        {details.isLoading ? (
          <span className="block bg-primary-200 animate-pulse rounded w-24 h-7" />
        ) : (
          <span className="text-primary-600 text-2xl leading-8 font-semibold capitalize">
            {`${details.data?.billingFrequency}ly`}
          </span>
        )}
      </div>
      <div className="p-4 sm:p-6">
        <p className="text-base leading-6 text-gray-900">Next Bill Date</p>
        {details.isLoading ? (
          <span className="block bg-primary-200 animate-pulse rounded w-24 h-7" />
        ) : (
          <span className="text-primary-600 text-2xl leading-8 font-semibold capitalize">
            {details.data?.currentPeriodEnd
              ? format(fromUnixTime(details.data?.currentPeriodEnd), 'dd/MM/yy')
              : null}
          </span>
        )}
      </div>
    </section>
  );
};

export default BillingDetails;
