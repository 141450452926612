import React, { useState } from 'react';
import { FastField, Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useMutation } from 'react-query';

import FormikInput from '../FormikInput';
import authService from '../../services/authService';
import SuccessMessage from '../SuccessMessage';
import { SignInError } from '../../types/sign-in';
import toast from 'react-hot-toast';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .required('Email is required'),
});

const initialValues: { email: string } = {
  email: '',
};

const ForgotPasswordForm: React.FC = () => {
  const [sent, setSent] = useState<boolean>(false);
  const sendReset = useMutation<void, SignInError, any>(
    authService.sendResetPasswordEmail,
    {
      onSuccess: () => {
        setSent(true);
      },
      onError: e => {
        if (e.code === 'emails/too-many-requests') {
          toast.error('Something went wrong. Try again later!');
        } else {
          setSent(true);
        }
      },
    }
  );
  const handleOnSubmit = async (values: { email: string }): Promise<void> => {
    try {
      await sendReset.mutateAsync(values.email);
    } catch {}
  };

  if (sent) {
    return (
      <SuccessMessage
        className="mt-16"
        title="Password Reset Email Sent"
        text={
          <p>
            Check your email for a link to reset your password. If it doesn't
            appear within a few minutes, check your "Spam" folder.
          </p>
        }
      />
    );
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleOnSubmit}
    >
      {({ isSubmitting }: FormikProps<{ email: string }>) => (
        <Form className="space-y-6 mt-16" noValidate>
          <FastField
            type="email"
            name="email"
            component={FormikInput}
            label="Email address"
            placeholder="you@example.com"
            autoFocus
          />
          <button
            type="submit"
            className="btn btn-primary btn-full"
            disabled={isSubmitting}
          >
            Send Password Reset
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
