import React, { useState } from 'react';
import { useQuery } from 'react-query';
import CreditCardIcon from '@heroicons/react/outline/CreditCardIcon';

import organisationService from '../../services/organisationService';
import ChangePaymentModal from './ChangePaymentModal';

const ChangePayment: React.FC = () => {
  const card = useQuery('payment-method', organisationService.getPaymentMethod);
  const [open, setOpen] = useState<boolean>(false);

  return (
    <section className="bg-white pt-6 shadow sm:rounded-md sm:overflow-hidden mb-6">
      <div className="px-4 sm:px-6">
        <h2 className="text-lg leading-6 font-medium text-gray-900">
          Payment Information
        </h2>
      </div>
      <div className="p-6 flex items-center">
        <div className="flex items-center">
          <CreditCardIcon className="w-10 h-10 mr-4 text-secondary-500" />
          {card.isLoading ? (
            <div>
              <p className="h-4 w-24 bg-gray-300 animate-pulse rounded" />
              <p className="h-4 w-24 bg-gray-200 animate-pulse rounded mt-2" />
            </div>
          ) : (
            <div>
              <p className="text-gray-900 text-sm capitalize">
                {card.data?.card.brand} •••• {card.data?.card.last4}
              </p>
              <p className="text-gray-500 text-sm">
                Expires {card.data?.card.exp_month}/{card.data?.card.exp_year}
              </p>
            </div>
          )}
        </div>
        <button className="ml-auto btn btn-white" onClick={() => setOpen(true)}>
          Update
        </button>
      </div>
      <ChangePaymentModal open={open} onClose={() => setOpen(false)} />
    </section>
  );
};

export default ChangePayment;
