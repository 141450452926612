import React, { FormEvent } from 'react';
import { FastField, FieldArrayRenderProps } from 'formik';
import { MinusCircleIcon } from '@heroicons/react/solid';

import FormikInput from '../FormikInput';

const TaskFieldArray: React.FC<FieldArrayRenderProps> = props => {
  const formValue = props.form.values.tasks;

  const onAddNewTask = (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.insert(formValue.length + 1, '');
  };

  const onRemoveTask = (index: number) => (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.remove(index);
  };

  return (
    <div>
      <FastField
        type="text"
        name="tasks.0"
        component={FormikInput}
        label="Task*"
        placeholder="Task Name"
      />

      <div className="space-y-6 mt-6">
        {formValue.slice(1).map((task: string, index: number) => (
          <div className="flex space-x-2 items-center">
            <div className="flex-grow">
              <FastField
                key={index + 1}
                type="text"
                name={`tasks.${index + 1}`}
                component={FormikInput}
                label="Task"
                placeholder="Task Name"
              />
            </div>
            <button onClick={onRemoveTask(index + 1)} className="mt-auto mb-1">
              <MinusCircleIcon className="w-9 h-9  text-secondary-600 hover:text-secondary-900" />
            </button>
          </div>
        ))}
      </div>
      <button
        className="btn btn-secondary btn-full mt-6"
        onClick={onAddNewTask}
      >
        Add Task
      </button>
    </div>
  );
};

export default TaskFieldArray;
