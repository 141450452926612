import { Formik, FormikProps } from 'formik';
import React from 'react';
import { toast } from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import * as Yup from 'yup';
import { resourcePlannerKeys } from '../../config';
import { useProjects } from '../../hooks/useProjects';
import resourcePlannerService from '../../services/resourcePlannerService';
import Spinner from '../../Spinner';
import { PlanMonth } from '../../types/resource-planner';
import Drawer from '../Drawer';
import PlanMonthForm from './PlanMonthForm';

type PlanMonthDrawerProps = {
  onClose: () => void;
  open: boolean;
  nextMonth: string;
};

const schema = Yup.object().shape({
  populateHoursFromPreviousMonth: Yup.boolean(),
  populateActiveProjectsFromPreviousMonth: Yup.boolean(),
  activeProjects: Yup.array()
    .of(Yup.string())
    .when('populateActiveProjectsFromPreviousMonth', {
      is: false,
      then: Yup.array().of(Yup.string()).min(1),
    }),
});

const PlanMonthDrawer: React.FC<PlanMonthDrawerProps> = props => {
  const queryClient = useQueryClient();
  const projects = useProjects();

  const createNextMonth = useMutation(resourcePlannerService.planNextMonth, {
    onSuccess: async () => {
      await queryClient.refetchQueries(resourcePlannerKeys.all);
    },
    onError: () => {
      toast.error('Something went wrong. Try again!');
    },
  });

  const initialValues: PlanMonth = {
    populateActiveProjectsFromPreviousMonth: false,
    populateHoursFromPreviousMonth: false,
    activeProjects: [],
  };

  const handleOnSubmit = async (values: PlanMonth) => {
    try {
      const selectedProjects = values.activeProjects.map(projectId => {
        return {
          projectId: projectId,
          brandId:
            projects.data?.find(project => project.id === projectId)?.brandId ||
            '',
        };
      });

      if (values.populateActiveProjectsFromPreviousMonth) {
        await createNextMonth.mutateAsync({
          populateActiveProjectsFromPreviousMonth:
            values.populateActiveProjectsFromPreviousMonth,
          populateHoursFromPreviousMonth: values.populateHoursFromPreviousMonth,
        });
      } else {
        await createNextMonth.mutateAsync({
          activeProjects: selectedProjects,
          populateHoursFromPreviousMonth: values.populateHoursFromPreviousMonth,
        });
      }
      props.onClose();
    } catch (e) {
      toast.error('Something went wrong. Try again!');
    }
  };

  return (
    <Drawer open={props.open} onClose={props.onClose}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleOnSubmit}
      >
        {({ isSubmitting, dirty, errors }: FormikProps<PlanMonth>) => (
          <Drawer.FormContainer
            open={props.open}
            onClose={props.onClose}
            title="Add new month"
            description="Set up a new month project time allocation resources"
            Footer={
              <>
                <button
                  className="btn btn-white"
                  onClick={props.onClose}
                  disabled={isSubmitting}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary ml-4"
                  type="submit"
                  disabled={isSubmitting || !dirty}
                >
                  {isSubmitting ? (
                    <>
                      <Spinner
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      Loading
                    </>
                  ) : (
                    'Begin Month'
                  )}
                </button>
              </>
            }
          >
            {errors && errors.activeProjects && (
              <p className="pt-6 px-4 sm:px-6 text-xs text-red-900">
                Please select at least one project
              </p>
            )}
            <PlanMonthForm
              projects={projects.data || []}
              nextMonth={props.nextMonth}
            />
          </Drawer.FormContainer>
        )}
      </Formik>
    </Drawer>
  );
};

export default PlanMonthDrawer;
