import React, { useState } from 'react';

import { usePermission } from '../hooks/usePermission';
import { useTaskTemplates } from '../hooks/useTasks';
import { TaskTemplate } from '../types/task';

import TemplatesTable from '../components/WIPTemplates/TemplatesTable';
import NewTemplateDrawer from '../components/WIPTemplates/NewTemplateDrawer';
import EditTemplateDrawer from '../components/WIPTemplates/EditTemplateDrawer';
import TemplatesTableSkeleton from '../components/WIPTemplates/TemplatesTableSkeleton';
import EmptyTaskTemplate from '../components/WIPTemplates/EmptyTaskTemplate';

const WIPTemplates: React.FC = () => {
  const templates = useTaskTemplates();
  const permission = usePermission();
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState<{
    open: boolean;
    template: TaskTemplate | null;
  }>({ open: false, template: null });

  const onNewDrawerClose = () => {
    setOpen(false);
  };

  const onEditDrawerClose = () => {
    setEdit(prevState => ({ ...prevState, open: false }));
  };

  if (!templates.isLoading && templates.data && templates.data?.length < 1) {
    return (
      <section>
        <NewTemplateDrawer open={open} onClose={onNewDrawerClose} />
        <EmptyTaskTemplate onNewTaskTemplate={() => setOpen(true)} />
      </section>
    );
  }

  return (
    <section>
      <NewTemplateDrawer open={open} onClose={onNewDrawerClose} />
      <EditTemplateDrawer
        open={edit.open}
        data={edit.template}
        onClose={onEditDrawerClose}
      />
      <div>
        <div className="block sm:flex mb-6 items-center px-4 lg:px-0">
          <div className="mb-4 sm:mb-0">
            <h2 className="text-lg leading-none font-bold text-gray-900 mb-0">
              Templates
            </h2>
          </div>
          {permission.data?.isAdmin || permission.data?.isMember ? (
            <div className="block sm:flex ml-auto items-center">
              <button className="btn btn-primary" onClick={() => setOpen(true)}>
                New template
              </button>
            </div>
          ) : null}
        </div>
      </div>
      <div className="shadow bg-white lg:rounded-md sm:overflow-hidden p-4 sm:p-6">
        {templates.isLoading ? (
          <TemplatesTableSkeleton />
        ) : (
          <TemplatesTable data={templates.data || []} onEdit={setEdit} />
        )}
      </div>
    </section>
  );
};

export default WIPTemplates;
