import React from 'react';
import { useUpdateResourcePlanner } from '../../hooks/useUpdateResourcePlanner';
import HeadingInput from './HeadingInput';

type UserColumnProps = {
  firstName: string | '';
  lastName: string | '';
  monthId: string;
  userId: string;
  hoursAllocated: number;
  hoursAvailable: number;
};

const UserColumn: React.FC<UserColumnProps> = props => {
  const updateRP = useUpdateResourcePlanner();

  const handleUpdate = async (newValue: string) => {
    if (parseFloat(props.hoursAvailable.toString()) === parseFloat(newValue)) {
      return;
    }

    try {
      await updateRP.mutateAsync({
        resourcePlannerId: props.monthId,
        values: {
          organisationMembers: [
            {
              id: props.userId,
              hoursAvailable: parseFloat(newValue),
            },
          ],
        },
      });
    } catch (e) {
      //
    }
  };

  return (
    <div
      style={{
        width: 180,
      }}
      className="entry items-center"
    >
      <div className="flex-1">
        <p className="text-sm leading-5 font-medium text-gray-700">
          {props.firstName} {props.lastName}
        </p>
        <HeadingInput
          total={props.hoursAllocated}
          value={props.hoursAvailable}
          onChange={handleUpdate}
          disabled={updateRP.isLoading}
        />
      </div>
    </div>
  );
};

export default UserColumn;
