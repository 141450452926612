import React, { useState } from 'react';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { useHistory, useLocation } from 'react-router-dom';
import {
  FastField,
  Field,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from 'formik';
import { useMutation } from 'react-query';
import { ExclamationIcon } from '@heroicons/react/outline';

import { Invitation, SignUp, SignUpError } from '../../types/sign-up';
import { User } from '../../types/user';
import authService from '../../services/authService';

import FormikInput from '../FormikInput';
import FormikPasswordInput from '../FormikPasswordInput';
import Spinner from '../../Spinner';
import TCSFooter from './TCSFooter';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Password must contain at least 8 characters')
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Password must contain at least 8 characters, one number, one uppercase letter'
    ),
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  phone: Yup.string().required('Phone number is required'),
  marketing_email: Yup.boolean(),
});

const AcceptInvitationForm: React.FC<Invitation> = props => {
  const history = useHistory();
  const [isBlocked, setBlocked] = useState<boolean>(false);
  const query = new URLSearchParams(useLocation().search);
  const inviteToken = query.get('inviteToken');

  const registerMember = useMutation<
    User,
    any,
    Omit<SignUp, 'company'> & { inviteId: string }
  >(authService.registerMember, {
    onError: () => {
      toast.error('Error occurred during the sign up process.');
    },
    onSuccess: data => {
      history.push('/auth/verify-email', { email: data.email });
    },
  });

  const initialValues: Omit<SignUp, 'company'> = {
    first_name: props.firstName || '',
    last_name: props.lastName || '',
    email: props.email,
    phone: props.mobileNumber || '',
    password: '',
    marketing_email: false,
  };

  const handleOnSubmit = async (
    values: Omit<SignUp, 'company'>,
    formikBag: FormikHelpers<Omit<SignUp, 'company'>>
  ): Promise<void> => {
    try {
      await registerMember.mutateAsync({
        ...values,
        inviteId: inviteToken || '',
      });
    } catch (e) {
      const error = e as SignUpError;
      switch (error.code) {
        case 'forbidden/is-not-firebase-user':
        case 'auth/email-already-in-use':
          formikBag.setFieldError(
            'email',
            'That email address is already in use!'
          );
          break;
        case 'users/user-has-no-invite':
          toast.error('User invite not found');
          break;
        case 'users/org-plan-invalid':
          setBlocked(true);
          break;
      }
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleOnSubmit}
    >
      {({ isSubmitting }: FormikProps<Omit<SignUp, 'company'>>) => (
        <Form className="space-y-6" noValidate>
          {isBlocked ? (
            <div className="rounded-md bg-yellow-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationIcon
                    className="h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-yellow-800">
                    Organisation Inactive
                  </h3>
                  <div className="mt-2 text-sm text-yellow-700">
                    <p>
                      Please note that the billing for this organisation has
                      failed or expired. Contact the organisation admin to
                      request billing be updated.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <fieldset disabled={isSubmitting}>
            <div className="space-y-6">
              <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <FastField
                    type="text"
                    name="first_name"
                    component={FormikInput}
                    label="First Name"
                    placeholder="John"
                    autoFocus
                  />
                </div>

                <div className="sm:col-span-3">
                  <FastField
                    type="text"
                    name="last_name"
                    component={FormikInput}
                    label="Last Name"
                    placeholder="Doe"
                  />
                </div>
              </div>
              <FastField
                type="email"
                name="email"
                component={FormikInput}
                label="Email address"
                disabled
              />
              <FastField
                type="tel"
                name="phone"
                component={FormikInput}
                label="Phone Number"
                placeholder="+44 1234567"
              />
              <Field
                component={FormikPasswordInput}
                label="Create Password"
                name="password"
                helperText="Passwords should be at least 8 characters long."
              />
            </div>
          </fieldset>
          <div className="relative flex items-start">
            <div className="flex items-center h-5">
              <Field
                id="marketing_email"
                aria-describedby="marketing_email-description"
                name="marketing_email"
                type="checkbox"
                className="form-checkbox focus:ring-secondary-700 h-4 w-4 text-secondary-700 border-gray-300 rounded cursor-pointer"
              />
            </div>
            <div className="ml-3 text-sm">
              <label
                htmlFor="marketing_email"
                className="font-medium text-gray-700 cursor-pointer"
              >
                Subscribe to Updates?
              </label>
              <p id="comments-description" className="text-gray-500">
                Get notified when we have feature updates, hints & tips
              </p>
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="btn btn-primary btn-full"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Spinner className="h-4 w-4 trailing-icon" />
              ) : (
                'Create Account'
              )}
            </button>
          </div>
          <TCSFooter />
        </Form>
      )}
    </Formik>
  );
};

export default AcceptInvitationForm;
