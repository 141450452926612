import React, { ChangeEvent } from 'react';
import clsx from 'clsx';
import { Link, useHistory, useParams } from 'react-router-dom';

const BrandTabMenu: React.FC = () => {
  const history = useHistory();
  const { brandId } = useParams<{ brandId: string }>();

  const tabs = [
    {
      name: 'Brand Overview',
      to: `/brands/${brandId}/overview`,
      current: history.location.pathname === `/brands/${brandId}/overview`,
    },
    {
      name: 'Projects',
      to: `/brands/${brandId}/projects`,
      current: history.location.pathname === `/brands/${brandId}/projects`,
    },
  ];

  const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    history.push(e.currentTarget.value);
  };

  return (
    <div className="max-w-screen-md	mx-auto my-6 px-4 lg:px-0">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-secondary-500 focus:border-secondary-500 select"
          onChange={handleSelect}
          defaultValue={history.location.pathname}
        >
          {tabs.map(tab => (
            <option key={tab.to} value={tab.to}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav
          className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200"
          aria-label="Tabs"
        >
          {tabs.map((tab, tabIdx) => (
            <Link
              key={tab.to}
              to={tab.to}
              className={clsx(
                tab.current
                  ? 'text-gray-900'
                  : 'text-gray-500 hover:text-gray-700',
                tabIdx === 0 ? 'rounded-l-lg' : '',
                tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10'
              )}
              aria-current={tab.current ? 'page' : undefined}
            >
              <span>{tab.name}</span>
              <span
                aria-hidden="true"
                className={clsx(
                  tab.current ? 'bg-secondary-500' : 'bg-transparent',
                  'absolute inset-x-0 bottom-0 h-0.5'
                )}
              />
            </Link>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default BrandTabMenu;
