import React from 'react';
import { TaskTemplate } from '../../types/task';

type TemplatePreviewProps = {
  template: TaskTemplate | undefined;
};

const TemplatePreview: React.FC<TemplatePreviewProps> = props => {
  if (!props.template) return null;

  return (
    <div className="border-t border-gray-200 py-4 sm:py-6 mt-2">
      <p className="text-base leading-6 font-semibold pb-0">Preview</p>

      <div className="space-y-4 divide-y divide-gray-200">
        {props.template?.tasks.map(task => (
          <div className="flex items-center justify-between pt-4" key={task}>
            <span className="text-sm text-gray-500">Task</span>
            <div>
              <p className="font-semibold text-sm text-gray-900 text-right">
                {props.template?.activity}
              </p>
              <p className="text-sm text-gray-500 text-right">{task}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TemplatePreview;
