import {
  ResourcePlanner,
  Settings,
  SettingsDTO,
  UpdateResourceMonthDTO,
} from '../types/resource-planner';
import { doc } from 'firebase/firestore';

import axios from '../utils/axios';
import { db } from '../utils/firebase';
import transformDoc from '../utils/transform-doc';

class ResourcePlannerService {
  /**
   *
   * @param year
   * @param monthName E.g.: JANUARY
   */
  getByDate = async (year: number, monthName: string) => {
    const response = await axios.get<ResourcePlanner>(
      `/resource-months/${year}/${monthName}`
    );

    return response.data;
  };

  planNextMonth = async (
    data:
      | {
          activeProjects: Array<{ projectId: string; brandId: string }>;
          populateHoursFromPreviousMonth: boolean;
        }
      | {
          populateActiveProjectsFromPreviousMonth: boolean;
          populateHoursFromPreviousMonth: boolean;
        }
  ) => {
    const response = await axios.post<ResourcePlanner>(
      `/resource-months/actions/plan-next-month`,
      data
    );

    return response.data;
  };

  updateResourcePlanner = async ({
    resourcePlannerId,
    values,
  }: {
    resourcePlannerId: string;
    values: UpdateResourceMonthDTO;
  }) => {
    const response = await axios.patch<ResourcePlanner>(
      `/resource-months/${resourcePlannerId}`,
      values
    );

    return response.data;
  };

  getSettings = async (organisationId: string): Promise<Settings> => {
    try {
      const docRef = doc(
        db,
        `organisations/${organisationId}/app-settings`,
        'team-resource-planner'
      );
      const settings = await transformDoc<Settings>(docRef);

      if (!settings) {
        return {
          unitOfTime: 'HOURS',
          workingHoursPerDay: 7.5,
        };
      }

      return settings;
    } catch (e) {
      // skip this, because if no settings saved yet, firebase returns error
    }

    return {
      unitOfTime: 'HOURS',
      workingHoursPerDay: 7.5,
    };
  };

  updateSettings = async (data: SettingsDTO): Promise<Settings> => {
    const response = await axios.patch<Settings>(
      '/organisations/app-settings/team-resource-planner',
      {
        workingHoursPerDay: parseFloat(data.workingHoursPerDay),
        unitOfTime: data.unitOfTime,
      }
    );

    return response.data;
  };
}

const resourcePlannerService = new ResourcePlannerService();

export default resourcePlannerService;
