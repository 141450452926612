import * as Yup from 'yup';
import React from 'react';
import { Formik, FormikProps } from 'formik';
import { toast } from 'react-hot-toast';

import { useProjects } from '../../hooks/useProjects';

import Drawer from '../Drawer';
import Spinner from '../../Spinner';
import ManageProjectsForm from './ManageProjectsForm';
import { useUpdateResourcePlanner } from '../../hooks/useUpdateResourcePlanner';

type ManageProjectsDrawerProps = {
  onClose: () => void;
  open: boolean;
  resourceMonthId: string;
  resourceMonthActiveProjectIds: string[];
  drawerSubTitle?: string;
  isCurrentMonth?: boolean;
};

type ManageProjectsFormValues = {
  activeProjects: string[];
};

const schema = Yup.object().shape({
  activeProjects: Yup.array().of(Yup.string()).min(1),
});

const ManageProjectsDrawer: React.FC<ManageProjectsDrawerProps> = props => {
  const projects = useProjects();
  const updateRP = useUpdateResourcePlanner();

  const initialValues: ManageProjectsFormValues = {
    activeProjects: props.resourceMonthActiveProjectIds,
  };

  const handleOnSubmit = async (values: ManageProjectsFormValues) => {
    try {
      await updateRP.mutateAsync({
        resourcePlannerId: props.resourceMonthId,
        values: {
          projects: projects.data?.map(project => ({
            id: project.id,
            isActive: values.activeProjects.includes(project.id),
          })),
        },
      });
      props.onClose();
    } catch (e) {
      toast.error('Something went wrong. Try again!');
    }
  };

  return (
    <Drawer open={props.open} onClose={props.onClose}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleOnSubmit}
      >
        {({
          isSubmitting,
          dirty,
          errors,
        }: FormikProps<ManageProjectsFormValues>) => (
          <Drawer.FormContainer
            open={props.open}
            onClose={props.onClose}
            title="Manage projects"
            description="Organise your budgeted projects"
            Footer={
              <>
                <button
                  className="btn btn-white"
                  onClick={props.onClose}
                  disabled={isSubmitting}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary ml-4"
                  type="submit"
                  disabled={isSubmitting || !dirty}
                >
                  {isSubmitting ? (
                    <>
                      <Spinner
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      Loading
                    </>
                  ) : (
                    'Update'
                  )}
                </button>
              </>
            }
          >
            {props.drawerSubTitle ? (
              <div className="py-6 px-4 sm:px-6 border-b border-gray-200 flex justify-between">
                <p className="text-base leading-6 font-semibold gray-900">
                  {props.drawerSubTitle}
                </p>

                {props.isCurrentMonth ? (
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full bg-secondary-50 text-sm leading-5 font-medium text-primary-700">
                    Current
                  </span>
                ) : null}
              </div>
            ) : null}

            {errors && errors.activeProjects && (
              <p className="pt-6 px-4 sm:px-6 text-xs text-red-900">
                Please select at least one project
              </p>
            )}

            <ManageProjectsForm projects={projects.data || []} />
          </Drawer.FormContainer>
        )}
      </Formik>
    </Drawer>
  );
};

export default ManageProjectsDrawer;
