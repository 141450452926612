import React from 'react';
import { ScrollSync, ScrollSyncNode } from 'scroll-sync-react';
import { ResourcePlanner } from '../../types/resource-planner';
import ProjectColumn from './ProjectColumn';
import RPInput from './RPInput';
import UserColumn from './UserColumn';

type TableProps = {
  data: ResourcePlanner;
};

const Table: React.FC<TableProps> = props => {
  const rp = props.data;

  const activeProjects = rp.projects.filter(p => p.isActive);

  return (
    <ScrollSync>
      <div className="relative overscroll-contain border-r border-b border-gray-200">
        <div className="top-0 sticky flex justify-end z-20">
          <ScrollSyncNode>
            <div className="scroller flex overflow-y-hidden overflow-x-hidden flex-1">
              <div className="time bg-white border-none z-20 md:sticky left-0" />
              {activeProjects.map(p => (
                <ProjectColumn
                  key={p.id}
                  monthId={rp.resourceMonth.id}
                  projectId={p.id}
                  project={p.name}
                  brand={p.brand.name}
                  hoursAllocated={p.hoursAllocated}
                  hoursAvailable={p.hoursAvailable}
                />
              ))}
            </div>
          </ScrollSyncNode>
        </div>

        <ScrollSyncNode>
          <div className="tracks flex overflow-auto overflow-y-hidden">
            <div className="time bg-white md:sticky left-0 z-10">
              {rp.organisationMembers.map(u => (
                <UserColumn
                  key={u.id}
                  userId={u.id}
                  monthId={rp.resourceMonth.id}
                  firstName={u.firstName}
                  lastName={u.lastName}
                  hoursAllocated={u.totalHoursAllocated}
                  hoursAvailable={u.hoursAvailable}
                />
              ))}
            </div>
            {activeProjects.map(project => (
              <div className="track z-0" key={project.id}>
                {rp.organisationMembers.map(member => (
                  <RPInput
                    key={member.id}
                    userId={member.id}
                    projectId={project.id}
                    brandId={project.brand.id}
                    monthId={rp.resourceMonth.id}
                    value={
                      member.hoursAllocatedPerProject.find(
                        proj => proj.project.id === project.id
                      )?.hoursAllocated || 0
                    }
                    isPM={member.managedProjectIds.includes(project.id)}
                  />
                ))}
              </div>
            ))}
          </div>
        </ScrollSyncNode>
      </div>
    </ScrollSync>
  );
};

export default Table;
