import { useFormikContext } from 'formik';
import React from 'react';

import { FormikAnswer, Question } from '../../../types/strategic-plan';
import QuestionContainer from '../QuestionContainer';

type ChecklistProps = {
  index: number;
  question: Question;
};

const Checklist: React.FC<ChecklistProps> = props => {
  const formik = useFormikContext<{
    questions: FormikAnswer[];
  }>();

  if (
    !props.question.questionSpecifics ||
    !props.question.questionSpecifics.checklistItems
  ) {
    return null;
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    label: string
  ) => {
    if (e.target.checked) {
      formik.setFieldValue(`questions[${props.index}].formData.checkedItems`, [
        ...formik.values.questions[props.index].formData.checkedItems,
        label,
      ]);
    } else {
      formik.setFieldValue(
        `questions[${props.index}].formData.checkedItems`,
        formik.values.questions[props.index].formData.checkedItems.filter(
          (checkedItem: string) => checkedItem !== label
        )
      );
    }
  };

  return (
    <QuestionContainer
      name={props.question.name}
      description={props.question.description}
    >
      <fieldset>
        <p className="text-gray-700 text-sm font-medium">
          Check all items that apply
        </p>

        <div className="mt-4 relative flex items-start flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-3">
          {props.question.questionSpecifics?.checklistItems?.map(
            checklistItem => (
              <div
                key={checklistItem}
                className="flex border rounded-md p-2 w-full sm:w-auto"
              >
                <div className="flex items-center h-5">
                  <input
                    id={checklistItem}
                    aria-describedby="checklist-item-description"
                    type="checkbox"
                    onChange={e => handleChange(e, checklistItem)}
                    checked={Boolean(
                      formik.values.questions[
                        props.index
                      ]?.formData?.checkedItems?.includes(checklistItem)
                    )}
                    className="form-checkbox focus:ring-secondary-700 h-4 w-4 text-secondary-700 border-gray-300 rounded cursor-pointer"
                  />
                </div>

                <div className="text-sm flex flex-grow">
                  <label
                    htmlFor={checklistItem}
                    id="checklist-item-description"
                    className="text-gray-500 cursor-pointer ml-3 w-full"
                  >
                    {checklistItem}
                  </label>
                </div>
              </div>
            )
          )}
        </div>
      </fieldset>
    </QuestionContainer>
  );
};

export default Checklist;
