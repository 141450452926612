import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

const AuthAction: React.FC = () => {
  const query = new URLSearchParams(useLocation().search);
  const mode = query.get('mode');
  const oobCode = query.get('oobCode');

  if (mode === 'verifyEmail') {
    return <Redirect to={`/auth/email-validated?oobCode=${oobCode}`} />;
  }

  if (mode === 'resetPassword') {
    return <Redirect to={`/auth/reset-password?oobCode=${oobCode}`} />;
  }

  return <Redirect to="/auth/login" />;
};

export default AuthAction;
