import * as Yup from 'yup';
import React from 'react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-hot-toast';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { useHistory } from 'react-router-dom';

import { Brand, BrandDTO, BrandError } from '../../types/brand';
import { usePMUsers } from '../../hooks/usePMUsers';
import { useIsAdmin } from '../../hooks/useUser';
import brandService from '../../services/brandService';
import { brandKeys } from '../../config';

import Drawer from '../Drawer';
import Spinner from '../../Spinner';
import BrandForm from './BrandForm';
import DeleteButton from '../DeleteButton';

type EditBrandDrawerProps = {
  onClose: () => void;
  open: boolean;
  data: Brand;
};

const schema = Yup.object().shape({
  name: Yup.string().required('First name is required'),
  projectManager: Yup.object(),
  keyContact: Yup.object().shape({
    fullName: Yup.string(),
    email: Yup.string().email('Must be a valid email'),
    phoneNumber: Yup.string(),
  }),
  billingContact: Yup.object().shape({
    fullName: Yup.string(),
    email: Yup.string().email('Must be a valid email'),
    phoneNumber: Yup.string(),
  }),
  billingInstructions: Yup.string(),
});

const EditBrandDrawer: React.FC<EditBrandDrawerProps> = props => {
  const queryClient = useQueryClient();
  const users = usePMUsers();
  const isAdmin = useIsAdmin();
  const history = useHistory();
  const createBrand = useMutation(brandService.updateBrand, {
    onError: () => {
      toast.error('Something went wrong. Try again!');
    },
    onSettled: async () => {
      await queryClient.refetchQueries(brandKeys.all);
    },
  });

  const deleteBrand = useMutation(brandService.deleteBrand, {
    onError: () => {
      toast.error('Something went wrong. Try again!');
    },
    onSuccess: () => {
      history.push('/brands');
    },
    onSettled: async () => {
      await queryClient.refetchQueries(brandKeys.all);
    },
  });

  const initialValues: BrandDTO = {
    name: props.data.name,
    projectManager: props.data.projectManager,
    contractEnd: props.data.contractEnd
      ? format(parseISO(props.data.contractEnd), 'yyyy-MM-dd')
      : '',
    keyContact: props.data.keyContact,
    billingContact: props.data.billingContact,
    billingInstructions: props.data.billingInstructions,
  };

  const handleOnSubmit = async (
    values: BrandDTO,
    formikBag: FormikHelpers<BrandDTO>
  ) => {
    try {
      await createBrand.mutateAsync({ data: values, id: props.data.id });
      props.onClose();
    } catch (e) {
      const error = e as BrandError;

      switch (error.code) {
        case 'brands/brand-name-already-in-use':
          formikBag.setFieldError('name', error.detail);
          break;
      }
    }
  };

  const handleBrandDelete = () => {
    deleteBrand.mutate(props.data.id);
  };

  return (
    <Drawer open={props.open} onClose={props.onClose}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleOnSubmit}
      >
        {({ isSubmitting, dirty }: FormikProps<BrandDTO>) => (
          <Drawer.FormContainer
            open={props.open}
            onClose={props.onClose}
            title="Edit details"
            description="Update details relating to this Brand."
            Footer={
              <>
                <button
                  className="btn btn-white"
                  onClick={props.onClose}
                  disabled={isSubmitting || deleteBrand.isLoading}
                  type="button"
                >
                  Cancel
                </button>
                {isAdmin.data ? (
                  <DeleteButton
                    modalTitle="Delete brand"
                    modalText="Once you delete this brand, you will lose all data associated with it. This action is irreversible."
                    className="btn btn-delete capitalize ml-4"
                    disabled={isSubmitting || deleteBrand.isLoading}
                    type="button"
                    onClick={handleBrandDelete}
                  >
                    {deleteBrand.isLoading ? 'Deleting' : 'Delete'}
                  </DeleteButton>
                ) : null}
                <button
                  className="btn btn-primary ml-4"
                  type="submit"
                  disabled={isSubmitting || !dirty || deleteBrand.isLoading}
                >
                  {isSubmitting ? (
                    <>
                      <Spinner
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      Loading
                    </>
                  ) : (
                    'Update Brand'
                  )}
                </button>
              </>
            }
          >
            <BrandForm isSubmitting={isSubmitting} users={users.data || []} />
          </Drawer.FormContainer>
        )}
      </Formik>
    </Drawer>
  );
};

export default EditBrandDrawer;
