import React, { ChangeEvent, useState } from 'react';
import clsx from 'clsx';
import { UserGroupIcon } from '@heroicons/react/solid';
import toast from 'react-hot-toast';

type SeatsProps = {
  value: number;
  onChange: (name: string, value: number | string) => void;
};

const Seats: React.FC<SeatsProps> = props => {
  const [value, setValue] = useState<number | string>(props.value);
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (
      !e.target.value ||
      parseInt(e.target.value) < 1 ||
      isNaN(parseInt(e.target.value))
    ) {
      setValue('');
      return;
    }

    setValue(parseInt(e.target.value));
  };

  const handleOnClick = () => {
    if (!value || value < 1) {
      return;
    }
    props.onChange('numberOfSeats', value);
    toast.success('The number of seats has been updated!');
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <div className="bg-gray-50 p-6 border-gray-300 border rounded-xl">
      <p className="text-sm text-gray-500 mb-3">
        Select how many seats you need to use. You can add and remove seats at
        any point.
      </p>
      <label className="block text-sm font-medium text-gray-700">
        Number of Seats
      </label>
      <div className="flex space-x-3 items-end">
        <div className="w-full">
          <div
            className={clsx({
              'input mt-1 relative bg-white': true,
            })}
          >
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <UserGroupIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="tel"
              value={value?.toString()}
              onChange={handleInputChange}
              className={clsx({
                'ml-7 sm:text-sm border-0 focus:outline-none block w-full px-3 py-2 appearance-none rounded-md placeholder-gray-400':
                  true,
              })}
            />
          </div>
        </div>
        <div>
          <button
            className="btn btn-secondary"
            onClick={handleOnClick}
            disabled={!value || value < 1}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default Seats;
