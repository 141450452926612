import React from 'react';

const InputSkeleton: React.FC = () => {
  return (
    <div className="animate-pulse">
      <div className="w-24 bg-gray-300 h-4 rounded mb-1" />
      <div className="w-full bg-gray-200 h-9 rounded" />
    </div>
  );
};

export default InputSkeleton;
