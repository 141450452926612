import React, { useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { useMutation } from 'react-query';
import { UserGroupIcon } from '@heroicons/react/solid';

import checkoutService from '../services/checkoutService';

import CheckoutHeader from '../components/Checkout/CheckoutHeader';
import Trial from '../components/Checkout/Trial';
import CardSkeleton from '../components/Checkout/CardSkeleton';
import ErrorPage from '../components/ErrorPage';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '');

const CheckoutLayout: React.FC = props => {
  const { mutateAsync, isLoading, isIdle, isError, data } = useMutation(
    checkoutService.setupIntent
  );

  useEffect(() => {
    if (!isIdle) return;

    const initIntent = async () => {
      await mutateAsync();
    };
    initIntent();
  }, [mutateAsync, isIdle]);

  if (isLoading || isIdle) {
    return (
      <div className="bg-white">
        <div
          className="hidden lg:block fixed top-0 left-0 w-1/2 h-full bg-white"
          aria-hidden="true"
        />
        <div
          className="hidden lg:block fixed top-0 right-0 w-1/2 h-full bg-secondary-600"
          aria-hidden="true"
        />

        <CheckoutHeader />
        <div className="relative grid grid-cols-1 gap-x-16 max-w-7xl mx-auto lg:px-8 lg:grid-cols-2">
          <section className="bg-secondary-600 text-indigo-300 pt-6 pb-12 md:px-10 lg:max-w-lg lg:w-full lg:mx-auto lg:px-0 lg:pt-0 lg:pb-24 lg:bg-transparent lg:row-start-1 lg:col-start-2">
            <div className="max-w-2xl mx-auto px-4 lg:max-w-none lg:px-0">
              <div className="h-40 border-secondary-400 bg-secondary-400 border rounded-xl mb-12 animate-pulse" />

              <div className="w-36 bg-secondary-100 h-4 rounded mb-3 animate-pulse" />
              <div className="w-full bg-white h-8 rounded mb-3 animate-pulse  mb-8 md:mb-12" />
              <ul className="text-lg md:text-xl font-medium">
                <li className="flex items-start space-x-4">
                  <div className="bg-secondary-500 w-16 h-16 rounded-md p-2">
                    <UserGroupIcon className="flex-none object-center object-cover text-white" />
                  </div>
                  <div className="flex-auto space-y-1">
                    <h3 className="text-white">Number of Seats</h3>
                    <div className="flex-none bg-secondary-100 h-6 w-6 animate-pulse rounded-md" />
                  </div>
                  <div className="flex-none bg-secondary-100 h-6 w-24 animate-pulse rounded-md" />
                </li>
              </ul>
              <dl className="text-lg md:text-xl text-secondary-100 font-medium space-y-3 md:space-y-6 pt-6">
                <div className="flex items-center justify-between">
                  <dt>Subtotal</dt>
                  <dd>
                    <div className="flex-none bg-secondary-100 h-6 w-24 animate-pulse rounded-md" />
                  </dd>
                </div>

                <p className="border-t border-secondary-100 flex w-full mb-0" />
                <div className="flex items-center justify-between text-white">
                  <dt>Total</dt>
                  <dd>
                    <div className="flex-none bg-secondary-100 h-6 w-24 animate-pulse rounded-md" />
                  </dd>
                </div>
              </dl>
            </div>
          </section>
          <section className="pb-16 pt-8 lg:max-w-lg lg:w-full lg:mx-auto lg:pt-0 lg:pb-24 lg:row-start-1 lg:col-start-1">
            <div className="max-w-2xl mx-auto px-4 lg:max-w-none lg:px-0">
              <Trial />
              <div className="w-48 bg-gray-500 h-6 rounded mb-3 animate-pulse" />
              <CardSkeleton />
              <div className="w-36 bg-gray-500 h-6 rounded mb-3 animate-pulse mt-10" />
              <div className="w-full bg-gray-300 h-4 rounded mb-3 animate-pulse" />
              <div className="flex space-x-3 animate-pulse">
                <div className="w-1/2 h-24 w-60 bg-gray-100 border-gray-300 rounded-lg shadow-sm" />
                <div className="w-1/2 h-24 w-60 bg-gray-100 border-gray-300 rounded-lg shadow-sm" />
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <ErrorPage
        title="Something went wrong!"
        text="We keep track of these errors, but feel free to contact us if refreshing doesn't fix things."
        subtitle="500 Error"
      />
    );
  }

  const options: StripeElementsOptions = {
    clientSecret: data?.setupIntent?.clientSecret,
    appearance: {
      theme: 'stripe',
      variables: {
        colorPrimary: '#402B70',
        colorBackground: '#ffffff',
        colorText: '#374151',
        fontFamily: 'Inter, sans-serif',
        borderRadius: '6px',
      },
    },
  };

  return (
    <div className="bg-white">
      <div
        className="hidden lg:block fixed top-0 left-0 w-1/2 h-full bg-white"
        aria-hidden="true"
      />
      <div
        className="hidden lg:block fixed top-0 right-0 w-1/2 h-full bg-secondary-600"
        aria-hidden="true"
      />

      <CheckoutHeader />

      <Elements stripe={stripePromise} options={options}>
        {props.children}
      </Elements>
    </div>
  );
};

export default CheckoutLayout;
