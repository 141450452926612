import React, { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import flatten from 'lodash/flatten';
import { Column } from 'react-table';
import get from 'lodash/get';
import format from 'date-fns/format';
import fromUnixTime from 'date-fns/fromUnixTime';

import organisationService from '../../services/organisationService';
import { Invoice } from '../../types/organisation';
import Table from '../Table';
import Spinner from '../../Spinner';
import InvoicesTableSkeleton from './InvoicesTableSkeleton';

const Invoices: React.FC = () => {
  const invoices = useInfiniteQuery<
    { data: Invoice[]; has_more: boolean },
    any,
    Invoice
  >('invoices', organisationService.getInvoices, {
    select: data => ({
      ...data,
      pages: data.pages.flatMap(page => page.data),
    }),
    getPreviousPageParam: firstPage => {
      if (firstPage.data) {
        return firstPage.data[firstPage.data.length - 1].id;
      }
      return false;
    },
    getNextPageParam: lastPage => {
      if (lastPage.has_more) {
        return lastPage.data[lastPage.data.length - 1].id;
      }
      return false;
    },
  });

  const columns = useMemo<Column<Invoice>[]>(
    () => [
      {
        Header: 'Date',
        accessor: invoice => (
          <span>{format(fromUnixTime(invoice.created), 'dd/MM/yy')}</span>
        ),
      },
      {
        Header: 'Description',
        accessor: invoice => (
          <span>{get(invoice, 'items[0].description', '')}</span>
        ),
      },
      {
        Header: 'Amount',
        accessor: invoice => (
          <span>£{(invoice.totalAmountPaid / 100).toFixed(2)}</span>
        ),
      },
      {
        id: 'download',
        accessor: invoice => {
          return (
            <a
              href={invoice.invoicePdfUrl}
              target="_blank"
              rel="noreferrer"
              className="text-link"
            >
              Download Invoice
            </a>
          );
        },
      },
    ],
    []
  );

  if (invoices.isError && invoices.error.code === 'billing/org-plan-invalid') {
    return null;
  }

  if (invoices.isLoading)
    return (
      <section aria-labelledby="invoices-header">
        <div className="bg-white pt-6 shadow sm:rounded-md sm:overflow-hidden">
          <div className="px-4 sm:px-6">
            <h2
              id="invoices-header"
              className="text-lg leading-6 font-medium text-gray-900"
            >
              Billing history
            </h2>
          </div>
          <div className="p-6">
            <InvoicesTableSkeleton />
          </div>
        </div>
      </section>
    );

  const data = flatten(invoices.data?.pages);

  return (
    <section aria-labelledby="invoices-header">
      <div className="bg-white pt-6 shadow sm:rounded-md sm:overflow-hidden">
        <div className="px-4 sm:px-6">
          <h2
            id="invoices-header"
            className="text-lg leading-6 font-medium text-gray-900"
          >
            Billing history
          </h2>
        </div>
        <div className="p-6">
          <Table columns={columns} data={data} />
          {invoices.hasNextPage ? (
            <div className="text-center mt-4">
              <button
                onClick={() => invoices.fetchNextPage()}
                className="btn btn-primary"
                disabled={invoices.isFetching}
              >
                {invoices.isFetching ? (
                  <>
                    <Spinner
                      className="h-5 w-5 text-white"
                      aria-hidden="true"
                    />
                    Loading
                  </>
                ) : (
                  'Load More'
                )}
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default Invoices;
