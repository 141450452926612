import React from 'react';
import clsx from 'clsx';
import get from 'lodash/get';

type AvatarProps = {
  firstName: string | undefined;
  lastName: string | undefined;
  className?: string;
};

const Avatar: React.FC<AvatarProps> = props => {
  const monogram =
    get(props, 'firstName[0]', '') + get(props, 'lastName[0]', '');

  return (
    <div
      className={clsx(
        'rounded-full bg-primary-300 text-white flex items-center justify-center',
        props.className
      )}
    >
      {monogram}
    </div>
  );
};

export default Avatar;
