import * as Yup from 'yup';
import React from 'react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { usePMUsers } from '../../hooks/usePMUsers';
import { ProjectDTO, ProjectError } from '../../types/project';
import projectService from '../../services/projectService';

import Drawer from '../Drawer';
import Spinner from '../../Spinner';
import ProjectForm from './ProjectForm';
import { projectKeys } from '../../config';

type NewProjectDrawerProps = {
  onClose: () => void;
  open: boolean;
};

const schema = Yup.object().shape({
  name: Yup.string().required('First name is required'),
  projectManager: Yup.object().required('Select a project manager'),
  projectEndDate: Yup.date()
    .min(new Date(), 'Select date in the future')
    .max(new Date('9999-12-30'), 'Must be a valid date.'),
  keyContact: Yup.object().shape({
    fullName: Yup.string(),
    email: Yup.string().email('Must be a valid email'),
    phoneNumber: Yup.string(),
  }),
  billingContact: Yup.object().shape({
    fullName: Yup.string(),
    email: Yup.string().email('Must be a valid email'),
    phoneNumber: Yup.string(),
  }),
  billingInstructions: Yup.string(),
  isSecret: Yup.boolean(),
});

const NewProjectDrawer: React.FC<NewProjectDrawerProps> = props => {
  const queryClient = useQueryClient();
  const users = usePMUsers();
  const { brandId } = useParams<{ brandId: string }>();

  const createProject = useMutation(projectService.createProject, {
    onError: () => {
      toast.error('Something went wrong. Try again!');
    },
    onSettled: async () => {
      await queryClient.refetchQueries(projectKeys.allByBrand(brandId));
    },
  });

  const initialValues: ProjectDTO = {
    name: '',
    projectManager: '',
    projectEndDate: '',
    keyContact: {
      fullName: '',
      email: '',
      phoneNumber: '',
    },
    billingContact: {
      fullName: '',
      email: '',
      phoneNumber: '',
    },
    billingInstructions: '',
    isSecret: false,
  };

  const handleOnSubmit = async (
    values: ProjectDTO,
    formikBag: FormikHelpers<ProjectDTO>
  ) => {
    try {
      await createProject.mutateAsync({ data: values, brandId: brandId });
      props.onClose();
    } catch (e) {
      const error = e as ProjectError;

      switch (error.code) {
        case 'projects/project-name-already-in-use':
          formikBag.setFieldError('name', error.detail);
          break;
        case 'projects/cannot-set-project-manager':
          formikBag.setFieldError('projectManager', error.detail);
          break;
      }
    }
  };

  return (
    <Drawer open={props.open} onClose={props.onClose}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleOnSubmit}
      >
        {({ isSubmitting, dirty }: FormikProps<ProjectDTO>) => (
          <Drawer.FormContainer
            open={props.open}
            onClose={props.onClose}
            title="New Project"
            description="Get started by filling in the information below to create your new project."
            Footer={
              <>
                <button
                  className="btn btn-white"
                  onClick={props.onClose}
                  disabled={isSubmitting}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary ml-4"
                  type="submit"
                  disabled={isSubmitting || !dirty}
                >
                  {isSubmitting ? (
                    <>
                      <Spinner
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      Loading
                    </>
                  ) : (
                    'Create'
                  )}
                </button>
              </>
            }
          >
            <ProjectForm isSubmitting={isSubmitting} users={users.data || []} />
          </Drawer.FormContainer>
        )}
      </Formik>
    </Drawer>
  );
};

export default NewProjectDrawer;
