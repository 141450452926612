import React from 'react';
import Avatar from '../Avatar';

type EditorLineProps = {
  text: string;
  date: string;
  user: {
    firstName: string;
    lastName: string;
  };
};

const EditorLine: React.FC<EditorLineProps> = props => {
  return (
    <div className="text-xs flex justify-between items-center">
      <div>
        <span className="text-gray-400 mr-1">{props.text}</span>
        <span className="text-gray-500">{props.date}</span>
      </div>
      <span className="text-gray-900 flex items-center">
        <Avatar
          firstName={props.user.firstName}
          lastName={props.user.lastName}
          className="w-5 h-5 mr-2"
        />
        by {props.user.firstName} {props.user.lastName}
      </span>
    </div>
  );
};

export default EditorLine;
