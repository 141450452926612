import { PromotionCode, SetupIntent } from '../types/checkout';
import axios from '../utils/axios';
import { SubscriptionStatus } from '../types/organisation';

class CheckoutService {
  setupIntent = async (): Promise<SetupIntent> => {
    const response = await axios.post<SetupIntent>('/billing/setup-intents');
    return response.data;
  };

  createSubscription = async ({
    priceId,
    numberOfSeats,
    discountCode,
  }: {
    priceId: string;
    numberOfSeats: number;
    discountCode?: string;
  }): Promise<{ id: string; status: SubscriptionStatus }> => {
    const response = await axios.post<{
      id: string;
      status: SubscriptionStatus;
    }>('/billing/subscriptions', { priceId, numberOfSeats, discountCode });
    return response.data;
  };

  checkCoupon = async (couponCode: string): Promise<PromotionCode> => {
    const response = await axios.post<PromotionCode>('/billing/coupons', {
      couponCode: couponCode,
    });
    return response.data;
  };
}

const checkoutService = new CheckoutService();

export default checkoutService;
