import React from 'react';
import { PlusIcon, PencilAltIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import { usePermission } from '../../hooks/usePermission';

type EmptyWIPProps = {
  onNewTask: () => void;
};

const EmptyWIP: React.FC<EmptyWIPProps> = props => {
  const permission = usePermission();

  return (
    <div className="text-center mt-12">
      <svg
        enableBackground="new 0 0 489.5 489.5"
        version="1.1"
        viewBox="0 0 489.5 489.5"
        className="mx-auto h-12 w-12"
        fill="#9ca3af"
        aria-hidden="true"
      >
        <rect y="1.35" width="97.5" height="97.5" />
        <rect x="138.3" y="27.45" width="351.2" height="43.1" />
        <rect y="195.95" width="97.5" height="97.5" />
        <rect x="138.3" y="222.05" width="351.2" height="43.1" />
        <rect y="390.65" width="97.5" height="97.5" />
        <rect x="138.3" y="416.65" width="351.2" height="43.1" />
      </svg>
      <h3 className="mt-2 text-sm font-medium text-gray-900">No Tasks</h3>
      <p className="mt-1 text-sm text-gray-500">
        {permission.data?.isAdmin
          ? 'Get started by creating a new task.'
          : 'No task been assigned to you.'}
      </p>
      <div className="mt-6">
        {permission.data?.isAdmin || permission.data?.isMember ? (
          <Link
            role="button"
            className="btn btn-secondary"
            to="/work-in-progress/templates"
          >
            <PencilAltIcon className="leading-icon" aria-hidden="true" />
            Manage Templates
          </Link>
        ) : null}
        {permission.data?.isAdmin ? (
          <button
            type="button"
            className="ml-4 btn btn-primary"
            onClick={props.onNewTask}
          >
            <PlusIcon className="leading-icon" aria-hidden="true" />
            New Task
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default EmptyWIP;
