import React from 'react';
import clsx from 'clsx';

type PercentageBadgeProps = {
  percentage: number;
  className?: string;
};

const PercentageBadge: React.FC<PercentageBadgeProps> = props => {
  const getPercentageClasses = () => {
    if (props.percentage <= 100) {
      return 'bg-green-100 text-green-800';
    }

    return 'bg-red-100 text-red-900';
  };

  return (
    <span
      className={clsx(
        'rounded-full text-xs leading-5 font-medium py-1 px-2',
        getPercentageClasses(),
        props.className
      )}
    >
      {Math.round(props.percentage)}%
    </span>
  );
};

export default PercentageBadge;
