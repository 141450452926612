import React from 'react';
import { FastField, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { InfiniteData, useMutation, useQueryClient } from 'react-query';

import { AlgoliaTask, EditTaskDTO, Task } from '../../types/task';
import taskService from '../../services/taskService';
import { taskKeys } from '../../config';
import { useProjects } from '../../hooks/useProjects';
import { useBrands } from '../../hooks/useBrands';
import { usePermission } from '../../hooks/usePermission';
import { usePMUsers } from '../../hooks/usePMUsers';

import Drawer from '../Drawer';
import Spinner from '../../Spinner';
import TaskDetailsForm from './TaskDetailsForm';
import StatusRadio from './StatusRadio';
import DeleteButton from '../DeleteButton';

type EditTaskProps = {
  open: boolean;
  onClose: () => void;
  data: Task | null;
  refetchTasks: () => Promise<void>;
};

const schema = Yup.object().shape({
  name: Yup.string().required('Task name is required.'),
  brand: Yup.object().required('Select a brand.'),
  project: Yup.object().required('Select a project.'),
  activity: Yup.string(),
  deadline: Yup.string(),
  description: Yup.string(),
  assignees: Yup.array().of(
    Yup.object().shape({
      userOrInviteId: Yup.string()
        .typeError('Assignee ID must be a string')
        .required('Select an assignee'),
      estimateInHours: Yup.number()
        .typeError('It is not a valid number.')
        .min(0, 'Estimation must be greater than 0.')
        .nullable(),
    })
  ),
});

const EditTaskDrawer: React.FC<EditTaskProps> = props => {
  const users = usePMUsers();
  const projects = useProjects();
  const brands = useBrands();
  const queryClient = useQueryClient();
  const permission = usePermission();

  const updateTask = useMutation(taskService.updateTask, {
    onSuccess: async updatedTask => {
      props.onClose();
      toast.success('The task has been successfully updated!');
      await queryClient.cancelQueries(taskKeys.all);

      const allTaskQuery = queryClient.getQueriesData<
        InfiniteData<AlgoliaTask>
      >(taskKeys.all);

      allTaskQuery.forEach(query => {
        queryClient.setQueryData(query[0], () => {
          const updatedPages = query[1].pages.map(page => {
            const updatedHits = page.hits.map(task => {
              if (task.id !== updatedTask.id) {
                return task;
              }

              return updatedTask;
            });
            return {
              ...page,
              hits: updatedHits,
            };
          });

          return {
            ...query[1],
            pages: updatedPages,
          };
        });
      });
      props.refetchTasks();
    },
  });

  const deleteTask = useMutation(taskService.deleteTask, {
    onSuccess: async deletedTask => {
      props.onClose();
      toast.success('The task has been successfully deleted!');
      await queryClient.cancelQueries(taskKeys.all);

      const allTaskQuery = queryClient.getQueriesData<
        InfiniteData<AlgoliaTask>
      >(taskKeys.all);

      allTaskQuery.forEach(query => {
        queryClient.setQueryData(query[0], () => {
          const updatedPages = query[1].pages.map(page => {
            const updatedHits = page.hits.filter(
              task => task.id !== deletedTask.id
            );
            return {
              ...page,
              hits: updatedHits,
            };
          });

          return {
            ...query[1],
            pages: updatedPages,
          };
        });
      });
    },
  });

  const handleOnSubmit = async (values: EditTaskDTO): Promise<void> => {
    try {
      await updateTask.mutateAsync({
        values: values,
        id: props.data?.id || '',
      });
    } catch (e) {
      toast.error('Try again! Something went wrong.');
    }
  };

  const handleDelete = async () => {
    try {
      await deleteTask.mutateAsync(props.data?.id || '');
    } catch (e) {
      //
    }
  };

  const initialValues: EditTaskDTO = {
    name: props.data?.name || '',
    brand: props.data?.brand || '',
    project: props.data?.project || '',
    activity: props.data?.activity || '',
    deadline: props.data?.deadline
      ? format(parseISO(props.data?.deadline || ''), 'yyyy-MM-dd')
      : '',
    description: props.data?.description || '',
    status: props.data?.status || '',
    assignees: props.data?.assignees.map(assignee => ({
      userOrInviteId: assignee.userOrInviteId,
      estimateInHours: assignee.estimateInHours,
    })),
  };

  return (
    <Drawer open={props.open} onClose={props.onClose}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleOnSubmit}
      >
        {({ isSubmitting, dirty, values }: FormikProps<EditTaskDTO>) => (
          <Drawer.FormContainer
            open={props.open}
            onClose={props.onClose}
            title="Edit Task"
            description="View and update task details"
            Footer={
              <>
                <button
                  className="btn btn-white"
                  onClick={props.onClose}
                  disabled={isSubmitting}
                  type="button"
                >
                  Cancel
                </button>
                {permission.data?.isAdmin || permission.data?.isMember ? (
                  <DeleteButton
                    modalTitle="Delete task"
                    modalText="Once you delete this task, you will lose all data associated with it. This action is irreversible."
                    className="btn btn-delete capitalize ml-4"
                    disabled={isSubmitting || deleteTask.isLoading}
                    type="button"
                    onClick={handleDelete}
                  >
                    {deleteTask.isLoading ? 'Deleting' : 'Delete Task'}
                  </DeleteButton>
                ) : null}
                <button
                  className="btn btn-primary ml-4"
                  type="submit"
                  disabled={isSubmitting || !dirty}
                >
                  {isSubmitting ? (
                    <>
                      <Spinner
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      Loading
                    </>
                  ) : (
                    'Update'
                  )}
                </button>
              </>
            }
          >
            <div className="sm:px-6 px-4 divide-y divide-gray-200">
              <div className="py-6">
                <FastField name="status" component={StatusRadio} />
              </div>
              <TaskDetailsForm
                deadline={values.deadline}
                isSubmitting={isSubmitting}
                users={users.data || []}
                brands={brands.data || []}
                projects={
                  projects?.data?.filter(project => {
                    // if no brand selected yet, display all
                    if (!values.brand) {
                      return true;
                    }

                    return project.brandId === values.brand.id;
                  }) || []
                }
              />
            </div>
          </Drawer.FormContainer>
        )}
      </Formik>
    </Drawer>
  );
};

export default EditTaskDrawer;
