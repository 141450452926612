import React, { Fragment, memo } from 'react';
import { Link } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { MenuIcon, XIcon, LogoutIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

import { useIsAdmin, useUser } from '../../hooks/useUser';
import { usePermission } from '../../hooks/usePermission';
import authService from '../../services/authService';
import { APP_TITLE } from '../../config';
import logo from '../../assets/logo-primary.svg';

import Avatar from '../../components/Avatar';

const DashboardMenu: React.FC = () => {
  const user = useUser();
  const isAdmin = useIsAdmin();
  const permission = usePermission();

  const userNavigation = [{ name: 'Profile', href: '/account' }];

  //settings are available only for admins
  if (!isAdmin.isLoading && isAdmin.data) {
    userNavigation.push({ name: 'Organisation Management', href: '/settings' });
  }

  const navigation = [
    { name: 'WIP', href: '/work-in-progress' },
    // TODO: add back later when features are implemented
    // { name: 'Ideas Machine', href: '/ideas-machine' },
    { name: 'Strategic Planning', href: '/strategic-planning' },
    { name: 'Brands', href: '/brands' },
  ];

  if (
    !permission.isLoading &&
    (permission.data?.isAdmin || permission.data?.isMember)
  ) {
    navigation.unshift({ name: 'Resource Planner', href: '/resource-planner' });
  }

  return (
    <Disclosure as="header" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="max-w-screen-xl mx-auto px-2 sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-8">
            <div className="relative h-16 flex justify-between">
              <div className="relative z-10 px-2 flex lg:px-0">
                <div className="flex-shrink-0 flex items-center">
                  <Link to="/" title={APP_TITLE}>
                    <img
                      className="block h-8 w-auto"
                      src={logo}
                      alt={APP_TITLE}
                    />
                  </Link>
                </div>
              </div>
              <div className="relative z-10 flex items-center lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-900">
                  <span className="sr-only">Open menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="hidden lg:relative lg:z-10 lg:ml-4 lg:flex lg:items-center">
                {/* Profile dropdown */}
                <Menu as="div" className="flex-shrink-0 relative ml-4">
                  <div>
                    <Menu.Button className="bg-white rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900">
                      <span className="sr-only">Open user menu</span>
                      <Avatar
                        className="w-8 h-8 text-sm"
                        firstName={user?.data?.firstName || ''}
                        lastName={user?.data?.lastName || ''}
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none divide-y divide-gray-200">
                      {userNavigation.map(item => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <NavLink
                              to={item.href}
                              className={clsx(
                                active ? 'bg-gray-100' : '',
                                'block py-2 px-4 text-sm text-gray-700'
                              )}
                              activeClassName="bg-secondary-50 text-secondary-700"
                            >
                              {item.name}
                            </NavLink>
                          )}
                        </Menu.Item>
                      ))}
                      <Menu.Item>
                        <button
                          className="block py-2 px-4 text-sm text-gray-700 w-full text-left flex items-center hover:bg-gray-100"
                          onClick={authService.logout}
                        >
                          <LogoutIcon className="w-4 h-4 text-gray-400 mr-2" />
                          Logout
                        </button>
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
            <nav
              className="hidden lg:py-5 lg:flex lg:space-x-5"
              aria-label="Global"
            >
              {navigation.map(item => (
                <NavLink
                  key={item.name}
                  to={item.href}
                  className="rounded-md py-2 px-3 inline-flex items-center text-sm font-medium text-gray-900 hover:bg-gray-50 hover:text-gray-900"
                  activeClassName="bg-secondary-50 text-secondary-700 hover:bg-secondary-50"
                >
                  {item.name}
                </NavLink>
              ))}
            </nav>
          </div>

          <Disclosure.Panel as="nav" className="lg:hidden" aria-label="Global">
            <div className="pt-2 pb-3 px-2 space-y-1">
              {navigation.map(item => (
                <Disclosure.Button
                  key={item.name}
                  as={NavLink}
                  to={item.href}
                  className="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-50 hover:text-gray-900"
                  activeClassName="bg-secondary-50 text-secondary-700 hover:bg-secondary-50"
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
            <div className="border-t border-gray-200 pt-4 pb-3">
              <div className="px-4 flex items-center">
                <div className="flex-shrink-0">
                  <Avatar
                    firstName={user?.data?.firstName || ''}
                    lastName={user?.data?.lastName || ''}
                    className="w-10 h-10 text-lg"
                  />
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">
                    {user?.data?.firstName} {user?.data?.lastName}
                  </div>
                  <div className="text-sm font-medium text-gray-500">
                    {user?.data?.organisation?.name}
                  </div>
                </div>
              </div>
              <div className="mt-3 px-2 space-y-1">
                {userNavigation.map(item => (
                  <Disclosure.Button
                    key={item.name}
                    as={Link}
                    to={item.href}
                    className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
                <button
                  onClick={authService.logout}
                  className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900 w-full text-left flex items-center"
                >
                  <LogoutIcon className="w-4 h-4 text-gray-400 mr-2" />
                  Logout
                </button>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default memo(DashboardMenu);
