import React from 'react';
import AuthLayoutHeader from '../components/auth/AuthLayoutHeader';
import ResetPasswordForm from '../components/auth/ResetPasswordForm';

const ResetPassword: React.FC = () => {
  return (
    <div>
      <AuthLayoutHeader title="Set New Password" />
      <ResetPasswordForm />
    </div>
  );
};

export default ResetPassword;
