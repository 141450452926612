import React from 'react';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { toast } from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

import { useBrands } from '../hooks/useBrands';
import strategicPlanService from '../services/strategicPlanService';
import {
  SPDTO,
  StrategicPlan as StrategicPlanType,
} from '../types/strategic-plan';
import { brandKeys, strategicPlannerKeys } from '../config';

import OverviewForm from '../components/StrategicPlanner/OverviewForm';
import SectionNavigation from '../components/StrategicPlanner/SectionNavigation';

const schema = Yup.object().shape({
  brand: Yup.object().required('Brand is required'),
  project: Yup.object(),
  name: Yup.string().required('Name is required'),
});

const NewStrategicPlan: React.FC = () => {
  const { brandId } = useParams<{ brandId: string }>();
  const brands = useBrands();
  const history = useHistory();
  const queryClient = useQueryClient();

  const create = useMutation(strategicPlanService.create, {
    onSuccess: async plan => {
      await queryClient.cancelQueries(
        strategicPlannerKeys.plans({
          brandId: plan.brand.id,
          organisationId: plan.organisation.id,
        })
      );

      const previousPlanList = queryClient.getQueryData<StrategicPlanType[]>(
        strategicPlannerKeys.plans({
          brandId: plan.brand.id,
          organisationId: plan.organisation.id,
        })
      );

      if (previousPlanList) {
        queryClient.setQueryData(
          strategicPlannerKeys.plans({
            brandId: plan.brand.id,
            organisationId: plan.organisation.id,
          }),
          [...previousPlanList, plan]
        );
      }

      // update brand 'planCount'
      queryClient.invalidateQueries(brandKeys.all);

      history.push(
        `/strategic-planning/${brandId}/${plan.id}/section/overview?created=true`
      );
    },
    onError: () => {
      toast.error('Something went wrong. Try again!');
    },
  });

  const initialValues: SPDTO = {
    brand: brands.data?.find(brand => brand.id === brandId) || '',
    project: '',
    name: '',
  };

  const handleOnSubmit = async (values: SPDTO) => {
    try {
      await toast.promise(create.mutateAsync(values), {
        loading: 'Creating..',
        success: (
          <span>
            The plan has been <b>successfully</b> created!
          </span>
        ),
        error: (
          <span>
            <b>Error</b> occurred during creating.
          </span>
        ),
      });
    } catch (e) {
      //
    }
  };

  return (
    <div className="flex flex-col md:flex-row flex-1">
      <SectionNavigation />
      <main className="px-4 lg:px-8 flex-grow">
        <div className="flex-grow flex flex-col sm:flex-row sm:items-center justify-between pt-5 md:pt-10 pb-5 md:pb-7 border-b border-gray-200">
          <p className="text-lg leading-6 font-medium text-gray-900">
            New Strategic Plan
          </p>
        </div>
        <p className="text-gray-700 text-lg leading-6 font-normal py-6">
          Project overview
        </p>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={handleOnSubmit}
        >
          {({ isSubmitting, dirty, values }: FormikProps<SPDTO>) => (
            <Form className="flex flex-col">
              <OverviewForm values={values} isSubmitting={isSubmitting} />
              <button
                className="btn btn-primary ml-auto mt-6"
                type="submit"
                disabled={isSubmitting || !dirty}
              >
                Create plan
              </button>
            </Form>
          )}
        </Formik>
      </main>
    </div>
  );
};

export default NewStrategicPlan;
