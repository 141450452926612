import React from 'react';
import { Column, SortingRule } from 'react-table';
import clsx from 'clsx';

import { formatStringDate, getDeadlineClass } from '../../utils/helper';
import { Task, TaskFilter } from '../../types/task';
import { usePermission } from '../../hooks/usePermission';

import Table from '../Table';
import Avatar from '../Avatar';

type WIPTableProps = {
  data: Task[];
  onSortBy: (sortBy: SortingRule<Task>[]) => void;
  onEdit: React.Dispatch<
    React.SetStateAction<{ open: boolean; task: Task | null }>
  >;
  filter: TaskFilter;
};

const WIPTable: React.FC<WIPTableProps> = props => {
  const { onEdit } = props;
  const permissions = usePermission();
  const permissionsData = permissions.data;

  const columns = React.useMemo<Column<Task>[]>(
    () => [
      {
        id: 'project',
        Header: 'Project',
        disableSortBy: true,
        accessor: ({ brand, project }) => (
          <div className="text-sm" style={{ maxWidth: 200 }}>
            <span className="text-gray-900 block truncate">{project.name}</span>
            <span className="text-gray-500 block truncate">{brand.name}</span>
          </div>
        ),
        enableFooter: true,
        Footer: () => <span className="text-gray-900">Total Estimate</span>,
      },
      {
        id: 'activity',
        Header: 'Activity',
        accessor: ({ activity }) => (
          <p style={{ maxWidth: 200 }} className="truncate">
            {activity ? activity : ''}
          </p>
        ),
      },
      {
        id: 'name',
        Header: 'Task',
        accessor: ({ name }) => (
          <span style={{ maxWidth: 200 }} className="truncate block">
            {name}
          </span>
        ),
      },
      {
        id: 'estimateInHours',
        Header: 'Total estimate (hours)',
        accessor: column => {
          if (!Array.isArray(column.assignees) || column.assignees.length < 1) {
            return '-';
          }

          const totalEstimate = column.assignees.reduce((acc, curr) => {
            // assignee estimate should only be counted if there are
            // no filters active for assignee, or assignee is in the filter list
            let shouldAssigneeBeCounted = true;
            if (
              Array.isArray(props.filter.assignees) &&
              props.filter.assignees.length > 0
            ) {
              shouldAssigneeBeCounted = Boolean(
                props.filter.assignees.find(fa => fa.id === curr.userOrInviteId)
              );
            }

            if (shouldAssigneeBeCounted && curr.estimateInHours) {
              return acc + curr.estimateInHours;
            } else {
              return acc;
            }
          }, 0);

          return <span>{totalEstimate}h</span>;
        },
      },
      {
        id: 'assignees',
        Header: 'Assignees',
        disableSortBy: true,
        accessor: ({ assignees }) => {
          if (!Array.isArray(assignees) || assignees.length < 1) {
            return '-';
          }

          let assigneesToDisplay;
          if (
            Array.isArray(props.filter.assignees) &&
            props.filter.assignees.length > 0
          ) {
            // show only filtered assignees
            assigneesToDisplay = assignees.filter(a => {
              return Boolean(
                props.filter.assignees.find(fa => fa.id === a.userOrInviteId)
              );
            });
          } else {
            assigneesToDisplay = assignees;
          }

          return (
            <div className="space-y-2">
              {assigneesToDisplay.map(assignee => (
                <div
                  key={assignee.userOrInviteId}
                  className="flex items-center"
                >
                  <Avatar
                    className="w-5 h-5 mr-3 text-xs"
                    firstName={assignee?.firstName}
                    lastName={assignee?.lastName}
                  />{' '}
                  {assignee?.firstName} {assignee?.lastName}
                </div>
              ))}
            </div>
          );
        },
      },
      {
        id: 'deadline',
        Header: 'Deadline',
        accessor: ({ deadline }) => {
          if (!deadline) {
            return '-';
          }

          return (
            <span
              className={clsx(
                getDeadlineClass(deadline),
                'rounded-full text-xs py-1 px-2'
              )}
            >
              {formatStringDate(deadline)}
            </span>
          );
        },
      },
      {
        id: 'status',
        Header: 'Status',
        disableSortBy: true,
        accessor: ({ status }) => {
          if (status === 'TODO') return 'To Do';
          if (status === 'IN_PROGRESS') return 'In Progress';
          return 'Done';
        },
      },
      {
        id: 'edit',
        disableSortBy: true,
        accessor: task => {
          if (!permissionsData?.canEditProjectById(task.project.id)) {
            return null;
          }

          return (
            <button
              className="text-link"
              title="Edit Task"
              onClick={() => onEdit({ open: true, task: task })}
            >
              Edit
            </button>
          );
        },
        Footer: ({ data: tasks }) => {
          const sum = tasks.reduce((sum: number, task: Task) => {
            if (!Array.isArray(task.assignees) || task.assignees.length < 1) {
              return sum;
            }

            const taskEstimate = task.assignees.reduce((acc, curr) => {
              // assignee estimate should only be counted if there are
              // no filters active for assignee, or assignee is in the filter list
              let shouldAssigneeBeCounted = true;
              if (
                Array.isArray(props.filter.assignees) &&
                props.filter.assignees.length > 0
              ) {
                shouldAssigneeBeCounted = Boolean(
                  props.filter.assignees.find(
                    fa => fa.id === curr.userOrInviteId
                  )
                );
              }

              if (shouldAssigneeBeCounted && curr.estimateInHours) {
                return acc + curr.estimateInHours;
              } else {
                return acc;
              }
            }, 0);

            return sum + taskEstimate;
          }, 0);

          return `${sum}h`;
        },
      },
    ],
    [onEdit, permissionsData, props.filter.assignees]
  );

  return (
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="overflow-hidden">
          <Table
            columns={columns}
            data={props.data}
            onSortBy={props.onSortBy}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(WIPTable);
