import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';

import { useMembers } from '../hooks/useMembers';
import organisationService from '../services/organisationService';
import { MemberCount } from '../types/organisation';

import AddUser from '../components/Settings/AddUser';
import MemberTable from '../components/Settings/MemberTable';
import MemberTableSkeleton from '../components/Settings/MemberTableSkeleton';

const TeamMembers: React.FC = () => {
  const members = useMembers({ includeInvite: true });
  const [open, setOpen] = useState<boolean>(false);
  const memberCount = useQuery<MemberCount>(
    'member-count',
    organisationService.getMemberCount
  );

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
      {/* AddUser drawer */}
      <AddUser open={open} onClose={() => setOpen(false)} />

      <section aria-labelledby="payment-details-heading">
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="bg-white py-6 px-4 sm:p-6">
            <div className="block sm:flex mb-6 items-center">
              <div className="mb-4 sm:mb-0">
                <h2 className="text-lg leading-none font-bold text-gray-900 mb-0">
                  Team members
                </h2>
                {memberCount.isLoading ? (
                  <span className="h-4 w-28 bg-gray-400 mr-6 inline-block md:hidden animate-pulse rounded" />
                ) : (
                  <span className="text-sm leading-5 text-gray-600 mr-6 md:hidden">
                    {memberCount.data?.used} of{' '}
                    {memberCount.data?.nbOrganisationSeats} Seats used
                  </span>
                )}
              </div>
              <div className="block sm:flex ml-auto items-center">
                {memberCount.isLoading ? (
                  <>
                    <span className="h-4 w-28 bg-gray-400 mr-6 hidden md:inline-block animate-pulse rounded" />
                    <span className="h-10 w-20 bg-secondary-500 rounded-md inline-block animate-pulse" />
                  </>
                ) : (
                  <>
                    <span className="text-sm leading-5 text-gray-600 mr-6 hidden md:inline">
                      {memberCount.data?.used} of{' '}
                      {memberCount.data?.nbOrganisationSeats} Seats used
                    </span>
                    {(memberCount.data?.used || 0) <
                    (memberCount.data?.nbOrganisationSeats || 0) ? (
                      <button
                        className="btn btn-primary"
                        onClick={() => setOpen(true)}
                      >
                        Add user
                      </button>
                    ) : (
                      <>
                        <Link
                          className="btn btn-primary mr-6 mb-2 sm:mb-0"
                          to="/settings/billing"
                        >
                          Purchase Seats
                        </Link>
                        <button
                          className="btn btn-white cursor-not-allowed"
                          disabled
                        >
                          Add user
                        </button>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="flex flex-col">
              {members.data ? (
                <MemberTable data={members.data} />
              ) : (
                <MemberTableSkeleton />
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TeamMembers;
