import React, { useState } from 'react';
import { PlusIcon } from '@heroicons/react/solid';

import { useBrands } from '../hooks/useBrands';
import BrandsTable from '../components/Brands/BrandsTable';
import BrandsTableSkeleton from '../components/Brands/BrandsTableSkeleton';
import NewBrandDrawer from '../components/Brands/NewBrandDrawer';
import ErrorPage from '../components/ErrorPage';
import { useIsAdmin } from '../hooks/useUser';

const Brands: React.FC = () => {
  const brands = useBrands();
  const [open, setOpen] = useState(false);
  const isAdmin = useIsAdmin();

  const Listing: React.FC = () => {
    if (brands.isLoading) {
      return <BrandsTableSkeleton />;
    }

    if (brands.data && brands.data.length === 0) {
      return (
        <div className="text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          <h3 className="mt-2 text-sm font-medium text-gray-900">No brands</h3>
          <p className="mt-1 text-sm text-gray-500">
            Get started by creating a new brand.
          </p>
          <div className="mt-6">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setOpen(true)}
            >
              <PlusIcon className="leading-icon" aria-hidden="true" />
              New Brand
            </button>
          </div>
        </div>
      );
    }

    if (brands.isError || !brands.data) {
      return (
        <ErrorPage
          title="Something went wrong!"
          text="We keep track of these errors, but feel free to contact us if refreshing doesn't fix things."
        />
      );
    }

    return <BrandsTable data={brands.data} />;
  };

  return (
    <section className="shadow lg:rounded-md sm:overflow-hidden">
      {/* Drawer to show when addBrand button is clicked*/}
      {isAdmin.data ? (
        <NewBrandDrawer open={open} onClose={() => setOpen(false)} />
      ) : null}
      <div className="bg-white py-7 px-4 sm:p-6">
        <div className="block sm:flex mb-6 items-center">
          <div className="mb-4 sm:mb-0">
            <h2 className="text-lg leading-none font-bold text-gray-900 mb-0">
              Brands
            </h2>
          </div>
          {isAdmin.data ? (
            <div className="block sm:flex ml-auto items-center">
              {brands.isLoading ? (
                <span className="h-10 w-20 bg-secondary-500 rounded-md inline-block animate-pulse" />
              ) : brands.data && brands.data.length !== 0 ? (
                <button
                  className="btn btn-primary"
                  onClick={() => setOpen(true)}
                >
                  New brand
                </button>
              ) : null}
            </div>
          ) : null}
        </div>
        <Listing />
      </div>
    </section>
  );
};

export default Brands;
