import React from 'react';
import { FastField } from 'formik';
import FormikInput from '../FormikInput';
import FormikTextarea from '../FormikTextarea';
import FormikCombobox from '../FormikCombobox';
import Avatar from '../Avatar';
import { User } from '../../types/user';

type BrandFormProps = {
  isSubmitting: boolean;
  users: User[];
};

const BrandForm: React.FC<BrandFormProps> = props => {
  return (
    <div className="px-4 divide-y divide-gray-200 sm:px-6">
      <fieldset className="space-y-6 pt-6 pb-5" disabled={props.isSubmitting}>
        <div className="flex items-center">
          <label className="text-base leading-6 font-semibold pb-0">
            Overview
          </label>
        </div>
        <FastField
          type="text"
          name="name"
          component={FormikInput}
          label="Brand Name*"
          placeholder="My Great Brand inc."
          autoFocus
        />
        <FastField
          type="text"
          name="projectManager"
          component={FormikCombobox}
          label="Project Manager"
          placeholder="Start typing to select user "
          data={props.users}
          itemToString={(user: User) =>
            user ? `${user.firstName} ${user.lastName}` : ''
          }
          renderItem={(user: User) => (
            <div className="flex items-center">
              <Avatar
                firstName={user.firstName}
                lastName={user.lastName}
                className="flex-shrink-0 h-6 w-6 rounded-full"
              />
              <span className="ml-3 block truncate">
                {`${user.firstName} ${user.lastName}`}
              </span>
            </div>
          )}
        />
        <FastField
          type="date"
          name="contractEnd"
          component={FormikInput}
          label="Contract End Date"
          placeholder="dd/mm/yy"
          max="9999-12-30"
          style={{ height: 38 }}
        />
      </fieldset>
      <fieldset className="space-y-6 pt-6 pb-5" disabled={props.isSubmitting}>
        <div className="flex items-center">
          <label className="text-base leading-6 font-semibold pb-0">
            Key Contact
          </label>
        </div>
        <FastField
          type="text"
          name="keyContact.fullName"
          component={FormikInput}
          label="Contact Name"
          placeholder="Contact Name"
        />
        <FastField
          type="email"
          name="keyContact.email"
          component={FormikInput}
          label="Contact Email"
          placeholder="tim@apple.com"
        />
        <FastField
          type="tel"
          name="keyContact.phoneNumber"
          component={FormikInput}
          label="Contact Number"
          placeholder="0123456789"
        />
      </fieldset>
      <fieldset className="space-y-6 pt-6 pb-5" disabled={props.isSubmitting}>
        <div className="flex items-center">
          <label className="text-base leading-6 font-semibold pb-0">
            Billing Contact
          </label>
        </div>
        <FastField
          type="text"
          name="billingContact.fullName"
          component={FormikInput}
          label="Contact Name"
          placeholder="Contact Name"
        />
        <FastField
          type="email"
          name="billingContact.email"
          component={FormikInput}
          label="Contact Email"
          placeholder="tim@apple.com"
        />
        <FastField
          type="tel"
          name="billingContact.phoneNumber"
          component={FormikInput}
          label="Contact Number"
          placeholder="0123456789"
        />
        <FastField
          name="billingInstructions"
          component={FormikTextarea}
          label="Billing Instructions"
        />
      </fieldset>
    </div>
  );
};

export default BrandForm;
