import React from 'react';
import { Redirect } from 'react-router-dom';

import { usePermission } from '../hooks/usePermission';
import LoadingScreen from './LoadingScreen';

const Dashboard: React.FC = () => {
  const permission = usePermission();

  if (permission.isLoading) {
    return <LoadingScreen />;
  }

  if (permission.data?.isMember || permission.data?.isAdmin) {
    return <Redirect to="/resource-planner" />;
  }

  return <Redirect to="/work-in-progress" />;
};

export default Dashboard;
