import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import { SortingRule } from 'react-table';
import isEqual from 'lodash/isEqual';
import { useParams } from 'react-router-dom';
import flatten from 'lodash/flatten';
import { useInView } from 'react-intersection-observer';

import { Task, TaskFilter, TaskStatus } from '../types/task';
import { defaultTaskFilter, taskKeys } from '../config';
import { useTasks } from '../hooks/useTasks';

import StatusFilter from '../components/WIP/StatusFilter';
import WIPTable from '../components/WIP/WIPTable';
import Filters from '../components/WIP/Filters';
import AddTaskDrawer from '../components/WIP/AddTaskDrawer';
import EmptyWIP from '../components/WIP/EmptyWIP';
import EditTaskDrawer from '../components/WIP/EditTaskDrawer';
import Spinner from '../Spinner';
import WIPTableSkeleton from '../components/WIP/WIPTableSkeleton';
import { queryClient } from '../utils/react-query';

const WIP: React.FC = () => {
  /**
   * Used to refetch tasks after a mutation
   * (delay is needed due to waiting for Algolia to sync the data)
   */
  const refetchTimerRef = useRef<NodeJS.Timeout | null>(null);
  const refetchTasks = async () => {
    if (refetchTimerRef.current) {
      clearTimeout(refetchTimerRef.current);
    }

    refetchTimerRef.current = setTimeout(() => {
      return queryClient.refetchQueries(taskKeys.all);
    }, 15000);
  };

  const { status } = useParams<{ status: TaskStatus }>();

  const [filter, setFilter] = useState<TaskFilter>({
    ...defaultTaskFilter,
    status: status,
  });
  // Task status tab changes
  useEffect(() => {
    setFilter(prevState => ({
      ...prevState,
      status: status,
    }));
  }, [status]);

  const tasks = useTasks(filter);
  const { hasNextPage, fetchNextPage } = tasks;

  const { ref, inView } = useInView({
    rootMargin: '100px 0px 0px 0px',
  });

  useEffect(() => {
    const init = async () => {
      if (inView && hasNextPage) {
        await fetchNextPage();
      }
    };
    init();
  }, [inView, hasNextPage, fetchNextPage]);

  // new task drawer
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState<{ open: boolean; task: Task | null }>({
    open: false,
    task: null,
  });

  const { orderBy: filterOrderBy } = filter;

  // Handle react-table sorting changes (in Table.tsx)
  const handleSortByChange = useCallback(
    (orderBy: SortingRule<any>[]) => {
      if (isEqual(filterOrderBy, orderBy)) return;
      setFilter(prevState => ({
        ...prevState,
        orderBy: orderBy,
      }));
    },
    [filterOrderBy]
  );

  const data = useMemo(() => {
    const taskData = flatten(tasks.data?.pages);
    return taskData ?? [];
  }, [tasks.data]);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleCloseEdit = useCallback(() => {
    setEdit(prevState => ({ ...prevState, open: false }));
  }, []);

  if (
    !tasks.isLoading &&
    data &&
    data.length < 1 &&
    isEqual(filter, { ...defaultTaskFilter, status: status })
  ) {
    return (
      <>
        <AddTaskDrawer
          open={open}
          onClose={handleDrawerClose}
          refetchTasks={refetchTasks}
        />
        <StatusFilter onNewTask={handleDrawerOpen} />
        <EmptyWIP onNewTask={handleDrawerOpen} />
      </>
    );
  }

  return (
    <section className="sm:px-4 lg:px-0">
      <StatusFilter onNewTask={handleDrawerOpen} />
      <Filters filter={filter} onChange={setFilter} />

      {!tasks.isLoading ? (
        <div className="shadow lg:rounded-md sm:overflow-hidden p-6 bg-white">
          <WIPTable
            data={data}
            onSortBy={handleSortByChange}
            onEdit={setEdit}
            filter={filter}
          />
          {hasNextPage ? (
            <div
              ref={ref}
              className="text-center mt-4 items-center flex flex-col"
            >
              <Spinner className="text-gray-400 w-8 h-8" />
              <span className="text-gray-400">Loading more...</span>
            </div>
          ) : null}
        </div>
      ) : (
        <div className="shadow lg:rounded-md sm:overflow-hidden p-6 bg-white">
          <WIPTableSkeleton />
        </div>
      )}

      <AddTaskDrawer
        open={open}
        onClose={handleDrawerClose}
        refetchTasks={refetchTasks}
      />
      <EditTaskDrawer
        open={edit.open}
        onClose={handleCloseEdit}
        data={edit.task}
        refetchTasks={refetchTasks}
      />
    </section>
  );
};

export default WIP;
