import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import AuthLayoutHeader from '../components/auth/AuthLayoutHeader';
import SignInForm from '../components/auth/SignInForm';
import SuccessMessage from '../components/SuccessMessage';

const SignIn: React.FC = () => {
  const query = new URLSearchParams(useLocation().search);
  const passwordReset = query.get('password_reset');

  return (
    <>
      <AuthLayoutHeader
        title="Login"
        backButtonLink="/"
        backButtonText="Back to Homepage"
      />
      {passwordReset ? (
        <SuccessMessage
          title="Success! Your password has been reset."
          className="mt-8"
        />
      ) : null}
      <div className="mt-6">
        <SignInForm />
        <div className="relative py-6">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white text-gray-500">
              Don’t have an account?
            </span>
          </div>
        </div>
        <div>
          <Link to="/auth/sign-up" className="btn btn-secondary btn-full">
            Register Here
          </Link>
        </div>
      </div>
    </>
  );
};

export default SignIn;
