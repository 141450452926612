import React from 'react';
import { Redirect } from 'react-router-dom';

import { useIsAdmin } from '../../hooks/useUser';

const AdminGuard: React.FC = props => {
  const isAdmin = useIsAdmin();

  if (isAdmin.data) {
    return <>{props.children}</>;
  }

  return <Redirect to="/" />;
};

export default AdminGuard;
