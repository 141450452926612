import React from 'react';
import { PlusIcon } from '@heroicons/react/solid';
import { usePermission } from '../../hooks/usePermission';

type EmptyTaskTemplateProps = {
  onNewTaskTemplate: () => void;
};

const EmptyTaskTemplate: React.FC<EmptyTaskTemplateProps> = props => {
  const permission = usePermission();

  return (
    <div className="text-center mt-12">
      <svg
        enableBackground="new 0 0 489.5 489.5"
        version="1.1"
        viewBox="0 0 489.5 489.5"
        className="mx-auto h-12 w-12"
        fill="#9ca3af"
        aria-hidden="true"
      >
        <rect y="1.35" width="97.5" height="97.5" />
        <rect x="138.3" y="27.45" width="351.2" height="43.1" />
        <rect y="195.95" width="97.5" height="97.5" />
        <rect x="138.3" y="222.05" width="351.2" height="43.1" />
        <rect y="390.65" width="97.5" height="97.5" />
        <rect x="138.3" y="416.65" width="351.2" height="43.1" />
      </svg>
      <h3 className="mt-2 text-sm font-medium text-gray-900">No Templates</h3>
      <p className="mt-1 text-sm text-gray-500">
        Get started by creating a new task template.
      </p>
      {permission.data?.isAdmin || permission.data?.isMember ? (
        <div className="mt-6">
          <button
            type="button"
            className="ml-4 btn btn-primary"
            onClick={props.onNewTaskTemplate}
          >
            <PlusIcon className="leading-icon" aria-hidden="true" />
            New Task Template
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default EmptyTaskTemplate;
