import toast from 'react-hot-toast';
import { useQuery, useInfiniteQuery } from 'react-query';

import { TaskFilter } from '../types/task';
import { taskKeys, taskTemplatesKey } from '../config';
import taskService from '../services/taskService';
import { useUser } from './useUser';

export function useTasks(filter: TaskFilter) {
  const user = useUser();

  return useInfiniteQuery(
    taskKeys.filtered(filter),
    context => {
      return taskService.getTasks(
        user.data?.organisation.id || '',
        filter,
        context,
        user.data?.role || 'VIEWER',
        user.data?.projects || []
      );
    },
    {
      enabled: !user.isLoading && !!user.data,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      keepPreviousData: true,
      select: data => ({
        pages: data.pages.flatMap(x => x.hits),
        pageParams: data.pageParams,
      }),
      getNextPageParam: lastPage => {
        if (lastPage.nbPages > lastPage.page) {
          return lastPage.page + 1;
        }
        return undefined;
      },
      onError: () => {
        toast.error('Try again! Something went wrong.');
      },
    }
  );
}

export function useTaskTemplates() {
  const user = useUser();

  return useQuery(
    taskTemplatesKey.all,
    () => taskService.getTemplates(user.data?.organisation.id || ''),
    {
      enabled: !user.isLoading && !!user.data,
      onError: () => {
        toast.error('Try again! Something went wrong.');
      },
    }
  );
}
