import React, { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { onAuthStateChanged, User } from 'firebase/auth';

import authService from '../../services/authService';
import { auth } from '../../utils/firebase';

import LoadingScreen from '../LoadingScreen';

const Auth: React.FC = ({ children }) => {
  const [isLoading, setLoading] = useState(true);
  const queryClient = useQueryClient();

  const setUser = useCallback(
    async (userId?: string) => {
      try {
        const fullUser = await authService.getUser(userId);
        queryClient.setQueryData('user', fullUser);
      } catch {
        queryClient.setQueryData('user', null);
        authService.logout();
      }
    },
    [queryClient]
  );

  useEffect(() => {
    return onAuthStateChanged(auth, async (user: User | null) => {
      if (user) {
        const token = await user.getIdToken();
        authService.setSession(token);

        await setUser(user.uid);
      } else {
        queryClient.setQueryData('user', null);
      }
      setLoading(false);
    });
  }, [queryClient, setUser]);

  useEffect(() => {
    const initAuth = async () => {
      const token = await authService.getAccessToken();
      if (token) {
        authService.setSession(token);
        await setUser();
      }

      authService.setAxiosInterceptors({
        onLogout: () => authService.logout(),
      });
    };

    initAuth();
  }, [setUser]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
};

export default Auth;
