import React from 'react';

import DashboardMenu from './Menu/DashboardMenu';
import BrandBreadcrumb from './Menu/BrandBreadcrumb';
import BrandTabMenu from './Menu/BrandTabMenu';

const BrandLayout: React.FC = props => {
  return (
    <div className="min-h-full bg-gray-50">
      <DashboardMenu />
      <BrandBreadcrumb />
      <BrandTabMenu />
      <main className="max-w-screen-xl mx-auto pb-10 lg:pb-12 lg:pb-8">
        {props.children}
      </main>
    </div>
  );
};

export default BrandLayout;
