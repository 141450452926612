import React from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { CheckCircleIcon } from '@heroicons/react/solid';
import clsx from 'clsx';

import { useSPSections } from '../../hooks/useSPSections';
import { usePlan } from '../../hooks/usePlans';
import SectionNavigationItem from './SectionNavigationItem';

type SectionNavigationListProps = {
  strategicPlanId?: string;
};

const SectionNavigationList: React.FC<SectionNavigationListProps> = props => {
  const sections = useSPSections();
  const { brandId } = useParams<{ brandId: string }>();
  const location = useLocation();
  const plan = usePlan();

  const overviewSlug = props.strategicPlanId
    ? `${props.strategicPlanId}/section/overview`
    : 'new';

  const isFinished = (sectionId: string) => {
    if (plan.isLoading || plan.isError || !plan.data) return false;

    return plan.data.finishedSections.includes(sectionId);
  };

  return (
    <nav className="space-y-5 ml-5">
      <NavLink
        className="flex items-center text-gray-500 font-medium"
        activeClassName="text-secondary-600"
        to={`/strategic-planning/${brandId}/${overviewSlug}`}
      >
        {props.strategicPlanId &&
        `/strategic-planning/${brandId}/${overviewSlug}` !==
          location.pathname ? (
          <CheckCircleIcon className="w-4 h-4 text-secondary-600 mr-1.5" />
        ) : (
          <svg
            className={clsx(
              'mr-1.5 h-4 w-4 rounded-full text-gray-300 border-4 border-white',
              {
                'border-secondary-200 text-secondary-600':
                  `/strategic-planning/${brandId}/${overviewSlug}` ===
                  location.pathname,
              }
            )}
            fill="currentColor"
            viewBox="0 0 8 8"
          >
            <circle cx={4} cy={4} r={4} />
          </svg>
        )}
        Overview
      </NavLink>
      {sections.data?.map(section => (
        <SectionNavigationItem
          key={section.id}
          section={section}
          type={props.strategicPlanId ? 'edit' : 'new'}
          brandId={brandId}
          strategicPlanId={props.strategicPlanId}
          isFinished={isFinished(section.id)}
        />
      ))}
    </nav>
  );
};

export default SectionNavigationList;
