import React, { FormEvent } from 'react';
import { Field, FieldArrayRenderProps } from 'formik';
import { MinusCircleIcon } from '@heroicons/react/solid';
import Avatar from '../Avatar';
import FormikCombobox from '../FormikCombobox';

import FormikInput from '../FormikInput';
import { TaskDTO } from '../../types/task';
import { User } from '../../types/user';

type AssigneesFieldArrayProps = FieldArrayRenderProps & {
  users: User[];
};

const AssigneesFieldArray: React.FC<AssigneesFieldArrayProps> = props => {
  const formValue = props.form.values.assignees;

  const onAddNewAssignee = (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.push({
      userOrInviteId: '',
      estimateInHours: null,
    });
  };

  const onRemoveAssignee =
    (index: number) => (e: FormEvent<HTMLButtonElement>) => {
      e.preventDefault();
      props.remove(index);
    };

  return (
    <div>
      <div className="space-y-6 mt-6">
        {formValue.map(
          (assignee: NonNullable<TaskDTO['assignees']>[0], index: number) => (
            <div key={`assignee-${index}`} className="space-y-3">
              <Field
                key={`assignee-id-${index}-${JSON.stringify(formValue)}`}
                type="text"
                name={`assignees.${index}.userOrInviteId`}
                component={FormikCombobox}
                label="Assignee"
                placeholder="Start typing to select assignee"
                data={props.users.filter(user => {
                  const isAlreadySelected = formValue.find(
                    (assignee: any) => assignee.userOrInviteId === user.id
                  );

                  return (
                    user.id === assignee.userOrInviteId || !isAlreadySelected
                  );
                })}
                objectPropertySelector="id" // we need to select only the ID as a string
                itemToString={(user: User) => {
                  return user ? `${user.firstName} ${user.lastName}` : '';
                }}
                renderItem={(user: User) => (
                  <div className="flex items-center">
                    <Avatar
                      firstName={user.firstName}
                      lastName={user.lastName}
                      className="flex-shrink-0 h-6 w-6 rounded-full"
                    />
                    <span className="ml-3 block truncate">
                      {`${user.firstName} ${user.lastName}`}
                    </span>
                  </div>
                )}
              />
              <div className="flex space-x-2 items-center">
                <div className="flex-grow">
                  <Field
                    key={`assignee-estimate-${index}`}
                    type="tel"
                    name={`assignees.${index}.estimateInHours`}
                    component={FormikInput}
                    label="Assignee estimate (in hours)"
                    placeholder="hours"
                  />
                </div>
                <button
                  onClick={onRemoveAssignee(index)}
                  className="mt-auto mb-1"
                >
                  <MinusCircleIcon className="w-9 h-9  text-secondary-600 hover:text-secondary-900" />
                </button>
              </div>
            </div>
          )
        )}
      </div>
      <button
        className="btn btn-secondary btn-full mt-6"
        onClick={onAddNewAssignee}
      >
        Add new assignee
      </button>
    </div>
  );
};

export default AssigneesFieldArray;
