import React from 'react';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@heroicons/react/outline/HomeIcon';
import UserGroupIcon from '@heroicons/react/outline/UserGroupIcon';
import CreditCardIcon from '@heroicons/react/outline/CreditCardIcon';

import { useIsOwner } from '../hooks/useUser';

const SettingsLayout: React.FC = props => {
  const owner = useIsOwner();
  const subNavigation = [
    {
      name: 'Organisation',
      href: '/settings/organisation',
      icon: HomeIcon,
      current: false,
    },
    {
      name: 'Team Members',
      href: '/settings/team-members',
      icon: UserGroupIcon,
      current: false,
    },
  ];

  //settings are available only for admins
  if (!owner.isLoading && owner.data) {
    subNavigation.push({
      name: 'Plan & Billing',
      href: '/settings/billing',
      icon: CreditCardIcon,
      current: false,
    });
  }

  return (
    <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
      <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
        <nav className="space-y-1">
          {subNavigation.map(item => (
            <NavLink
              key={item.name}
              to={item.href}
              className="text-gray-600 hover:text-gray-900 hover:bg-gray-100 group rounded-md px-3 py-2 flex items-center text-sm font-medium"
              activeClassName="bg-gray-100 text-gray-900 hover:bg-gray-100 hover:text-gray-900"
            >
              <item.icon
                className="text-gray-600 group-hover:text-gray-600 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                aria-hidden="true"
              />
              <span className="truncate">{item.name}</span>
            </NavLink>
          ))}
        </nav>
      </aside>

      <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
        {props.children}
      </div>
    </div>
  );
};

export default SettingsLayout;
