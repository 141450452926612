import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';

import { strategicPlannerKeys } from '../config';
import strategicPlanService from '../services/strategicPlanService';

export function useSPSections() {
  return useQuery(
    strategicPlannerKeys.sections,
    strategicPlanService.getSections,
    {
      staleTime: 1000 * 60 * 60, // 1 hour
    }
  );
}

export function useNextSection() {
  const sections = useSPSections();
  const { SPId, brandId, sectionId } =
    useParams<{ SPId: string; brandId: string; sectionId: string }>();

  const baseUrl = `/strategic-planning/${brandId}/${SPId}/section`;

  if (sections.isLoading || !sections.data || !sectionId) {
    return false;
  }

  const firstSection = minBy(sections.data, section => section.order);

  // this is the first section, however its not in the db
  // retrieve the first section
  if (sectionId === 'overview') {
    if (!firstSection) {
      return false;
    }

    return `${baseUrl}/${firstSection.id}`;
  }

  //its actually a section from a db
  const actualSection = sections.data.find(section => section.id === sectionId);

  if (!actualSection) return false;

  // select all sections which order's is bigger than the actual one
  const biggerSections = sections.data.filter(
    section => section.order > actualSection.order
  );

  if (biggerSections.length < 1) return false;

  //find the smallest (aka next) section
  const nextSection = minBy(biggerSections, section => section.order);

  if (!nextSection) return;

  return `${baseUrl}/${nextSection.id}`;
}

export function usePreviousSection() {
  const sections = useSPSections();
  const { SPId, brandId, sectionId } =
    useParams<{ SPId: string; brandId: string; sectionId: string }>();

  const baseUrl = `/strategic-planning/${brandId}/${SPId}/section`;

  if (sections.isLoading || !sections.data || !sectionId) {
    return false;
  }

  const firstSection = minBy(sections.data, section => section.order);

  // this is the first section, there is no previous section
  if (sectionId === 'overview') {
    return false;
  }

  //its actually a section from a db
  const actualSection = sections.data.find(section => section.id === sectionId);

  if (!actualSection) return false;

  // it was the first section in the db
  if (firstSection?.order === actualSection.order) {
    return `${baseUrl}/overview`;
  }

  // select all sections which order's is smaller than the actual one
  const smallerSections = sections.data.filter(
    section => section.order < actualSection.order
  );

  if (smallerSections.length < 1) return false;

  //find the smallest (aka next) section
  const nextSection = maxBy(smallerSections, section => section.order);

  if (!nextSection) return;

  return `${baseUrl}/${nextSection.id}`;
}

export function useActualSectionName() {
  const sections = useSPSections();
  const { sectionId } =
    useParams<{ SPId: string; brandId: string; sectionId: string }>();

  if (sections.isLoading || sections.isError || !sections.data) return null;

  const actualSection = sections.data.find(section => section.id === sectionId);

  if (!actualSection) return null;

  return actualSection.name;
}
