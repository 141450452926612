import React from 'react';
import { FieldArray } from 'formik';

import { Project } from '../../types/project';
import { useProjects } from '../../hooks/useProjects';
import { useBrands } from '../../hooks/useBrands';

import SelectProjectToMonth from './SelectProjectToMonth';

type ManageProjectsFormProps = {
  projects: Project[];
};

const ManageProjectsForm: React.FC<ManageProjectsFormProps> = props => {
  const projects = useProjects();
  const brands = useBrands();

  return (
    <div className="px-4 divide-y divide-gray-200 sm:px-6 space-y-6">
      {projects.data && brands.data ? (
        <FieldArray
          name="activeProjects"
          render={props => (
            <SelectProjectToMonth
              {...props}
              brands={brands.data}
              projects={projects.data}
            />
          )}
        />
      ) : null}
    </div>
  );
};

export default ManageProjectsForm;
