import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/solid';

import { Brand } from '../../types/brand';
import PlanItem from './PlanItem';
import { usePlans } from '../../hooks/usePlans';

type PlanListProps = {
  brand: Brand;
};

const PlanList: React.FC<PlanListProps> = props => {
  const { brandId } = useParams<{ brandId: string }>();
  const plans = usePlans();

  const List = () => {
    if (plans.isLoading) {
      const data = new Array(4).fill('');

      return (
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-6 sm:mt-12">
          {data.map((_, index) => (
            <span
              key={index}
              className="block w-full h-60 bg-gray-200 rounded-lg animate-pulse"
            />
          ))}
        </div>
      );
    }

    if (plans.data && plans.data?.length < 1) {
      return (
        <div className="flex flex-col items-center justify-center w-full mt-6 sm:mt-12">
          <svg
            enableBackground="new 0 0 489.5 489.5"
            version="1.1"
            viewBox="0 0 489.5 489.5"
            className="mx-auto h-12 w-12"
            fill="#9ca3af"
            aria-hidden="true"
          >
            <rect y="1.35" width="97.5" height="97.5" />
            <rect x="138.3" y="27.45" width="351.2" height="43.1" />
            <rect y="195.95" width="97.5" height="97.5" />
            <rect x="138.3" y="222.05" width="351.2" height="43.1" />
            <rect y="390.65" width="97.5" height="97.5" />
            <rect x="138.3" y="416.65" width="351.2" height="43.1" />
          </svg>
          <h3 className="mt-2 text-sm font-medium text-gray-900">No Plans</h3>
          <p className="mt-1 text-sm text-gray-500">
            Get started by creating a new plan.
          </p>
        </div>
      );
    }

    if (!plans.data) {
      return <p>error</p>;
    }

    return (
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-6 sm:mt-12">
        {plans.data.map(plan => (
          <PlanItem key={plan.id} plan={plan} />
        ))}
      </div>
    );
  };

  return (
    <div className="flex-grow sm:mt-3.5">
      <div className="flex-grow flex flex-col sm:flex-row sm:items-center justify-between py-5 border-b border-gray-200">
        <p className="text-lg leading-6 font-medium text-gray-900">
          {props.brand.name}
        </p>
        <div>
          <Link
            role="button"
            className="btn btn-primary sm:ml-4"
            to={`/strategic-planning/${brandId}/new`}
          >
            <PlusIcon className="leading-icon" aria-hidden="true" /> Create new
            plan
          </Link>
        </div>
      </div>
      <List />
    </div>
  );
};

export default PlanList;
