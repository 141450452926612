import React from 'react';
import { FastField, Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { InfiniteData, useMutation, useQueryClient } from 'react-query';

import { AlgoliaTask, TaskDTO, TaskError, TaskFilter } from '../../types/task';
import taskService from '../../services/taskService';
import { taskKeys } from '../../config';
import { usePMUsers } from '../../hooks/usePMUsers';
import { useProjects } from '../../hooks/useProjects';
import { useBrands } from '../../hooks/useBrands';

import FormikSelect from '../FormikSelect';
import Drawer from '../Drawer';
import Spinner from '../../Spinner';
import TaskDetailsForm from './TaskDetailsForm';
import { useTaskTemplates } from '../../hooks/useTasks';
import TemplateDetailsForm from './TemplateDetailsForm';
import TemplatePreview from './TemplatePreview';

type AddTaskProps = {
  open: boolean;
  onClose: () => void;
  refetchTasks: () => Promise<void>;
};

const schema = Yup.object().shape({
  name: Yup.string().when('template', {
    is: 'new',
    then: Yup.string().required('Task name is required.'),
  }),
  brand: Yup.object().required('Select a brand.'),
  project: Yup.object().required('Select a project.'),
  activity: Yup.string(),
  deadline: Yup.string(),
  description: Yup.string(),
  assignees: Yup.array().of(
    Yup.object().shape({
      userOrInviteId: Yup.string()
        .typeError('Assignee ID must be a string')
        .required('Select an assignee'),
      estimateInHours: Yup.number()
        .typeError('It is not a valid number.')
        .min(0, 'Estimation must be greater than 0.')
        .nullable(),
    })
  ),
  template: Yup.string(),
});

const initialValues: TaskDTO = {
  name: '',
  brand: '',
  project: '',
  activity: '',
  deadline: '',
  description: '',
  assignees: [
    {
      userOrInviteId: '',
      estimateInHours: null,
    },
  ],
  template: 'new',
};

const AddTaskDrawer: React.FC<AddTaskProps> = props => {
  const users = usePMUsers();
  const projects = useProjects();
  const brands = useBrands();
  const templates = useTaskTemplates();
  const queryClient = useQueryClient();

  const createTask = useMutation(taskService.createTask, {
    onSuccess: async data => {
      props.onClose();
      await queryClient.cancelQueries(taskKeys.all);

      const allTaskQuery = queryClient.getQueriesData<
        InfiniteData<AlgoliaTask>
      >(taskKeys.all);

      // newly created tasks going to be 'to-do'
      const onlyTodoQueries = allTaskQuery.filter(query => {
        const queryKey = query[0] as [string, TaskFilter];

        if (!queryKey[1]) return false;
        return queryKey[1].status === 'to-do' || queryKey[1].status === 'all';
      });

      onlyTodoQueries.forEach(query => {
        queryClient.setQueryData(query[0], () => {
          //map the data to the algolia's result
          const addToFirstPageHits = [data, ...query[1].pages[0].hits];
          const addToFirstPage = [
            { ...query[1].pages[0], hits: addToFirstPageHits },
          ];

          return {
            ...query[1],
            pages: [...addToFirstPage, ...query[1].pages.slice(1)],
          };
        });
      });
      props.refetchTasks();
    },
  });

  const handleOnSubmit = async (
    values: TaskDTO,
    formikBag: FormikHelpers<TaskDTO>
  ): Promise<void> => {
    try {
      await createTask.mutateAsync(values);
    } catch (e) {
      const error = e as TaskError;
      switch (error.code) {
        case 'general/resource-not-found': {
          if (values.brand) {
            formikBag.setFieldError(
              'project',
              `Choose a project which belongs to the "${values.brand.name}" brand`
            );
          }
          break;
        }
      }
      toast.error('Try again! Something went wrong.');
    }
  };

  const templateList = templates.data
    ? templates.data.map(template => ({
        name: template.name,
        value: template.id,
      }))
    : [];

  return (
    <Drawer open={props.open} onClose={props.onClose}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleOnSubmit}
      >
        {({ isSubmitting, dirty, values }: FormikProps<TaskDTO>) => (
          <Drawer.FormContainer
            open={props.open}
            onClose={props.onClose}
            title="New Task"
            description="Create new tasks either from a template for individually."
            Footer={
              <>
                <button
                  className="btn btn-white"
                  onClick={props.onClose}
                  disabled={isSubmitting}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary ml-4"
                  type="submit"
                  disabled={isSubmitting || !dirty}
                >
                  {isSubmitting ? (
                    <>
                      <Spinner
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      Loading
                    </>
                  ) : (
                    'Create'
                  )}
                </button>
              </>
            }
          >
            <div className="sm:px-6 px-4">
              <div className="border-b border-gray-200 pt-6 pb-6">
                <label className="text-base leading-6 font-semibold pb-6 block">
                  Overview
                </label>
                <FastField
                  name="template"
                  component={FormikSelect}
                  label="New or From Template"
                  options={[{ name: 'New', value: 'new' }, ...templateList]}
                />
              </div>
              {values.template === 'new' ? (
                <TaskDetailsForm
                  isSubmitting={isSubmitting}
                  users={users.data || []}
                  brands={brands.data || []}
                  projects={
                    projects?.data?.filter(project => {
                      // if no brand selected yet, display all
                      if (!values.brand) {
                        return true;
                      }

                      return project.brandId === values.brand.id;
                    }) || []
                  }
                />
              ) : (
                <>
                  <TemplateDetailsForm
                    isSubmitting={isSubmitting}
                    brands={brands.data || []}
                    projects={
                      projects?.data?.filter(project => {
                        // if no brand selected yet, display all
                        if (!values.brand) {
                          return true;
                        }

                        return project.brandId === values.brand.id;
                      }) || []
                    }
                  />
                  <TemplatePreview
                    template={templates?.data?.find(
                      template => template.id === values.template
                    )}
                  />
                </>
              )}
            </div>
          </Drawer.FormContainer>
        )}
      </Formik>
    </Drawer>
  );
};

export default AddTaskDrawer;
