import React, { ReactElement } from 'react';
import { ChevronLeftIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';

import { AllOrNothing } from '../../types/type-helper';
import logo from '../../assets/logo-primary.svg';
import { APP_TITLE } from '../../config';

type AuthLayoutHeaderProps = {
  title?: string;
  subTitle?: ReactElement;
} & AllOrNothing<{ backButtonText: string; backButtonLink: string }>;

const AuthLayoutHeader: React.FC<AuthLayoutHeaderProps> = props => {
  return (
    <div>
      {props.backButtonLink && props.backButtonText ? (
        <Link
          to={props.backButtonLink}
          className="flex items-center text-gray-500 hover:!text-gray-700 text-sm mb-6"
        >
          <ChevronLeftIcon aria-hidden="true" className="w-4 h-4" />
          {props.backButtonText}
        </Link>
      ) : null}
      <Link to="/" title={APP_TITLE}>
        <img className="h-12 w-auto" src={logo} alt={APP_TITLE} />
      </Link>
      {props.title ? (
        <h2 className="mt-4 text-3xl font-extrabold text-gray-900">
          {props.title}
        </h2>
      ) : null}
      {props.subTitle}
    </div>
  );
};

export default AuthLayoutHeader;
