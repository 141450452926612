import * as Yup from 'yup';
import React from 'react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-hot-toast';

import { BrandDTO, BrandError } from '../../types/brand';
import { usePMUsers } from '../../hooks/usePMUsers';
import brandService from '../../services/brandService';
import { brandKeys } from '../../config';

import Drawer from '../Drawer';
import Spinner from '../../Spinner';
import BrandForm from './BrandForm';

type NewBrandDrawerProps = {
  onClose: () => void;
  open: boolean;
};

const schema = Yup.object().shape({
  name: Yup.string().required('First name is required'),
  projectManager: Yup.object(),
  contractEnd: Yup.date()
    .min(new Date(), 'Select date in the future')
    .max(new Date('9999-12-30'), 'Must be a valid date.'),
  keyContact: Yup.object().shape({
    fullName: Yup.string(),
    email: Yup.string().email('Must be a valid email'),
    phoneNumber: Yup.string(),
  }),
  billingContact: Yup.object().shape({
    fullName: Yup.string(),
    email: Yup.string().email('Must be a valid email'),
    phoneNumber: Yup.string(),
  }),
  billingInstructions: Yup.string(),
});

const NewBrandDrawer: React.FC<NewBrandDrawerProps> = props => {
  const queryClient = useQueryClient();
  const users = usePMUsers();
  const createBrand = useMutation(brandService.createBrand, {
    onSuccess: async () => {
      await queryClient.refetchQueries(brandKeys.all);
    },
    onError: () => {
      toast.error('Something went wrong. Try again!');
    },
  });

  const initialValues: BrandDTO = {
    name: '',
    projectManager: '',
    contractEnd: '',
    keyContact: {
      fullName: '',
      email: '',
      phoneNumber: '',
    },
    billingContact: {
      fullName: '',
      email: '',
      phoneNumber: '',
    },
    billingInstructions: '',
  };

  const handleOnSubmit = async (
    values: BrandDTO,
    formikBag: FormikHelpers<BrandDTO>
  ) => {
    try {
      await createBrand.mutateAsync(values);
      props.onClose();
    } catch (e) {
      const error = e as BrandError;

      switch (error.code) {
        case 'brands/brand-name-already-in-use':
          formikBag.setFieldError('name', error.detail);
          break;
      }
    }
  };

  return (
    <Drawer open={props.open} onClose={props.onClose}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleOnSubmit}
      >
        {({ isSubmitting, dirty }: FormikProps<BrandDTO>) => (
          <Drawer.FormContainer
            open={props.open}
            onClose={props.onClose}
            title="New Brand"
            description="Get started by filling in the information below to create your new Brand."
            Footer={
              <>
                <button
                  className="btn btn-white"
                  onClick={props.onClose}
                  disabled={isSubmitting}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary ml-4"
                  type="submit"
                  disabled={isSubmitting || !dirty}
                >
                  {isSubmitting ? (
                    <>
                      <Spinner
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      Loading
                    </>
                  ) : (
                    'Create'
                  )}
                </button>
              </>
            }
          >
            <BrandForm isSubmitting={isSubmitting} users={users.data || []} />
          </Drawer.FormContainer>
        )}
      </Formik>
    </Drawer>
  );
};

export default NewBrandDrawer;
