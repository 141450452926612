import React from 'react';

const Trial: React.FC = () => {
  return (
    <div className="border-gray-300 border rounded-xl p-6 mb-6">
      <p className="font-medium text-gray-900 flex mb-2">
        Trial Ends
        <span className="text-secondary-500 ml-auto">01/12/21</span>
      </p>
      <p className="text-sm text-gray-500">
        You won’t be charged until the completion of your{' '}
        <span className="text-secondary-500">14-day free trial.</span>
        You’re free to cancel at any time.
      </p>
    </div>
  );
};

export default Trial;
