import React from 'react';
import { Column } from 'react-table';

import { Brand } from '../../types/brand';
import Table from '../Table';

const BrandsTableSkeleton: React.FC = () => {
  const columns = React.useMemo<Column<Brand>[]>(
    () => [
      {
        Header: 'Brand',
        disableSortBy: true,
        accessor: () => (
          <span className="block w-36 h-3  bg-gray-200 rounded animate-pulse" />
        ),
      },
      {
        Header: 'PM',
        disableSortBy: true,
        accessor: () => (
          <span className="block w-36 h-3  bg-gray-200 rounded animate-pulse" />
        ),
      },
      {
        Header: 'Projects',
        disableSortBy: true,
        accessor: () => (
          <span className="block w-20 h-3  bg-gray-200 rounded animate-pulse" />
        ),
      },
      {
        Header: 'Contract end',
        disableSortBy: true,
        accessor: () => (
          <span className="block w-28 h-3  bg-gray-200 rounded animate-pulse" />
        ),
      },
      {
        id: 'edit',
        disableSortBy: true,
        accessor: () => (
          <span className="block w-16 h-3  bg-gray-200 rounded animate-pulse" />
        ),
      },
    ],
    []
  );

  const data = new Array(6).fill('');

  return <Table columns={columns} data={data} />;
};

export default BrandsTableSkeleton;
