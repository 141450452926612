import React from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';

import { useBrand, useBrands } from '../hooks/useBrands';

import BrandMenu from '../components/StrategicPlanner/BrandMenu';
import PlanList from '../components/StrategicPlanner/PlanList';
import Spinner from '../Spinner';
import ErrorPage from '../components/ErrorPage';

const StrategicPlanner: React.FC = () => {
  const { brandId } = useParams<{ brandId: string }>();
  const brands = useBrands();
  const selectedBrand = useBrand();

  if (brands.isLoading) return null;
  if (brands.isError || !brands.data)
    return (
      <ErrorPage
        title="Something went wrong!"
        text="We keep track of these errors, but feel free to contact us if refreshing doesn't fix things."
        subtitle="500 Error"
      />
    );

  if (!brandId) {
    if (!brands.data[0]) {
      return <Redirect to={`/brands`} />;
    }

    return <Redirect to={`/strategic-planning/${brands.data[0].id}`} />;
  }

  const PlanComponent = () => {
    if (selectedBrand.isLoading) {
      return <Spinner className="w-6 h-6" />;
    }

    if (selectedBrand.isError || !selectedBrand.data) {
      return (
        <ErrorPage
          title="Something went wrong!"
          text="We keep track of these errors, but feel free to contact us if refreshing doesn't fix things."
          subtitle="500 Error"
        />
      );
    }

    return <PlanList brand={selectedBrand.data} />;
  };

  return (
    <div className="flex flex-col md:flex-row flex-1">
      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:w-64 xl:w-80 md:flex-col md:flex">
        <div className="flex flex-col flex-grow pt-6 px-4 bg-white overflow-y-auto">
          <div className="mt-5 flex-1 flex flex-col">
            <p className="text-gray-900 text-sm leading-5 font-medium mb-6">
              Brands
            </p>
            <BrandMenu brands={brands.data} />
          </div>
        </div>
      </div>

      {/* Mobile dropdown navigation*/}
      <Disclosure
        as="div"
        className="bg-white md:hidden m-4 rounded-md flex flex-col"
      >
        {({ open }) => (
          <>
            <Disclosure.Button className="p-2 flex flex-1 rounded-md items-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-900">
              {open ? (
                <ChevronUpIcon className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <ChevronDownIcon className="block h-6 w-6" aria-hidden="true" />
              )}
              <span className="ml-2 text-gray-900 leading-5 font-medium">
                Brands
              </span>
            </Disclosure.Button>
            <Disclosure.Panel as="div" className="mt-4" aria-label="Global">
              <BrandMenu brands={brands.data} />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <main className="px-4 lg:px-8 flex-grow">
        <PlanComponent />
      </main>
    </div>
  );
};

export default StrategicPlanner;
