import React, { useEffect } from 'react';
import { Column, useSortBy, useTable, SortingRule } from 'react-table';
import isFunction from 'lodash/isFunction';

type TableProps<Data extends {}> = {
  columns: Column<Data>[];
  data: Data[];
  onSortBy?: (sortBy: SortingRule<Data>[]) => void;
};

const Table = <TableData extends {}>(props: TableProps<TableData>) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    footerGroups,
    state,
  } = useTable<TableData>(
    {
      columns: props.columns,
      data: props.data,
      manualSortBy: !!isFunction(props.onSortBy),
      autoResetSortBy: false,
      autoResetHiddenColumns: false,
    },
    useSortBy
  );

  const { onSortBy } = props;
  const { sortBy } = state;
  useEffect(() => {
    if (isFunction(onSortBy)) {
      onSortBy(sortBy);
    }
  }, [sortBy, onSortBy]);

  return (
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="overflow-hidden border border-gray-200 rounded-lg">
          <table
            className="min-w-full divide-y divide-gray-200"
            {...getTableProps()}
          >
            <thead className="bg-secondary-50">
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th
                      className="table-header select-none"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <span className="flex items-center">
                        {column.render('Header')}
                        <span className="text-secondary-700 w-2 inline-block">
                          {column.isSorted
                            ? column.isSortedDesc
                              ? '▲'
                              : '▼'
                            : ' '}
                        </span>
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              className="bg-white divide-y divide-gray-200"
              {...getTableBodyProps()}
            >
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className="even:bg-secondary-25">
                    {row.cells.map(cell => {
                      return (
                        <td
                          className="px-4 py-6 whitespace-nowrap text-sm text-gray-500 truncate"
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
            {footerGroups[0].headers.some(column => {
              return column?.enableFooter;
            }) ? (
              <tfoot>
                {footerGroups.map(group => (
                  <tr {...group.getFooterGroupProps()}>
                    {group.headers.map(column => (
                      <td
                        className="px-4 py-4 whitespace-nowrap text-sm text-gray-500 truncate"
                        {...column.getFooterProps()}
                      >
                        {column.render('Footer')}
                      </td>
                    ))}
                  </tr>
                ))}
              </tfoot>
            ) : null}
          </table>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Table) as typeof Table;
