import React, { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import { SearchIcon } from '@heroicons/react/solid';

import { useProjects } from '../../hooks/useProjects';
import { usePMUsers } from '../../hooks/usePMUsers';
import { useBrands } from '../../hooks/useBrands';
import { TaskFilter, TaskStatus } from '../../types/task';
import { defaultTaskFilter } from '../../config';

import FilterSelect from './FilterSelect';
import FilterBadge from './FilterBadge';
import Avatar from '../Avatar';
import useDebounce from '../../hooks/useDebouce';
import { isBefore, isValid } from 'date-fns';

type FiltersProps = {
  filter: TaskFilter;
  onChange: React.Dispatch<React.SetStateAction<TaskFilter>>;
};

const Filters: React.FC<FiltersProps> = ({ onChange, filter }) => {
  const { status } = useParams<{ status: TaskStatus }>();

  const brands = useBrands();
  const projects = useProjects();
  const members = usePMUsers();

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    onChange(prevState => ({
      ...prevState,
      text: debouncedSearchTerm,
    }));
  }, [debouncedSearchTerm, onChange]);

  const handleSearchTermChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleDeadlineChange =
    (fieldName: keyof Pick<TaskFilter, 'deadlineFrom' | 'deadlineTo'>) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(prevState => {
        return {
          ...prevState,
          [fieldName]: e.target.value ?? '',
        };
      });
    };

  const handleRemoveFilter =
    (type: 'brands' | 'projects' | 'assignees', id: string) => () => {
      onChange(prevState => ({
        ...prevState,
        [type]: filter[type].filter(item => item.id !== id),
      }));
    };

  const handleFilterOnChange =
    (type: 'brands' | 'projects' | 'assignees') =>
    (value: { id: string; label: string }) => {
      onChange(prevState => ({
        ...prevState,
        [type]: [...filter[type], value],
      }));
    };

  const getSelectLabel = (type: 'brands' | 'projects' | 'assignees') => {
    if (filter[type].length > 0) {
      return filter[type].map(item => item.label).join(', ');
    }

    switch (type) {
      case 'brands':
        return 'Select a brand';
      case 'projects':
        return 'Select a project';
      case 'assignees':
        return 'Select an assignee';
    }
  };

  const brandsData = brands.data
    ?.map(brand => ({ id: brand.id, label: brand.name }))
    .filter(brand => {
      const isNotSelectedBrand = !filter.brands.some(b => b.id === brand.id);
      return isNotSelectedBrand;
    });

  const projectsData = projects.data
    ?.map(project => ({
      id: project.id,
      label: project.name,
      brandId: project.brandId,
    }))
    .filter(project => {
      const isNotSelectedProject = !filter.projects.some(
        p => p.id === project.id
      );
      // if 0 brands are selected, always pass this test
      const isWithinSelectedBrand =
        filter.brands.length < 1 ||
        filter.brands.some(brand => brand.id === project.brandId);

      return isNotSelectedProject && isWithinSelectedBrand;
    });

  const membersData = members.data
    ?.map(member => ({
      id: member.id,
      label: `${member.firstName} ${member.lastName}`,
    }))
    .filter(member => {
      const isNotSelectedAssignee = !filter.assignees.some(
        a => a.id === member.id
      );
      return isNotSelectedAssignee;
    });

  const handleFilterClear = () => {
    onChange({
      ...defaultTaskFilter,
      status: status,
    });
  };

  const deadlineFromDate = new Date(filter.deadlineFrom);
  const deadlineToDate = new Date(filter.deadlineTo);

  const areDeadlineFiltersConflicting =
    isValid(deadlineFromDate) &&
    isValid(deadlineToDate) &&
    !isBefore(deadlineFromDate, deadlineToDate);

  return (
    <div className="shadow lg:rounded-md p-4 sm:p-6 bg-white mb-6">
      <div className="flex justify-between mb-7">
        <p className="text-lg text-gray-900 font-medium">Filters</p>
        <button onClick={handleFilterClear} className="text-sm text-gray-500">
          Clear All Filters
        </button>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-6 gap-4">
        <div>
          <label
            htmlFor="search"
            className="block text-sm font-medium text-gray-700"
          >
            Filter by Task or Activity
          </label>
          <div className="input mt-1">
            <div className="inset-y-0 pl-3 flex items-center pointer-events-none">
              <SearchIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              id="search"
              type="search"
              value={searchTerm}
              onChange={handleSearchTermChange}
              className="bg-white sm:text-sm border-0 focus:outline-none block w-full px-3 py-2 appearance-none rounded-md placeholder-gray-400"
              placeholder="Type Task or Activity Name"
            />
          </div>
        </div>
        <FilterSelect
          label="Brand"
          selectPlaceholder={getSelectLabel('brands')}
          data={brandsData}
          onChange={handleFilterOnChange('brands')}
          mapItem={item => <span className="block truncate">{item.label}</span>}
        />

        <FilterSelect
          label="Project"
          selectPlaceholder={getSelectLabel('projects')}
          data={projectsData}
          onChange={handleFilterOnChange('projects')}
          mapItem={item => {
            const project = projects.data?.find(
              project => project.id === item.id
            );
            const brand = brands.data?.find(
              brand => brand.id === project?.brandId
            );
            if (brand) {
              return (
                <span className="block truncate">
                  {item.label} -{' '}
                  <span className="text-gray-500">{brand.name}</span>
                </span>
              );
            }

            return <span className="block truncate">{item.label}</span>;
          }}
        />

        <div>
          <label
            htmlFor="deadlineFrom"
            className="block text-sm font-medium text-gray-700"
          >
            Deadline from
          </label>
          <div className="input mt-1">
            <input
              id="deadlineFrom"
              type="date"
              className="bg-white sm:text-sm border-0 focus:outline-none block w-full px-3 py-2 appearance-none rounded-md placeholder-gray-400"
              placeholder="dd/mm/yy"
              max="9999-12-30"
              style={{ height: 36 }}
              onChange={handleDeadlineChange('deadlineFrom')}
              value={filter.deadlineFrom}
            />
          </div>
          {areDeadlineFiltersConflicting ? (
            <p className="mt-1 text-xs text-red-900">
              The 'from' date needs to be earlier than the 'to' date
            </p>
          ) : null}
        </div>

        <div>
          <label
            htmlFor="deadlineTo"
            className="block text-sm font-medium text-gray-700"
          >
            Deadline to
          </label>
          <div className="input mt-1">
            <input
              id="deadlineTo"
              type="date"
              className="bg-white sm:text-sm border-0 focus:outline-none block w-full px-3 py-2 appearance-none rounded-md placeholder-gray-400"
              placeholder="dd/mm/yy"
              max="9999-12-30"
              style={{ height: 36 }}
              onChange={handleDeadlineChange('deadlineTo')}
              value={filter.deadlineTo}
            />
          </div>
        </div>

        <FilterSelect
          label="Assignee"
          selectPlaceholder={getSelectLabel('assignees')}
          data={membersData}
          onChange={handleFilterOnChange('assignees')}
          mapItem={item => {
            const name = item.label.split(' ');
            return (
              <div className="flex items-center">
                <Avatar
                  className="w-5 h-5 mr-3 text-xs"
                  firstName={name[0]}
                  lastName={name[1]}
                />
                {name[0]} {name[1]}
              </div>
            );
          }}
        />
      </div>

      <div
        className={clsx({
          'mt-8':
            filter.assignees.length > 0 ||
            filter.projects.length > 0 ||
            filter.brands.length > 0,
        })}
      >
        {filter.brands.map(brand => {
          return (
            <FilterBadge
              key={brand.id}
              label={brand.label}
              onRemove={handleRemoveFilter('brands', brand.id)}
            />
          );
        })}
        {filter.projects.map(project => {
          return (
            <FilterBadge
              key={project.id}
              label={project.label}
              onRemove={handleRemoveFilter('projects', project.id)}
            />
          );
        })}
        {filter.assignees.map(assignee => {
          return (
            <FilterBadge
              key={assignee.id}
              label={assignee.label}
              onRemove={handleRemoveFilter('assignees', assignee.id)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(Filters);
