import React from 'react';
import { FastField } from 'formik';
import ReactQuill from 'react-quill';

import { Question } from '../../../types/strategic-plan';
import QuestionContainer from '../QuestionContainer';

type WYSIWYGProps = {
  index: number;
  question: Question;
};

const WYSIWYG: React.FC<WYSIWYGProps> = props => {
  return (
    <QuestionContainer
      name={props.question.name}
      description={props.question.description}
    >
      <FastField name={`questions[${props.index}].formData.text`} type="text">
        {
          // @ts-ignore
          ({ field }) => (
            <ReactQuill
              placeholder="Enter your answer here..."
              value={field.value}
              onChange={field.onChange(field.name)}
            />
          )
        }
      </FastField>
    </QuestionContainer>
  );
};

export default WYSIWYG;
