import React, { Fragment, useState, FC } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon, ChevronDownIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';

import SectionNavigationList from './SectionNavigationList';

type MobileSectionNavigationProps = {
  actualSection: string | null;
};
const MobileSectionNavigation: FC<MobileSectionNavigationProps> = props => {
  const [open, setOpen] = useState(false);
  const { SPId } = useParams<{ SPId: string }>();

  return (
    <div>
      <button
        onClick={() => setOpen(true)}
        className="flex items-center text-sm leading-5 font-normal text-gray-900"
      >
        {props.actualSection ? props.actualSection : 'Overview'}
        <ChevronDownIcon className="w-4 h-4 ml-2" />
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-hidden z-50"
          onClose={setOpen}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md">
                  <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          Sections
                        </Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close navigation</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mt-6 relative flex-1 px-4 sm:px-6">
                      <SectionNavigationList strategicPlanId={SPId} />
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default MobileSectionNavigation;
