import React from 'react';
import { useMutation } from 'react-query';
import toast from 'react-hot-toast';

import organisationService from '../../services/organisationService';
import SuccessMessage from '../SuccessMessage';
import Spinner from '../../Spinner';
import { useIsOwner } from '../../hooks/useUser';

const RequestData: React.FC = () => {
  const isOwner = useIsOwner();
  const requestOrg = useMutation(organisationService.requestOrganisationData, {
    onError: () => {
      toast.error('Something went wrong. Try again!');
    },
  });

  // only owners can to this
  if (!isOwner.data) {
    return null;
  }

  return (
    <div className="pt-12 mt-12 border-t border-gray-200">
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        Request a copy of your data
      </h3>
      <p className="text-sm text-gray-500 leading-5 font-normal">
        Per GDPR, you are able to request a copy of your data. This request will
        be processed in the background, and you will receive a copy of your data
        via email once it is ready.
      </p>
      {requestOrg.status === 'success' ? (
        <SuccessMessage
          title="Requested data sent!"
          text={<p>Check your email to download your data.</p>}
          className="my-2"
        />
      ) : (
        <button
          className="text-link my-2"
          onClick={() => requestOrg.mutate()}
          disabled={requestOrg.isLoading}
        >
          <span className="pr-3">Request a copy of your data</span>
          {requestOrg.isLoading ? (
            <Spinner className="h-4 w-4 ml-4 trailing-icon inline-block" />
          ) : (
            '→'
          )}
        </button>
      )}
    </div>
  );
};

export default RequestData;
