import React from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { Toaster } from 'react-hot-toast';
import { ReactQueryDevtools } from 'react-query/devtools';

import { APP_TITLE } from './config';
import { queryClient } from './utils/react-query';

import Routes from './Routes';
import ErrorBoundary from './components/ErrorBoundary';
import Auth from './components/auth/Auth';
import MaintenanceGuard from './components/auth/MaintenanceGuard';

const App: React.FC = () => {
  return (
    <ErrorBoundary>
      <HelmetProvider>
        <Helmet titleTemplate={`%s | ${APP_TITLE}`} defaultTitle={APP_TITLE} />
        <Toaster position="bottom-left" />

        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />

            <MaintenanceGuard>
              <Auth>
                <Routes />
              </Auth>
            </MaintenanceGuard>
          </QueryClientProvider>
        </BrowserRouter>
      </HelmetProvider>
    </ErrorBoundary>
  );
};

export default App;
