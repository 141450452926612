import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { ArrowRightIcon } from '@heroicons/react/solid';
import { useHistory, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import strategicPlanService from '../../../services/strategicPlanService';
import { strategicPlannerKeys } from '../../../config';
import { PlanDTO, SPDTO, StrategicPlan } from '../../../types/strategic-plan';
import { usePlan } from '../../../hooks/usePlans';
import { useNextSection } from '../../../hooks/useSPSections';

import OverviewForm from '../OverviewForm';

const schema = Yup.object().shape({
  brand: Yup.object().required('Brand is required'),
  project: Yup.object(),
  name: Yup.string().required('Name is required'),
});

const EditOverview: React.FC = () => {
  const plan = usePlan();
  const { SPId } = useParams<{ SPId: string; brandId: string }>();
  const queryClient = useQueryClient();
  const history = useHistory();
  const nextSection = useNextSection();

  const updateSection = useMutation<StrategicPlan, any, PlanDTO>(
    data => strategicPlanService.savePlan(SPId, data),
    {
      onSuccess: data => {
        queryClient.setQueryData(strategicPlannerKeys.plan(SPId), data);
        if (nextSection) {
          history.push(nextSection);
        }
      },
      onError: () => {
        toast.error('Something went wrong! Try again!');
      },
    }
  );

  if (plan.isLoading) {
    return (
      <div className="bg-gray-100 rounded-lg w-full h-64 animate-pulse animate mt-6" />
    );
  }

  if (!plan.data) {
    return <p>error</p>;
  }

  const initialValues: SPDTO = {
    brand: plan.data.brand || '',
    project: plan.data.project || '',
    name: plan.data.name || '',
  };

  const handleOnSubmit = async (values: SPDTO) => {
    try {
      await updateSection.mutateAsync({
        name: values.name || '',
        project: values.project ? values.project.id : '',
        brand: values.brand ? values.brand.id : '',
      });
    } catch (e) {
      //
    }
  };

  return (
    <div>
      <p className="text-gray-700 text-lg leading-6 font-normal py-6 px-4 sm:px-0">
        Project overview
      </p>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleOnSubmit}
      >
        {({ isSubmitting, values }: FormikProps<SPDTO>) => (
          <Form className="flex flex-col">
            <OverviewForm values={values} isSubmitting={isSubmitting} />
            <button
              className="btn btn-primary ml-auto mt-6 mr-4 sm:mr-0"
              type="submit"
              disabled={isSubmitting}
            >
              Save & Continue
              <ArrowRightIcon className="ml-2 w-4 h-4" />
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditOverview;
