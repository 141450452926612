import { useMutation, useQueryClient } from 'react-query';
import toast from 'react-hot-toast';

import resourcePlannerService from '../services/resourcePlannerService';
import { resourcePlannerKeys } from '../config';

export function useUpdateResourcePlanner() {
  const queryClient = useQueryClient();

  return useMutation(resourcePlannerService.updateResourcePlanner, {
    onSuccess: rp => {
      queryClient.setQueryData(
        resourcePlannerKeys.month(
          `${rp.resourceMonth.month}-${rp.resourceMonth.year}`
        ),
        rp
      );
      toast.success('The resource planner has been successfully updated.');
    },
    onError: () => {
      toast.success('Error! Try again later or refresh the page!');
    },
  });
}
