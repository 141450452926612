import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/outline';

import { PromotionCode } from '../../types/checkout';
import checkoutService from '../../services/checkoutService';
import Spinner from '../../Spinner';

type CouponProps = {
  onChange: (fieldName: string, value?: PromotionCode | null) => void;
};

const Coupon: React.FC<CouponProps> = props => {
  const [couponCode, setCouponCode] = useState<string>('');
  const checkCoupon = useMutation(checkoutService.checkCoupon, {
    onError: () => {
      props.onChange('promotionCode', null);
    },
    onSuccess: data => {
      props.onChange('promotionCode', data);
    },
  });

  const handleApply = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (couponCode.length < 1) {
      props.onChange('promotionCode', null);
      checkCoupon.reset();
      return;
    }

    await toast.promise(
      checkCoupon.mutateAsync(couponCode),
      {
        loading: 'Checking the coupon code...',
        success: (
          <span>
            Coupon code is <b>added!</b>
          </span>
        ),
        error: (
          <span>
            Coupon code is <b>invalid!</b>
          </span>
        ),
      },
      {
        position: 'top-center',
      }
    );
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCouponCode(e.target.value);
  };

  return (
    <form
      className="p-6 border-secondary-400 border rounded-xl mb-12"
      onSubmit={handleApply}
    >
      <h4 className="text-lg font-medium text-white">Have a code?</h4>
      <p className="text-sm text-secondary-100 mb-3">
        Do you have a discount code to use? Please enter it below.
      </p>
      <label className="block text-sm font-medium text-secondary-100">
        Discount Code
      </label>
      <div className="flex space-x-3 items-end">
        <div className="w-full">
          <div className="input mt-1 relative bg-white">
            <input
              type="text"
              onChange={handleInputChange}
              value={couponCode}
              disabled={checkCoupon.isLoading}
              className="sm:text-sm border-0 focus:outline-none block w-full px-3 py-2 appearance-none rounded-md placeholder-gray-400 text-gray-900"
            />
            <div className="inset-y-0 pr-3 flex items-center pointer-events-none">
              {checkCoupon.isError ? (
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              ) : null}

              {checkCoupon.data ? (
                <CheckCircleIcon
                  className="h-5 w-5 text-green-500"
                  aria-hidden="true"
                />
              ) : null}

              {checkCoupon.isLoading ? (
                <Spinner
                  className="h-5 w-5 text-secondary-400 -mr-0"
                  aria-hidden="true"
                />
              ) : null}
            </div>
          </div>
        </div>
        <div>
          <button
            type="submit"
            className="btn btn-secondary"
            disabled={checkCoupon.isLoading}
          >
            Apply
          </button>
        </div>
      </div>
    </form>
  );
};

export default Coupon;
