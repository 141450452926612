import React, { useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import {
  FastField,
  Field,
  Form,
  Formik,
  FormikProps,
  FormikHelpers,
} from 'formik';
import { useMutation } from 'react-query';
import { ExclamationIcon } from '@heroicons/react/outline';
import toast from 'react-hot-toast';
import { Link, useHistory } from 'react-router-dom';

import authService from '../../services/authService';
import { SignIn, SignInError } from '../../types/sign-in';
import { User } from '../../types/user';

import FormikInput from '../FormikInput';
import FormikPasswordInput from '../FormikPasswordInput';
import Spinner from '../../Spinner';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
});

const initialValues: SignIn = {
  email: '',
  password: '',
};

const SignInForm: React.FC = () => {
  const [isBlocked, setBlocked] = useState<boolean>(false);
  const history = useHistory();

  const signIn = useMutation<User, SignInError, SignIn>(
    authService.loginWithEmailAndPassword,
    {
      onMutate: () => {
        setBlocked(false);
      },
      onError: error => {
        if (error.code === 'forbidden/email-not-verified') return; // don't show notification just redirect
        toast.error('Error occurred during the login process.');
      },
    }
  );

  const handleOnSubmit = async (
    values: SignIn,
    formikBag: FormikHelpers<SignIn>
  ): Promise<void> => {
    try {
      await signIn.mutateAsync(values);
    } catch (e) {
      const error = e as SignInError;
      switch (error.code) {
        case 'auth/wrong-password':
          formikBag.setFieldError('password', 'Password is not correct');
          break;
        case 'auth/user-not-found':
        case 'permission-denied':
          formikBag.setFieldError('password', ' ');
          formikBag.setFieldError('email', ' ');
          break;
        case 'i-user/user-is-blocked':
          setBlocked(true);
          break;
        case 'auth/too-many-requests':
          formikBag.setFieldError(
            'email',
            'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later'
          );
          break;
        case 'forbidden/email-not-verified': {
          history.push('/auth/verify-email', { email: values.email });
        }
      }
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleOnSubmit}
    >
      {({ isSubmitting }: FormikProps<SignIn>) => (
        <Form className="space-y-6" noValidate>
          {isBlocked ? (
            <div className="rounded-md bg-yellow-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationIcon
                    className="h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-yellow-800">
                    Organisation Inactive
                  </h3>
                  <div className="mt-2 text-sm text-yellow-700">
                    <p>
                      Please note that the billing for this organisation has
                      failed or expired. Contact the organisation admin to
                      request billing be updated.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <fieldset disabled={isSubmitting}>
            <div className="space-y-3">
              <FastField
                type="email"
                name="email"
                component={FormikInput}
                label="Email"
                autoComplete="username"
                autoFocus
              />
              <Field
                component={FormikPasswordInput}
                label="Password"
                name="password"
                autoComplete="current-password"
              />
            </div>
          </fieldset>
          <div className="flex text-sm">
            <Link
              to="/auth/forgot-password"
              className="ml-auto font-medium text-link"
            >
              Forgot your password?
            </Link>
          </div>

          <div>
            <button
              className={clsx({
                'btn btn-primary btn-full': true,
                'animate__animated animate__shakeX': isBlocked,
              })}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Spinner className="h-4 w-4 trailing-icon" />
              ) : (
                'Sign in'
              )}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SignInForm;
