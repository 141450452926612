import * as React from 'react';
import { Switch, Route, RouteProps, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import ErrorBoundary from './components/ErrorBoundary';
import SplitAuthLayout from './layouts/SplitAuthLayout';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import AuthGuard from './components/auth/AuthGuard';
import GuestGuard from './components/auth/GuestGuard';
import VerifyEmail from './pages/VerifyEmail';
import EmailValidated from './pages/EmailValidated';
import AuthAction from './pages/AuthAction';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Checkout from './pages/Checkout';
import CheckoutLayout from './layouts/CheckoutLayout';
import CheckoutGuard from './components/auth/CheckoutGuard';
import AcceptInvitation from './pages/AcceptInvitation';
import DashboardLayout from './layouts/DashboardLayout';
import ResourcePlanner from './pages/ResourcePlanner';
import TeamMembers from './pages/TeamMembers';
import SettingsLayout from './layouts/SettingsLayout';
import AdminGuard from './components/auth/AdminGuard';
import Billing from './pages/Billing';
import Account from './pages/Account';
import Organisation from './pages/Organisation';
import Brands from './pages/Brands';
import Projects from './pages/Projects';
import BrandLayout from './layouts/BrandLayout';
import BrandOverview from './pages/BrandOverview';
import WIP from './pages/WIP';
import WIPTemplates from './pages/WIPTemplates';
import TaskTemplateLayout from './layouts/TaskTemplateLayout';
import Dashboard from './components/Dashboard';
import MemberGuard from './components/auth/MemberGuard';
import StrategicPlanner from './pages/StrategicPlanner';
import StrategicPlannerLayout from './layouts/StrategicPlannerLayout';
import NewStrategicPlan from './pages/NewStrategicPlan';
import StrategicPlan from './pages/StrategicPlan';
import Maintenance from './pages/Maintenance';

type RouteConfig = {
  path: string | readonly string[];
  exact?: boolean;
  title?: string;
  guard?: React.FC<{}> | null;
  guardProps?: {};
  layout?: React.FC<{}> | null;
  layoutProps?: {};
  component?: React.FC<RouteProps>;
  routes?: RouteConfig[];
};

const routesConfig: RouteConfig[] = [
  {
    path: '/',
    exact: true,
    title: 'Dashboard',
    layout: DashboardLayout,
    component: Dashboard,
    guard: AuthGuard,
  },
  {
    path: '/maintenance',
    exact: true,
    title: 'Maintenance',
    component: Maintenance,
    layout: null,
    guard: null,
  },
  {
    path: '/brands',
    exact: true,
    title: 'Brands',
    layout: DashboardLayout,
    component: Brands,
    guard: AuthGuard,
  },
  {
    path: '/work-in-progress/templates',
    title: 'Manage Templates',
    component: WIPTemplates,
    layout: TaskTemplateLayout,
    guard: AuthGuard,
  },
  {
    path: '/brands/:brandId',
    title: 'Brand overview',
    layout: BrandLayout,
    guard: AuthGuard,
    routes: [
      {
        path: '/brands/:brandId/overview',
        title: 'Brand overview',
        component: BrandOverview,
      },
      {
        path: '/brands/:brandId/projects',
        title: 'Projects',
        component: Projects,
      },
      {
        path: '*',
        component: history => (
          <Redirect to={history.location?.pathname + '/overview'} />
        ),
      },
    ],
  },
  {
    path: '/auth/checkout',
    exact: true,
    title: 'Checkout',
    component: Checkout,
    guard: CheckoutGuard,
    layout: CheckoutLayout,
  },
  {
    path: '/strategic-planning',
    exact: true,
    title: 'Strategic Planner',
    layout: StrategicPlannerLayout,
    component: StrategicPlanner,
    guard: AuthGuard,
  },
  {
    path: '/strategic-planning/:brandId/new',
    exact: true,
    title: 'New Strategic Plan',
    layout: StrategicPlannerLayout,
    component: NewStrategicPlan,
    guard: AuthGuard,
  },
  {
    path: '/strategic-planning/:brandId/:SPId/section/:sectionId',
    exact: true,
    title: 'Strategic Plan',
    layout: StrategicPlannerLayout,
    component: StrategicPlan,
    guard: AuthGuard,
  },
  {
    path: '/strategic-planning/:brandId',
    title: 'Strategic Planner',
    layout: StrategicPlannerLayout,
    component: StrategicPlanner,
    guard: AuthGuard,
  },
  {
    path: '/resource-planner',
    exact: true,
    title: 'Resource Planner',
    guard: AuthGuard,
    routes: [
      {
        path: '/',
        component: ResourcePlanner,
        layout: DashboardLayout,
        layoutProps: {
          fullWidth: true,
        },
        guard: MemberGuard,
      },
    ],
  },
  {
    path: '/auth',
    layout: SplitAuthLayout,
    guard: GuestGuard,
    routes: [
      {
        path: '/auth/verify-email',
        title: 'Verify Email Address',
        component: VerifyEmail,
      },
      {
        path: '/auth/email-validated',
        title: 'Your Email Address is validated',
        component: EmailValidated,
      },
      {
        path: '/auth/login',
        exact: true,
        title: 'Sign In',
        component: SignIn,
      },
      {
        path: '/auth/sign-up',
        exact: true,
        title: 'Sign Up',
        component: SignUp,
      },
      {
        path: '/auth/forgot-password',
        exact: true,
        title: 'Forgot Password',
        component: ForgotPassword,
      },
      {
        path: '/auth/reset-password',
        exact: true,
        title: 'Reset Password',
        component: ResetPassword,
      },
      {
        path: '/auth/action',
        title: 'Redirecting..',
        component: AuthAction,
      },
      {
        path: '/auth/register',
        exact: true,
        title: 'Accept Invitation',
        component: AcceptInvitation,
      },
      {
        path: '*',
        component: () => (
          <>
            <Redirect to="/auth/login" />
          </>
        ),
      },
    ],
  },
  {
    path: '*',
    layout: DashboardLayout,
    guard: AuthGuard,
    routes: [
      {
        title: 'Profile',
        path: '/account',
        exact: true,
        component: Account,
      },
      {
        path: '/settings',
        layout: SettingsLayout,
        guard: AdminGuard,
        routes: [
          {
            title: 'Manage Organisation',
            path: '/settings/organisation',
            exact: true,
            component: Organisation,
          },
          {
            title: 'Team members',
            path: '/settings/team-members',
            exact: true,
            component: TeamMembers,
          },
          {
            title: 'Billing',
            path: '/settings/billing',
            exact: true,
            component: Billing,
          },
          {
            path: '*',
            component: () => <Redirect to="/settings/organisation" />,
          },
        ],
      },
      {
        path: '/work-in-progress/:status',
        title: 'Work in Progress',
        component: WIP,
      },
      {
        path: '/work-in-progress',
        title: 'Work in Progress',
        exact: true,
        component: () => <Redirect to="/work-in-progress/to-do" />,
      },
    ],
  },
];

const renderRoutes = (routes: RouteConfig[]) =>
  routes ? (
    <Switch>
      {routes.map((route, index) => {
        const Guard = route.guard ?? React.Fragment;
        const Layout = route.layout ?? React.Fragment;
        const Component = route.component ?? React.Fragment;

        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard {...route.guardProps}>
                <Layout {...route.layoutProps}>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <ErrorBoundary>
                      {route.title ? (
                        <Helmet>
                          <title>{route.title}</title>
                        </Helmet>
                      ) : null}

                      <Component {...props} />
                    </ErrorBoundary>
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
