import React from 'react';
import { Column } from 'react-table';
import { Link } from 'react-router-dom';

import { Brand } from '../../types/brand';
import { formatStringDate } from '../../utils/helper';

import Table from '../Table';
import Avatar from '../Avatar';

type BrandsTableProps = {
  data: Brand[];
};

const BrandsTable: React.FC<BrandsTableProps> = props => {
  const columns = React.useMemo<Column<Brand>[]>(
    () => [
      {
        Header: 'Brand',
        accessor: 'name',
      },
      {
        Header: 'PM',
        accessor: ({ projectManager }) => {
          if (!projectManager) return '-';

          return (
            <div className="flex items-center">
              <Avatar
                className="w-5 h-5 mr-3 text-xs"
                firstName={projectManager?.firstName}
                lastName={projectManager?.lastName}
              />{' '}
              {projectManager?.firstName} {projectManager?.lastName}
            </div>
          );
        },
      },
      {
        Header: 'Projects',
        accessor: brand => brand.projectCount || 0,
      },
      {
        Header: 'Contract end',
        accessor: ({ contractEnd }) => formatStringDate(contractEnd, '-'),
      },
      {
        id: 'view',
        disableSortBy: true,
        accessor: ({ id }) => {
          return (
            <Link
              className="text-link"
              to={`/brands/${id}/overview`}
              title="View Brand"
            >
              View
            </Link>
          );
        },
      },
    ],
    []
  );

  return (
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="overflow-hidden">
          <Table columns={columns} data={props.data} />
        </div>
      </div>
    </div>
  );
};

export default BrandsTable;
