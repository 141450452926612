import React, { SyntheticEvent } from 'react';
import { ChevronRightIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { Transition } from '@headlessui/react';

import DetailGroup from '../Brands/DetailGroup';
import Avatar from '../Avatar';
import { Project } from '../../types/project';
import { formatStringDate } from '../../utils/helper';

type ProjectItemProps = {
  open: boolean;
  canEdit: boolean;
  data: Project;
  onToggle: () => void;
  onEdit: () => void;
};

const ProjectItem: React.FC<ProjectItemProps> = props => {
  const handleEdit = (e: SyntheticEvent) => {
    e.stopPropagation();
    props.onEdit();
  };

  return (
    <div className="shadow sm:rounded-md bg-white">
      <div
        role="button"
        className="flex w-full items-center px-4 sm:px-6 py-3 sm:py-5"
        onClick={props.onToggle}
      >
        <div className="text-left">
          <p className="text-md sm:text-lg leading-6 font-medium">
            {props.data.name}{' '}
            {props.data.isSecret ? (
              <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                Private
              </span>
            ) : null}
          </p>
          {props.open ? (
            <p className="text-gray-500 text-sm">Overview</p>
          ) : null}
        </div>
        <div className="ml-auto flex items-center">
          {props.open && props.canEdit ? (
            <button className="btn btn-primary" onClick={handleEdit}>
              Edit
            </button>
          ) : null}
          <ChevronRightIcon
            className={clsx('ml-6 w-6 h-6', {
              'transform rotate-90': props.open,
            })}
          />
        </div>
      </div>
      <Transition
        show={props.open}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
        className="divide-y divide-gray-200 border-t border-gray-200"
      >
        <DetailGroup name="Brand Management" description="Internal details">
          <DetailGroup.Item
            name="Project Manager"
            value={
              <div className="flex items-center">
                <Avatar
                  className="w-5 h-5 mr-3 text-xs"
                  firstName={props.data.projectManager?.firstName}
                  lastName={props.data.projectManager?.lastName}
                />
                {props.data.projectManager?.firstName}{' '}
                {props.data.projectManager?.lastName}
              </div>
            }
          />
          <DetailGroup.Item
            name="Project End Date"
            value={formatStringDate(props.data.projectEndDate)}
          />
        </DetailGroup>
        <DetailGroup
          name="Key Contact"
          description="Contact details for primary Brand contact."
        >
          <DetailGroup.Item
            name="Contact Name"
            value={props.data.keyContact?.fullName}
          />
          <DetailGroup.Item
            name="Contact Number"
            value={props.data.keyContact?.phoneNumber}
          />
          <DetailGroup.Item
            name="Contact Email"
            value={props.data.keyContact?.email}
          />
        </DetailGroup>
        <DetailGroup
          name="Billing Contact"
          description="Contact details for Brand billing."
        >
          <DetailGroup.Item
            name="Contact Name"
            value={props.data.billingContact?.fullName}
          />
          <DetailGroup.Item
            name="Contact Number"
            value={props.data.billingContact?.phoneNumber}
          />
          <DetailGroup.Item
            name="Contact Email"
            value={props.data.billingContact?.email}
          />
          <DetailGroup.Item
            name="Billing Instructions"
            value={props.data?.billingInstructions || ''}
          />
        </DetailGroup>
      </Transition>
    </div>
  );
};

export default ProjectItem;
