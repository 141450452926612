import React from 'react';
import AuthLayoutHeader from '../components/auth/AuthLayoutHeader';
import ForgotPasswordForm from '../components/auth/ForgotPasswordForm';

const ForgotPassword: React.FC = () => {
  return (
    <div>
      <AuthLayoutHeader
        title="Forgot Password"
        backButtonLink="/auth/login"
        backButtonText="Back to Login"
      />
      <ForgotPasswordForm />
    </div>
  );
};

export default ForgotPassword;
