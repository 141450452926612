import React from 'react';
import { FormikAnswer, Question } from '../../../types/strategic-plan';
import QuestionContainer from '../QuestionContainer';
import { FastField, FieldArray, useFormikContext } from 'formik';
import FormikInput from '../../FormikInput';
import { MinusCircleIcon, PlusIcon } from '@heroicons/react/solid';
import clsx from 'clsx';

type TableProps = {
  index: number;
  question: Question;
};

const Table: React.FC<TableProps> = props => {
  const formik = useFormikContext<{
    questions: FormikAnswer[];
  }>();

  return (
    <QuestionContainer
      name={props.question.name}
      description={props.question.description}
    >
      <fieldset>
        <FieldArray
          name={`questions[${props.index}].formData.rows`}
          render={arrayHelpers => (
            <div>
              <div className="space-y-4">
                {formik.values.questions[props.index]?.formData?.rows?.map(
                  (row: {}[], rowNumber: number) => (
                    <div key={rowNumber} className="flex space-x-4">
                      {Array(
                        props.question.questionSpecifics?.tableColumns || 2
                      )
                        .fill('')
                        .map((col, colNumber) => (
                          <div className="flex-grow" key={colNumber}>
                            <FastField
                              label={
                                rowNumber === 0 ? 'Column Title' : 'Table Row'
                              }
                              name={`questions[${props.index}].formData.rows[${rowNumber}].col${colNumber}Val`}
                              type="text"
                              component={FormikInput}
                              placeholder={
                                rowNumber === 0
                                  ? 'i.e: Example title here'
                                  : 'i.e: Example text here'
                              }
                            />
                          </div>
                        ))}
                      <button
                        onClick={() => arrayHelpers.remove(rowNumber)}
                        type="button"
                        className={clsx('ml-2 mt-5', {
                          'opacity-0 cursor-default': rowNumber === 0,
                        })}
                        disabled={rowNumber === 0}
                      >
                        <MinusCircleIcon className="w-10 h-10  text-secondary-600 hover:text-secondary-900" />
                      </button>
                    </div>
                  )
                )}
              </div>
              <div className="flex items-center mt-4">
                <div className="border-gray-200 border-b w-full" />

                <button
                  onClick={() => arrayHelpers.push('')}
                  type="button"
                  className="btn btn-white whitespace-nowrap"
                >
                  <PlusIcon className="w-5 h-5  text-gray-900 mr-2" />
                  <span className="text-sm leading-5 font-medium">Add Row</span>
                </button>
              </div>
            </div>
          )}
        />
      </fieldset>
    </QuestionContainer>
  );
};

export default Table;
