import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { useMutation, useQueryClient } from 'react-query';
import toast from 'react-hot-toast';

import { useSubscription } from '../../hooks/useSubscription';
import organisationService from '../../services/organisationService';
import { SubscriptionDetails } from '../../types/organisation';
import { Price } from '../../types/checkout';

import SubscriptionType from '../Checkout/SubscriptionType';
import Spinner from '../../Spinner';

const PaymentFrequency: React.FC = () => {
  const details = useSubscription();
  const queryClient = useQueryClient();

  const changeFrequency = useMutation(
    organisationService.changeBillingFrequency,
    {
      onSuccess: response => {
        queryClient.setQueryData<SubscriptionDetails | undefined>(
          'subscription-details',
          data => {
            if (!data) return;

            return {
              ...data,
              currentPeriodStart: response.currentPeriodStart,
              currentPeriodEnd: response.currentPeriodEnd,
              billingFrequency: response.billingFrequency,
            };
          }
        );
      },
      onError: () => {
        toast.error('Try again! Something went wrong!');
      },
    }
  );

  const handleOnSubmit = async (values: { type: Price }) => {
    try {
      await changeFrequency.mutateAsync({
        newBillingFrequency: values.type.id,
      });
    } catch (e) {
      //
    }
  };

  const prices: Price[] = useMemo(
    () => [
      {
        id: 'month',
        name: 'Monthly',
        price: parseFloat(process.env.REACT_APP_STRIPE_MONTHLY_PRICE || ''),
      },
      {
        id: 'year',
        name: 'Annual',
        price: parseFloat(process.env.REACT_APP_STRIPE_ANNUAL_PRICE || ''),
      },
    ],
    []
  );

  if (details.isLoading) {
    return (
      <section className="bg-white shadow sm:rounded-md sm:overflow-hidden mb-6 p-6">
        <h3 className="text-lg font-medium text-gray-900">Payment Frequency</h3>
        <p className="text-sm text-gray-500 mb-6">
          Select how you’d like to be billed. Changes will come into affect at
          the end of the current billing period.
        </p>
        <div className="flex space-x-3 animate-pulse">
          <div className="w-1/2 h-24 w-60 bg-gray-100 border-gray-300 rounded-lg shadow-sm" />
          <div className="w-1/2 h-24 w-60 bg-gray-100 border-gray-300 rounded-lg shadow-sm" />
        </div>
      </section>
    );
  }

  const initialValues: { type: Price } = {
    type:
      prices.find(price => price.id === details.data?.billingFrequency) ||
      prices[0],
  };

  const schema = Yup.object().shape({
    type: Yup.object()
      .shape({
        id: Yup.string().required(),
        name: Yup.string().required(),
        price: Yup.number().required(),
      })
      .required('Subscription Type is required'),
  });

  return (
    <section>
      <div className="bg-white shadow sm:rounded-md sm:overflow-hidden mb-6">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={handleOnSubmit}
        >
          {({
            isSubmitting,
            values,
            dirty,
            setFieldValue,
          }: FormikProps<{ type: Price }>) => (
            <Form className="p-6" noValidate>
              <SubscriptionType
                value={values.type}
                onChange={setFieldValue}
                prices={prices}
                description="Select how you’d like to be billed. Changes will come into affect at
            the end of the current billing period."
                title="Payment Frequency"
              />
              <div className="flex">
                <button
                  className="btn btn-primary ml-auto"
                  type="submit"
                  disabled={isSubmitting || !dirty}
                >
                  {isSubmitting ? (
                    <>
                      <Spinner
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      Update
                    </>
                  ) : (
                    'Update'
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
};

export default PaymentFrequency;
