import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { db } from '../utils/firebase';

import {
  PlanDTO,
  Question,
  SPDTO,
  SPSection,
  StrategicPlan,
} from '../types/strategic-plan';
import axios from '../utils/axios';
import transformDoc from '../utils/transform-doc';
import CustomError from '../utils/custom-error';

class StrategicPlanService {
  getSections = async (): Promise<SPSection[]> => {
    const q = query(
      collection(db, 'strategic-plan-sections'),
      orderBy('order', 'asc')
    );

    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return [];
    }

    return querySnapshot.docs.map(doc => {
      return { id: doc.id, ...doc.data() } as SPSection;
    });
  };

  create = async (data: SPDTO): Promise<StrategicPlan> => {
    const response = await axios.post<StrategicPlan>('/strategic-plans', {
      brand: data.brand ? data.brand.id : '',
      project: data.project ? data.project.id : null,
      name: data.name,
    });

    return response.data;
  };

  getPlansByBrand = async (context: {
    brandId: string;
    organisationId: string;
  }): Promise<StrategicPlan[]> => {
    const q = query(
      collection(db, 'strategic-plans'),
      where('brand.id', '==', context.brandId),
      where('organisation.id', '==', context.organisationId)
    );

    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return [];
    }

    return querySnapshot.docs.map(doc => {
      return {
        ...doc.data(),
        id: doc.id,
        createdAt: doc.data().createdAt
          ? doc.data().createdAt.toDate().toISOString()
          : null,
        updatedAt: doc.data().createdAt
          ? doc.data().createdAt.toDate().toISOString()
          : null,
      } as StrategicPlan;
    });
  };

  getPlanByPlanId = async (SPId: string): Promise<StrategicPlan> => {
    const docRef = doc(db, 'strategic-plans', SPId);
    const plan = await transformDoc<Omit<StrategicPlan, 'id'>>(docRef);

    if (!plan) {
      throw new CustomError('i-strategic-plan/no-plan-found');
    }

    return {
      id: SPId,
      ...plan,
      createdAt: plan.createdAt
        ? // @ts-ignore
          plan.createdAt.toDate().toISOString()
        : null,
      updatedAt: plan.createdAt
        ? // @ts-ignore
          plan.createdAt.toDate().toISOString()
        : null,
    };
  };

  getQuestionsBySectionId = async (sectionId: string): Promise<Question[]> => {
    const q = query(
      collection(db, 'strategic-plan-questions'),
      where('section', '==', sectionId),
      orderBy('order', 'asc')
    );

    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return [];
    }

    return querySnapshot.docs.map(doc => {
      return {
        ...doc.data(),
        id: doc.id,
      } as Question;
    });
  };

  savePlan = async (SPId: string, data: PlanDTO): Promise<StrategicPlan> => {
    const response = await axios.patch<StrategicPlan>(
      `/strategic-plans/${SPId}`,
      data
    );

    return response.data;
  };

  deletePlan = async (SPId: string): Promise<StrategicPlan> => {
    const response = await axios.delete<StrategicPlan>(
      `/strategic-plans/${SPId}`
    );

    return response.data;
  };
}

export const strategicPlanService = new StrategicPlanService();

export default strategicPlanService;
