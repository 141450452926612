import React, { useState } from 'react';
import { CogIcon, PencilAltIcon } from '@heroicons/react/solid';
import { useQuery } from 'react-query';
import format from 'date-fns/format';
import isSameMonth from 'date-fns/isSameMonth';
import addMonths from 'date-fns/addMonths';
import subMonths from 'date-fns/subMonths';

import resourcePlannerService from '../services/resourcePlannerService';
import { resourcePlannerKeys } from '../config';
import { useSettings } from '../hooks/useSettings';
import { usePermission } from '../hooks/usePermission';

import Table from '../components/ResourcePlanner/Table';
import TotalRow from '../components/ResourcePlanner/TotalRow';
import PlanMonthDrawer from '../components/ResourcePlanner/PlanMonthDrawer';
import ManageProjectsDrawer from '../components/ResourcePlanner/ManageProjectsDrawer';
import ErrorPage from '../components/ErrorPage';
import Spinner from '../Spinner';
import SettingsModal from '../components/ResourcePlanner/SettingsModal';
import EmptyResourcePlanner from '../components/ResourcePlanner/EmptyResourcPlanner';

const ResourcePlanner: React.FC = () => {
  useSettings(); //preload query
  const permission = usePermission();
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [openPlanMonthDrawer, setPlanMonthDrawer] = useState(false);
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [openManageProjectsDrawer, setManageProjectsDrawer] = useState(false);

  const canEdit = permission.data?.isAdmin || permission.data?.isMember;

  const handlePlanMonthDrawerOpen = () => {
    setPlanMonthDrawer(true);
  };
  const handlePlanMonthDrawerClose = () => {
    setPlanMonthDrawer(false);
  };

  const handleSettingsModalOpen = () => {
    setOpenSettingsModal(true);
  };
  const handleSettingsModalClose = () => {
    setOpenSettingsModal(false);
  };

  const handleManageProjectsDrawerOpen = () => {
    setManageProjectsDrawer(true);
  };
  const handleManageProjectsDrawerClose = () => {
    setManageProjectsDrawer(false);
  };

  const handleNextMonth = () => {
    setSelectedMonth(prevState => addMonths(prevState, 1));
  };

  const handlePreviousMonth = () => {
    setSelectedMonth(prevState => subMonths(prevState, 1));
  };

  const formattedSelectedMonth = format(
    selectedMonth,
    'LLLL-yyyy'
  ).toUpperCase();

  const resourcePlannerData = useQuery(
    resourcePlannerKeys.month(formattedSelectedMonth),
    () =>
      resourcePlannerService.getByDate(
        selectedMonth.getFullYear(),
        format(selectedMonth, 'LLLL').toUpperCase()
      )
  );

  if (resourcePlannerData.isLoading) {
    return (
      <section className="shadow lg:rounded-md relative bg-white">
        <div className="py-7 px-4 sm:p-6">
          <div className="xl:flex items-center mb-9">
            <h1 className="text-2xl leading-7 font-bold text-gray-700 mr-8">
              Team Resources
            </h1>

            <div className="lg:flex justify-between flex-1">
              <div className="flex items-center md:order-2 lg:mx-4 mb-2 lg:mb-0">
                <span className="block w-36 h-6 bg-gray-200 rounded animate-pulse" />
              </div>

              {canEdit ? (
                <div className="sm:flex md:order-1 mb-4 lg:mb-0">
                  <button className="btn btn-white mr-4" disabled>
                    <CogIcon className="leading-icon" />
                    Settings
                  </button>
                  <button className="btn btn-secondary" disabled>
                    <PencilAltIcon className="leading-icon" />
                    Manage projects
                  </button>
                </div>
              ) : null}

              <div className="flex md:order-3">
                <button className="btn btn-white mr-4" disabled>
                  Previous Month
                </button>
                <button className="btn btn-white" disabled>
                  Next Month
                </button>
              </div>
            </div>
          </div>
          <div className="justify-center flex items-center h-96">
            <Spinner className="text-secondary-600 w-12 h-12" />
          </div>
        </div>
      </section>
    );
  }

  if (resourcePlannerData.isError || !resourcePlannerData.data) {
    return (
      <ErrorPage
        title="Something went wrong!"
        text="We keep track of these errors, but feel free to contact us if refreshing doesn't fix things."
        subtitle="500 Error"
      />
    );
  }

  const activeProjects = resourcePlannerData.data.projects.filter(
    p => p.isActive
  );

  if (activeProjects.length < 1) {
    return (
      <>
        <EmptyResourcePlanner
          onManageProjects={handleManageProjectsDrawerOpen}
        />
        <ManageProjectsDrawer
          open={openManageProjectsDrawer}
          onClose={handleManageProjectsDrawerClose}
          resourceMonthId={resourcePlannerData.data.resourceMonth.id}
          resourceMonthActiveProjectIds={resourcePlannerData.data.projects
            .filter(p => p.isActive)
            .map(p => p.id)}
          drawerSubTitle={`${format(selectedMonth, 'LLLL yyyy')} projects`}
          isCurrentMonth={isSameMonth(selectedMonth, new Date())}
        />
      </>
    );
  }

  return (
    <section className="shadow lg:rounded-md relative bg-white">
      {canEdit ? (
        <>
          <PlanMonthDrawer
            open={openPlanMonthDrawer}
            onClose={handlePlanMonthDrawerClose}
            nextMonth={format(addMonths(selectedMonth, 1), 'LLLL `yy')}
          />
          <ManageProjectsDrawer
            open={openManageProjectsDrawer}
            onClose={handleManageProjectsDrawerClose}
            resourceMonthId={resourcePlannerData.data.resourceMonth.id}
            resourceMonthActiveProjectIds={resourcePlannerData.data.projects
              .filter(p => p.isActive)
              .map(p => p.id)}
            drawerSubTitle={`${format(selectedMonth, 'LLLL yyyy')} projects`}
            isCurrentMonth={isSameMonth(selectedMonth, new Date())}
          />
          <SettingsModal
            open={openSettingsModal}
            onClose={handleSettingsModalClose}
          />
        </>
      ) : null}

      <div className="py-7 px-4 sm:p-6">
        <div className="xl:flex items-center mb-9">
          <h1 className="text-2xl leading-7 font-bold text-gray-700 mr-8 mb-2 lg:mb-2">
            Team Resources
          </h1>

          <div className="lg:flex justify-between flex-1">
            <div className="flex items-center md:order-2 lg:mx-4 mb-2 lg:mb-0">
              <p className="text-xl leading-7 text-gray-700 mr-2">
                {format(selectedMonth, 'LLLL yyyy')}
              </p>
              {isSameMonth(selectedMonth, new Date()) ? (
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-100 text-gray-800">
                  Current
                </span>
              ) : null}
            </div>

            {canEdit ? (
              <div className="sm:flex md:order-1 mb-4 lg:mb-0">
                <button
                  className="btn btn-white mr-4"
                  onClick={handleSettingsModalOpen}
                >
                  <CogIcon className="leading-icon" />
                  Settings
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={handleManageProjectsDrawerOpen}
                >
                  <PencilAltIcon className="leading-icon" />
                  Manage projects
                </button>
              </div>
            ) : null}

            <div className="flex md:order-3">
              <button
                className="btn btn-white mr-4"
                onClick={handlePreviousMonth}
              >
                Previous Month
              </button>
              {!resourcePlannerData.data.isFutureMonthPlanned &&
              isSameMonth(selectedMonth, new Date()) &&
              canEdit ? (
                <button
                  className="btn btn-primary"
                  onClick={handlePlanMonthDrawerOpen}
                >
                  Plan Next Month
                </button>
              ) : (
                <button
                  className="btn btn-white"
                  onClick={handleNextMonth}
                  disabled={
                    !isSameMonth(selectedMonth, new Date()) &&
                    selectedMonth > new Date()
                  }
                >
                  Next Month
                </button>
              )}
            </div>
          </div>
        </div>
        <Table data={resourcePlannerData.data} />
        <TotalRow data={resourcePlannerData.data} />
      </div>
    </section>
  );
};

export default ResourcePlanner;
