import React, { useState } from 'react';
import { Column } from 'react-table';
import { Link } from 'react-router-dom';

import { Member } from '../../types/organisation';
import { useIsOwner } from '../../hooks/useUser';
import Avatar from '../Avatar';
import Table from '../Table';
import EditUser from './EditUser';

type MemberTableProps = {
  data: Member[];
};

const MemberTable: React.FC<MemberTableProps> = props => {
  const amIOwner = useIsOwner();
  const [edit, setEdit] = useState<{ open: boolean; userId: string | null }>({
    open: false,
    userId: null,
  });

  const columns = React.useMemo<Column<Member>[]>(
    () => [
      {
        Header: 'User',
        accessor: person => (
          <div className="flex items-center">
            <Avatar
              className="w-5 h-5 mr-3 text-xs"
              firstName={person.firstName}
              lastName={person.lastName}
            />{' '}
            {person.firstName} {person.lastName}
          </div>
        ),
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Phone Number',
        accessor: 'mobileNumber',
      },
      {
        Header: 'Role',
        accessor: person => (
          <span className="capitalize">{person.role.toLowerCase()}</span>
        ),
      },
      {
        Header: 'Status',
        accessor: person => {
          return person.type === 'INVITE' ? 'Invited' : 'Active';
        },
      },
      {
        id: 'edit',
        disableSortBy: true,
        accessor: person => {
          //if i am not an owner, i cant edit the owner users
          if (!amIOwner.data && person.role === 'OWNER') {
            return null;
          }

          //if i am an owner, i can edit my details under Profile menu
          if (amIOwner.data && person.role === 'OWNER') {
            return (
              <Link to="/account" className="text-link">
                View
              </Link>
            );
          }

          // regular drawer editor
          return (
            <button
              className="text-link"
              onClick={() => setEdit({ open: true, userId: person.id })}
            >
              View
            </button>
          );
        },
      },
    ],
    [amIOwner.data]
  );

  return (
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="overflow-hidden">
          {/* EditUser drawer */}
          <EditUser
            userId={edit.userId}
            open={edit.open}
            onClose={() =>
              setEdit(old => ({ open: false, userId: old.userId }))
            }
          />

          <Table columns={columns} data={props.data} />
        </div>
      </div>
    </div>
  );
};

export default MemberTable;
