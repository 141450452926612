import React from 'react';
import { Column } from 'react-table';

import { TaskTemplate } from '../../types/task';
import Table from '../Table';

const TemplatesTableSkeleton: React.FC = () => {
  const columns = React.useMemo<Column<TaskTemplate>[]>(() => {
    return [
      {
        Header: 'Template Name',
        disableSortBy: true,
        accessor: () => (
          <span className="block w-36 h-3  bg-gray-200 rounded animate-pulse" />
        ),
      },
      {
        Header: 'Activity',
        disableSortBy: true,
        accessor: () => (
          <span className="block w-36 h-3  bg-gray-200 rounded animate-pulse" />
        ),
      },
      {
        Header: 'Number of tasks',
        disableSortBy: true,
        accessor: () => (
          <span className="block w-28 h-3  bg-gray-200 rounded animate-pulse" />
        ),
      },
      {
        Header: 'Date Created',
        disableSortBy: true,
        accessor: () => (
          <span className="block w-28 h-3  bg-gray-200 rounded animate-pulse" />
        ),
      },
      {
        Header: 'Last Updated',
        disableSortBy: true,
        accessor: () => (
          <span className="block w-28 h-3  bg-gray-200 rounded animate-pulse" />
        ),
      },
      {
        id: 'view',
        disableSortBy: true,
        accessor: () => (
          <span className="block w-16 h-3  bg-gray-200 rounded animate-pulse" />
        ),
      },
    ];
  }, []);

  const data = new Array(6).fill('');

  return <Table columns={columns} data={data} />;
};

export default TemplatesTableSkeleton;
