import React, { useState } from 'react';
import { PlusIcon } from '@heroicons/react/solid';

import { useProjectsByBrand } from '../hooks/useProjects';
import { usePermission } from '../hooks/usePermission';

import NewProjectDrawer from '../components/Projects/NewProjectDrawer';
import ProjectItem from '../components/Projects/ProjectItem';
import EditProjectDrawer from '../components/Projects/EditProjectDrawer';

const Projects: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState<string | null>(null);
  const permission = usePermission();
  const projects = useProjectsByBrand();
  const [edit, setEdit] = useState<{ open: boolean; projectId: string | null }>(
    {
      open: false,
      projectId: null,
    }
  );

  const toggleProjectItem = (projectId: string) => async () => {
    if (selectedProject === projectId) {
      setSelectedProject(null);
      return;
    }
    setSelectedProject(projectId);
  };

  const openEditDrawer = (projectId: string) => () => {
    setEdit({
      open: true,
      projectId: projectId,
    });
  };

  if (
    !projects.isLoading &&
    !projects.error &&
    projects.data &&
    projects.data.length < 1
  ) {
    return (
      <div className="text-center mt-20">
        {permission.data?.isAdmin ? (
          <NewProjectDrawer open={open} onClose={() => setOpen(false)} />
        ) : null}
        <svg
          className="mx-auto h-12 w-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
          />
        </svg>
        <h3 className="mt-2 text-sm font-medium text-gray-900">No projects</h3>
        <p className="mt-1 text-sm text-gray-500">
          Get started by creating a new project.
        </p>
        {permission.data?.isAdmin ? (
          <div className="mt-6">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setOpen(true)}
            >
              <PlusIcon className="leading-icon" aria-hidden="true" />
              New Project
            </button>
          </div>
        ) : null}
      </div>
    );
  }

  const Listing: React.FC = () => {
    if (projects.isLoading)
      return (
        <div className="space-y-6">
          <div className="h-16 w-full bg-white rounded shadow sm:rounded-md flex items-center px-6 justify-between">
            <div className="bg-gray-200 h-6 w-60 animate-pulse rounded" />
            <div className="bg-gray-200 h-6 w-6 animate-pulse rounded" />
          </div>
          <div className="h-16 w-full bg-white rounded shadow sm:rounded-md flex items-center px-6 justify-between">
            <div className="bg-gray-200 h-6 w-60 animate-pulse rounded" />
            <div className="bg-gray-200 h-6 w-6 animate-pulse rounded" />
          </div>
          <div className="h-16 w-full bg-white rounded shadow sm:rounded-md flex items-center px-6 justify-between">
            <div className="bg-gray-200 h-6 w-60 animate-pulse rounded" />
            <div className="bg-gray-200 h-6 w-6 animate-pulse rounded" />
          </div>
        </div>
      );

    if (!projects.data || projects.error) return <p>error</p>;

    return (
      <div className="space-y-3 sm:space-y-6 px-0 sm:px-6 lg:px-0">
        {projects.data.map(project => (
          <ProjectItem
            key={project.id}
            open={selectedProject === project.id}
            onToggle={toggleProjectItem(project.id)}
            data={project}
            onEdit={openEditDrawer(project.id)}
            canEdit={permission.data?.canEditProjectById(project.id) || false}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="max-w-screen-lg	mx-auto mt-6">
      {permission.data?.isAdmin ? (
        <NewProjectDrawer open={open} onClose={() => setOpen(false)} />
      ) : null}

      {!permission.data?.isViewer ? (
        <EditProjectDrawer
          open={edit.open}
          onClose={() =>
            setEdit(prev => ({
              ...prev,
              open: false,
            }))
          }
          data={
            projects.data?.find(project => project.id === edit.projectId) ||
            null
          }
        />
      ) : null}

      <div className="flex items-center mb-6 px-6 lg:px-0">
        <h1 className="text-xl md:text-3xl leading-9 font-bold">Projects</h1>
        {permission.data?.isAdmin ? (
          <button
            className="btn btn-primary ml-auto"
            onClick={() => setOpen(true)}
          >
            New Project
          </button>
        ) : null}
      </div>
      <Listing />
    </div>
  );
};

export default Projects;
