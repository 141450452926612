import React from 'react';
import { PencilAltIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';

type EmptyResourcePlannerProps = {
  onManageProjects: () => void;
};

const EmptyResourcePlanner: React.FC<EmptyResourcePlannerProps> = props => {
  return (
    <div className="text-center mt-12">
      <svg
        enableBackground="new 0 0 489.5 489.5"
        version="1.1"
        viewBox="0 0 489.5 489.5"
        className="mx-auto h-12 w-12"
        fill="#9ca3af"
        aria-hidden="true"
      >
        <rect y="1.35" width="97.5" height="97.5" />
        <rect x="138.3" y="27.45" width="351.2" height="43.1" />
        <rect y="195.95" width="97.5" height="97.5" />
        <rect x="138.3" y="222.05" width="351.2" height="43.1" />
        <rect y="390.65" width="97.5" height="97.5" />
        <rect x="138.3" y="416.65" width="351.2" height="43.1" />
      </svg>
      <h3 className="mt-2 text-sm font-medium text-gray-900">No Projects</h3>
      <p className="mt-1 text-sm text-gray-500">
        Before you are able to allocate resources, please ensure you have
        projects that are active for this month.
      </p>
      <div className="mt-6">
        <Link role="button" className="btn btn-secondary" to="/brands">
          View Brands
        </Link>
        <button
          type="button"
          className="ml-4 btn btn-primary"
          onClick={props.onManageProjects}
        >
          <PencilAltIcon className="leading-icon" aria-hidden="true" />
          Manage projects
        </button>
      </div>
    </div>
  );
};

export default EmptyResourcePlanner;
