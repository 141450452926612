import React from 'react';
import { FieldProps, getIn } from 'formik';
import clsx from 'clsx';

const FormikSelect: React.ComponentType<
  FieldProps & {
    label: string;
    options: Array<{ name: string; value: string }>;
  }
> = ({ field, form, label, options, ...rest }) => {
  const error = getIn(form.errors, field.name);
  const isTouched = getIn(form.touched, field.name);
  const hasError = isTouched && error;

  return (
    <div>
      <label
        htmlFor={field.name}
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <select
        id={field.name}
        className={clsx({
          select: true,
          'input-error ': hasError,
        })}
        {...field}
        {...rest}
      >
        {options.map(option => (
          <option value={option.value} key={option.value}>
            {option.name}
          </option>
        ))}
      </select>
      {hasError ? (
        <p className="mt-1 text-xs text-red-900" id={`${field.name}-error`}>
          {error}
        </p>
      ) : null}
    </div>
  );
};

export default FormikSelect;
