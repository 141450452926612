import React from 'react';
import { FastField, FieldArray } from 'formik';

import FormikInput from '../FormikInput';
import TaskFieldArray from './TaskFieldArray';

type TemplateFormProps = {
  isSubmitting: boolean;
};

const TemplateForm: React.FC<TemplateFormProps> = props => {
  return (
    <div className="px-4 divide-y divide-gray-200 sm:px-6">
      <fieldset className="space-y-6 pt-6 pb-5" disabled={props.isSubmitting}>
        <div className="flex items-center">
          <label className="text-base leading-6 font-semibold pb-0">
            Overview
          </label>
        </div>
        <FastField
          type="text"
          name="name"
          component={FormikInput}
          label="Template Name*"
          placeholder="Press Release"
          autoFocus
        />
      </fieldset>
      <fieldset className="space-y-6 pt-6 pb-5" disabled={props.isSubmitting}>
        <div className="flex items-center">
          <label className="text-base leading-6 font-semibold pb-0">
            Details
          </label>
        </div>
        <FastField
          type="text"
          name="activity"
          component={FormikInput}
          label="Activity*"
          placeholder="Activity Name"
        />
        <FieldArray
          name="tasks"
          render={props => <TaskFieldArray {...props} />}
        />
      </fieldset>
    </div>
  );
};

export default TemplateForm;
