import { doc } from 'firebase/firestore';
import {
  applyActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  deleteUser,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { QueryCache } from 'react-query';

import { SignIn, SignInErrorCode } from '../types/sign-in';
import { Invitation, InvitationResponse, SignUp } from '../types/sign-up';
import { NewUserValues, UpdateUserDTO, User } from '../types/user';
import axios from '../utils/axios';
import { auth, db } from '../utils/firebase';
import transformDoc from '../utils/transform-doc';
import CustomError from '../utils/custom-error';
import { queryClient } from '../utils/react-query';

class AuthService {
  setAxiosInterceptors = ({ onLogout }: { onLogout: any }) => {
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          this.setSession();

          if (typeof onLogout === 'function') {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  loginWithEmailAndPassword = async (data: SignIn): Promise<User> => {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      data.email,
      data.password
    );

    const token = await userCredential.user?.getIdToken(true);

    this.setSession(token);

    return await this.getUser(userCredential.user?.uid);
  };

  /**
   * Register Owner user
   */
  registerOwner = async (data: SignUp): Promise<User> => {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      data.email,
      data.password
    );

    const token = await userCredential.user?.getIdToken(true);

    this.setSession(token);

    try {
      const createUser = await axios.post<User>('/users/owner', {
        isSubscribedToMarketing: data.marketing_email,
        mobileNumber: data.phone,
        lastName: data.last_name,
        firstName: data.first_name,
        organisationName: data.company,
      });

      return createUser.data;
    } catch (e) {
      // user already registered by firebase, but registering the profile failed
      await deleteUser(userCredential.user);

      throw e;
    }
  };

  /**
   * Register member user (with invitation link)
   * @param data
   */
  registerMember = async (
    data: Omit<SignUp, 'company'> & { inviteId: string }
  ): Promise<User> => {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      data.email,
      data.password
    );

    const token = await userCredential.user?.getIdToken(true);

    this.setSession(token);

    try {
      const createUser = await axios.post<User>('/users/member', {
        isSubscribedToMarketing: data.marketing_email,
        mobileNumber: data.phone,
        lastName: data.last_name,
        firstName: data.first_name,
        inviteId: data.inviteId,
      });

      return createUser.data;
    } catch (e) {
      // user already registered by firebase, but registering the profile failed
      await deleteUser(userCredential.user);

      throw e;
    }
  };

  getUser = async (id?: string): Promise<User> => {
    let userId = id;
    if (!id) {
      userId = await auth.currentUser?.uid;
    }

    if (!userId) {
      throw Error('User not logged in');
    }

    const docRef = doc(db, 'users', userId);
    const user = await transformDoc<Omit<User, 'id'>>(docRef);

    if (!user) {
      throw Error('User not logged in');
    }

    if (user?.blocked) {
      throw new CustomError<SignInErrorCode>('i-user/user-is-blocked');
    }

    if (!auth.currentUser?.emailVerified) {
      throw new CustomError<SignInErrorCode>('forbidden/email-not-verified');
    }

    return { id: userId, ...user };
  };

  logout = async () => {
    signOut(auth);

    const queryCache = new QueryCache();
    queryCache.clear();
    await queryClient.resetQueries();
    this.setSession();
  };

  setSession = (accessToken?: string) => {
    if (accessToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      delete axios.defaults.headers.common.Authorization;
    }
  };

  getAccessToken = async (): Promise<string | undefined> =>
    await auth.currentUser?.getIdToken(true);

  isAuthenticated = async () => !!(await this.getAccessToken());

  resendEmailValidationEmail = async (email: string): Promise<void> => {
    return await axios.post('/emails/actions/resend-verification', { email });
  };

  verifyEmail = async (oobCode: string): Promise<void> => {
    return await applyActionCode(auth, oobCode);
  };

  sendResetPasswordEmail = async (email: string): Promise<void> => {
    return await axios.post('/emails/actions/reset-password', { email });
  };

  resetPassword = async (data: { oobCode: string; newPassword: string }) => {
    return await confirmPasswordReset(auth, data.oobCode, data.newPassword);
  };

  getInvitation = async (invitationId: string): Promise<Invitation> => {
    const response = await axios.get<Invitation>(`/invites/${invitationId}`);
    return response.data;
  };

  createInvitation = async (
    data: NewUserValues
  ): Promise<InvitationResponse> => {
    const response = await axios.post<InvitationResponse>(`/invites`, data);
    return response.data;
  };

  updateUser = async (values: {
    data: UpdateUserDTO;
    id: string;
  }): Promise<User> => {
    const response = await axios.patch<User>(
      `/users/${values.id}`,
      values.data
    );
    return response.data;
  };
}

const authService = new AuthService();

export default authService;
