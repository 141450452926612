import { Formik, FormikHelpers, FormikProps } from 'formik';
import React from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { memberKeys } from '../../config';
import { useMember } from '../../hooks/useMembers';
import { useUser } from '../../hooks/useUser';
import organisationService from '../../services/organisationService';
import Spinner from '../../Spinner';
import {
  Member,
  MemberCount,
  SubscriptionDetails,
} from '../../types/organisation';
import { InvitationResponse, InviteError } from '../../types/sign-up';
import { EditUserValues, User } from '../../types/user';
import Drawer from '../Drawer';
import UserForm from './UserForm';

type EditUserProps = {
  open: boolean;
  userId: string | null;
  onClose: () => void;
};

const schema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string(),
  email: Yup.string()
    .email('Must be a valid email')
    .required('Email is required'),
  mobileNumber: Yup.string(),
  role: Yup.string().required('Select user role'),
  fullTimeEquivalent: Yup.number().min(0).required(),
  projects: Yup.array(),
});

const EditUser: React.FC<EditUserProps> = props => {
  const member = useMember(props.userId);
  const user = useUser();
  const queryClient = useQueryClient();
  const history = useHistory();

  const updateAction =
    member.data?.type === 'USER'
      ? organisationService.updateUser
      : organisationService.updateInvitation;

  const deleteAction =
    member.data?.type === 'USER'
      ? organisationService.deleteUser
      : organisationService.deleteInvitation;

  const updateMember = useMutation<
    InvitationResponse | User,
    InviteError,
    EditUserValues
  >(values => updateAction(props.userId || '', values), {
    onSuccess: async invitation => {
      props.onClose();
      await queryClient.cancelQueries(memberKeys.all);
      const previousMemberList = queryClient.getQueryData<Member[]>(
        memberKeys.all
      );

      if (previousMemberList) {
        queryClient.setQueryData<Member[]>(memberKeys.all, data => {
          if (!data) return [];

          const updatedIndex = data.findIndex(
            member => member.id === props.userId
          );

          if (updatedIndex < 0) return data;

          const newData = [...data];
          newData[updatedIndex] = {
            ...newData[updatedIndex],
            ...invitation,
          };
          return newData;
        });
      }
    },
  });

  const deleteMember = useMutation<
    InvitationResponse | User,
    InviteError,
    string
  >(deleteAction, {
    onMutate: async () => {
      props.onClose();
      await queryClient.cancelQueries(memberKeys.all);
      const previousMemberList = queryClient.getQueryData<Member[]>(
        memberKeys.all
      );
      const previousSubscription =
        queryClient.getQueryData<SubscriptionDetails>('subscription-details');
      const previousMemberCount =
        queryClient.getQueryData<MemberCount>('member-count');

      if (previousSubscription) {
        const newSubscription: SubscriptionDetails = {
          ...previousSubscription,
          seats: {
            ...previousSubscription.seats,
            used: previousSubscription.seats.used - 1,
          },
        };

        queryClient.setQueryData<SubscriptionDetails>(
          'subscription-details',
          newSubscription
        );
      }

      if (previousMemberCount) {
        queryClient.setQueryData<MemberCount | undefined>(
          'member-count',
          data => {
            if (!data) return;

            return {
              used: data.used - 1,
              nbOrganisationSeats: data.nbOrganisationSeats,
            };
          }
        );
      }

      if (previousMemberList) {
        queryClient.setQueryData<Member[]>(memberKeys.all, data => {
          if (!data) return [];

          return data.filter(member => member.id !== props.userId);
        });
      }

      return { previousMemberList, previousSubscription, previousMemberCount };
    },
    onSuccess: () => {
      toast.success('The user has been successfully deleted.');
    },
    onError: (err, variables, data: any) => {
      toast.error('Delete user failed. Try again!');
      if (data?.previousMemberList) {
        queryClient.setQueryData(memberKeys.all, data.previousMemberList);
      }

      if (data?.previousSubscription) {
        queryClient.setQueryData(
          'subscription-details',
          data.previousSubscription
        );
      }

      if (data?.previousMemberCount) {
        queryClient.setQueryData('member-count', data.previousMemberCount);
      }
    },
  });

  const handleOnSubmit = async (
    values: EditUserValues,
    formikBag: FormikHelpers<EditUserValues>
  ): Promise<void> => {
    try {
      await updateMember.mutateAsync(values);
    } catch (e) {
      toast.error('Something went wrong');
      const error = e as InviteError;

      switch (error.code) {
        case 'users/cannot-change-user-role':
          formikBag.setFieldError('role', 'You cannot change your role.');
          break;
        case 'invites/user-already-exists':
        case 'invites/email-already-invited':
          formikBag.setFieldError('email', error.detail);
          break;
      }
    }
  };

  const handleUserDelete = async () => {
    //dont let me delete myself. redirect to the profile
    if (member.data?.id === user.data?.id) {
      history.push('/account#delete-account');
      return;
    }
    await deleteMember.mutateAsync(member.data?.id || '');
  };

  const initialValues: EditUserValues = {
    firstName: member.data?.firstName || '',
    lastName: member.data?.lastName || '',
    email: member.data?.email || '',
    mobileNumber: member.data?.mobileNumber || '',
    role: member.data?.role || 'MEMBER',
    fullTimeEquivalent: member.data?.fullTimeEquivalent ?? 1.0,
    projects: member.data?.projects || [],
  };

  return (
    <Drawer open={props.open} onClose={props.onClose}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleOnSubmit}
      >
        {({ isSubmitting, dirty }: FormikProps<EditUserValues>) => (
          <Drawer.FormContainer
            open={props.open}
            onClose={props.onClose}
            title="Edit Details"
            description="Update User"
            Footer={
              <>
                <button
                  className="btn btn-white"
                  onClick={props.onClose}
                  disabled={isSubmitting}
                  type="button"
                >
                  Cancel
                </button>
                {/*OWNER user cannot be deleted. hide the button*/}
                {member.data?.role !== 'OWNER' ? (
                  <button
                    className="btn btn-delete capitalize ml-4"
                    disabled={isSubmitting}
                    type="button"
                    onClick={handleUserDelete}
                  >
                    Delete {member.data?.type.toLowerCase()}
                  </button>
                ) : null}
                <button
                  className="btn btn-primary ml-4 capitalize"
                  type="submit"
                  disabled={isSubmitting || !dirty}
                >
                  {isSubmitting ? (
                    <>
                      <Spinner
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      Loading
                    </>
                  ) : (
                    `Update ${member.data?.type.toLowerCase()}`
                  )}
                </button>
              </>
            }
          >
            <UserForm
              isSubmitting={isSubmitting}
              userType={member.data?.type}
            />
          </Drawer.FormContainer>
        )}
      </Formik>
    </Drawer>
  );
};

export default EditUser;
