import React, { useEffect } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import { XCircleIcon } from '@heroicons/react/outline';

import authService from '../services/authService';
import authValidationImage from '../assets/auth_validation_undraw.svg';
import AuthLayoutHeader from '../components/auth/AuthLayoutHeader';
import Spinner from '../Spinner';

const EmailValidated: React.FC = () => {
  const query = new URLSearchParams(useLocation().search);
  const oobCode = query.get('oobCode');
  const { mutateAsync, isLoading, isError } = useMutation(
    authService.verifyEmail
  );

  useEffect(() => {
    if (!oobCode) return;
    const mutation = async () => {
      try {
        await mutateAsync(oobCode);
      } catch {}
    };

    mutation();
  }, [oobCode, mutateAsync]);

  if (!oobCode) {
    return <Redirect to="/auth/login" />;
  }

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <Spinner className="mt-24 h-12 w-12 text-secondary-600" />
      </div>
    );
  }

  if (isError) {
    return (
      <div>
        <AuthLayoutHeader />
        <img
          src={authValidationImage}
          alt="Email validated"
          className="mb-8 mt-12"
        />

        <div className="rounded-md bg-red-50 p-4 mb-8">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                Your email is not verified
              </h3>
              <p className="mt-2 text-sm text-red-700">
                Your verification code may have expired. Request a new
                verification e-mail.
              </p>
            </div>
          </div>
        </div>

        <Link to="/auth/login" className="btn btn-primary btn-full">
          Login
        </Link>
      </div>
    );
  }

  return (
    <div>
      <AuthLayoutHeader />
      <img
        src={authValidationImage}
        alt="Email validated"
        className="mb-8 mt-12"
      />
      <h1 className="mb-2 text-lg text-gray-900">Email validated</h1>
      <p className="text-sm text-gray-500 mb-6">
        We have successfully validated your email. You may now login and
        continue setting up your account.
      </p>

      <Link to="/auth/login" className="btn btn-primary btn-full">
        Login
      </Link>
    </div>
  );
};

export default EmailValidated;
