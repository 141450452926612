import React from 'react';
import { Redirect } from 'react-router-dom';
import { useUser } from '../../hooks/useUser';

const GuestGuard: React.FC = props => {
  const user = useUser();

  if (user.data) {
    return <Redirect to="/" />;
  }

  return <>{props.children}</>;
};

export default GuestGuard;
