import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import Spinner from '../../Spinner';
import ErrorPage from '../ErrorPage';
import FlexibleContainer from '../StrategicPlanner/FlexibleContainer';
import DeleteButton from '../DeleteButton';
import MobileSectionNavigation from '../StrategicPlanner/MobileSectionNavigation';
import {
  useActualSectionName,
  useNextSection,
  usePreviousSection,
  useSPSections,
} from '../../hooks/useSPSections';
import { useSPQuestions } from '../../hooks/useSPQuestions';
import strategicPlanService from '../../services/strategicPlanService';
import { brandKeys, strategicPlannerKeys } from '../../config';
import { StrategicPlan as StrategicPlanType } from '../../types/strategic-plan';
import EditOverview from '../StrategicPlanner/Flexibles/EditOverview';

type PlanContainerProps = {
  plan: StrategicPlanType;
};

const PlanContainer: React.FC<PlanContainerProps> = props => {
  const { sectionId, SPId } = useParams<{ sectionId: string; SPId: string }>();
  const queryClient = useQueryClient();
  const history = useHistory();
  const sections = useSPSections();
  const actualSection = useActualSectionName();
  const nextSectionUrl = useNextSection();

  const questions = useSPQuestions();
  const previousSection = usePreviousSection();

  const deletePlan = useMutation(() => strategicPlanService.deletePlan(SPId), {
    onSuccess: async deletedPlan => {
      await queryClient.cancelQueries(
        strategicPlannerKeys.plans({
          brandId: deletedPlan.brand.id,
          organisationId: deletedPlan.organisation.id,
        })
      );

      const previousPlanList = queryClient.getQueryData<StrategicPlanType[]>(
        strategicPlannerKeys.plans({
          brandId: deletedPlan.brand.id,
          organisationId: deletedPlan.organisation.id,
        })
      );

      if (previousPlanList) {
        queryClient.setQueryData(
          strategicPlannerKeys.plans({
            brandId: deletedPlan.brand.id,
            organisationId: deletedPlan.organisation.id,
          }),
          previousPlanList.filter(plan => plan.id !== deletedPlan.id)
        );
      }

      // update brand 'planCount'
      queryClient.invalidateQueries(brandKeys.all);

      history.push(`/strategic-planning/${deletedPlan.brand.id}`);
    },
  });

  const handleDelete = async () => {
    try {
      await toast.promise(deletePlan.mutateAsync(), {
        loading: 'Deleting the plan..',
        success: (
          <span>
            The plan has been <b>successfully</b> deleted!
          </span>
        ),
        error: (
          <span>
            <b>Error</b> occurred during the delete operation.
          </span>
        ),
      });
    } catch (e) {
      //
    }
  };

  if (sections.isLoading || questions.isLoading) {
    return (
      <main className="px-4 lg:px-8 flex-grow">
        <div className="flex pt-5 md:pt-10 pb-5 md:pb-7 justify-center">
          <Spinner className="w-8 h-8 text-secondary-800" />
        </div>
      </main>
    );
  }

  return (
    <main className="sm:px-4 lg:px-8 flex-grow">
      {/*Desktop heading*/}
      <div className="flex-grow hidden md:flex flex-col sm:flex-row sm:items-center justify-between pt-5 md:pt-10 pb-5 md:pb-7 border-b border-gray-200">
        <div>
          <p className="text-lg leading-6 font-medium text-gray-900">
            {props.plan.name}
          </p>
          <div className="space-x-2">
            {props.plan.project ? (
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-secondary-50 text-secondary-800">
                {props.plan.project?.name}
              </span>
            ) : null}
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-secondary-50 text-secondary-800">
              {props.plan.brand?.name}
            </span>
          </div>
        </div>
        <div>
          <DeleteButton
            modalTitle="Delete plan"
            modalText="Once you delete this plan, you will lose all data associated with it. This action is irreversible."
            className="btn btn-delete capitalize sm:ml-4 mt-4 sm:mt-0"
            disabled={deletePlan.isLoading}
            type="button"
            onClick={handleDelete}
          >
            {deletePlan.isLoading ? 'Deleting' : 'Delete'}
          </DeleteButton>
        </div>
      </div>
      {/*Mobile heading*/}
      <div className="bg-white md:hidden flex divide-x">
        <div className="px-4 py-3">
          <p className="text-sm leading-5 font-normal text-gray-900">
            {props.plan.name}
          </p>
        </div>
        <div className="ml-auto px-4 flex items-center">
          <MobileSectionNavigation actualSection={actualSection} />
        </div>
      </div>

      {actualSection ? (
        <div className="px-4 sm:px-0">
          <p className="text-gray-700 text-lg leading-6 font-normal py-6">
            {actualSection}
          </p>
        </div>
      ) : null}
      {!questions.data || !sections.data ? (
        sectionId === 'overview' ? (
          <EditOverview />
        ) : (
          <ErrorPage
            title="Something went wrong!"
            text="We keep track of these errors, but feel free to contact us if refreshing doesn't fix things."
            subtitle="500 Error"
          />
        )
      ) : (
        <FlexibleContainer
          plan={props.plan}
          questions={questions.data}
          sections={sections.data}
          nextSection={nextSectionUrl || ''}
          previousSection={previousSection || ''}
        />
      )}
    </main>
  );
};

export default PlanContainer;
