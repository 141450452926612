import { doc, getDoc } from 'firebase/firestore';
import { useQuery } from 'react-query';
import { db } from '../utils/firebase';

export type MaintenanceDocument = {
  id: string;
  isMaintenanceModeOn?: boolean;
};

export function useMaintenance() {
  return useQuery<MaintenanceDocument>(
    'maintenance',
    async () => {
      const docRef = doc(db, 'maintenance', 'maintenance');

      const maintenanceDoc = await getDoc(docRef);

      return {
        id: maintenanceDoc.id,
        ...maintenanceDoc.data(),
      } as MaintenanceDocument;
    },
    {
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      refetchInterval: 1000 * 60, // 60 seconds
    }
  );
}
