import React, { Fragment, useRef } from 'react';
import * as Yup from 'yup';
import { Dialog, Transition } from '@headlessui/react';
import { CogIcon } from '@heroicons/react/outline';
import { FastField, Field, Form, Formik, FormikProps } from 'formik';
import { useMutation, useQueryClient } from 'react-query';
import toast from 'react-hot-toast';

import { SettingsDTO } from '../../types/resource-planner';
import { useSettings } from '../../hooks/useSettings';
import resourcePlannerService from '../../services/resourcePlannerService';

import FormikInput from '../FormikInput';
import UnitRadio from './UnitRadio';
import { resourcePlannerKeys } from '../../config';

type SettingsModalProps = {
  open: boolean;
  onClose: () => void;
};

const schema = Yup.object().shape({
  unitOfTime: Yup.string().required(),
  workingHoursPerDay: Yup.string().when('unitOfTime', {
    is: 'DAYS',
    then: Yup.string().required('This is a required field!'),
  }),
});

const SettingsModal: React.FC<SettingsModalProps> = props => {
  const cancelButtonRef = useRef(null);
  const settings = useSettings();
  const queryClient = useQueryClient();
  const updateSettings = useMutation(resourcePlannerService.updateSettings, {
    onSuccess: response => {
      queryClient.setQueryData(resourcePlannerKeys.settings, response);
      props.onClose();
    },
    onError: () => {
      toast.error('Something went wrong. Please try again!');
    },
  });

  const initialValues: SettingsDTO = {
    unitOfTime: settings.data?.unitOfTime || 'HOURS',
    workingHoursPerDay: settings.data?.workingHoursPerDay?.toString() || '7.5',
  };

  const handleOnSubmit = async (values: SettingsDTO) => {
    try {
      await updateSettings.mutateAsync({
        unitOfTime: values.unitOfTime,
        workingHoursPerDay: values.workingHoursPerDay,
      });
    } catch (e) {
      //
    }
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-40 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={props.onClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={schema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={handleOnSubmit}
              >
                {({
                  isSubmitting,
                  dirty,
                  values,
                  errors,
                }: FormikProps<SettingsDTO>) => (
                  <Form>
                    <Dialog.Title className="text-lg text-gray-900 leading-6 font-medium flex items-center">
                      <CogIcon className="w-10 h-10 mr-4" /> Resource Planner
                      Settings
                    </Dialog.Title>
                    <div className="space-y-8 mt-8">
                      <Field name="unitOfTime" component={UnitRadio} />
                      {values.unitOfTime === 'DAYS' ? (
                        <FastField
                          type="tel"
                          label="Working hours in a day"
                          name="workingHoursPerDay"
                          component={FormikInput}
                        />
                      ) : null}
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmitting || !dirty}
                      >
                        Apply
                      </button>
                      <button
                        type="button"
                        className="btn btn-white sm:mr-3"
                        onClick={props.onClose}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SettingsModal;
