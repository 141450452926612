import React from 'react';

import { ResourcePlanner } from '../../types/resource-planner';
import { useSettings } from '../../hooks/useSettings';

import PercentageBadge from './PercentageBadge';
import { displayHoursOrDays } from '../../utils/helper';

type TotalRowProps = {
  data: ResourcePlanner;
};

const TotalRow: React.FC<TotalRowProps> = props => {
  const settings = useSettings();
  const rp = props.data;

  return (
    <div className="border-gray-200 border-r border-b border-l sm:flex">
      <div
        style={{
          width: 180,
        }}
        className="sm:border-r border-gray-200 p-4"
      >
        <div className="flex items-center">
          <span className="text-xs leading-6 font-bold text-gray-900 mr-1">
            Total
          </span>
          <span className="text-sm text-primary-600 leading-6 font-bold">
            {
              displayHoursOrDays(
                settings,
                rp.organisationMembersSummary.hoursAllocated
              ).value
            }
            {
              displayHoursOrDays(
                settings,
                rp.organisationMembersSummary.hoursAllocated
              ).unit
            }
          </span>
          <span className="text-xs leading-5 font-medium text-gray-500 mx-1">
            of
          </span>
          <span className="text-sm text-primary-600 leading-6 font-bold">
            {
              displayHoursOrDays(
                settings,
                rp.organisationMembersSummary.hoursAvailable
              ).value
            }
            {
              displayHoursOrDays(
                settings,
                rp.organisationMembersSummary.hoursAvailable
              ).unit
            }
          </span>
          <PercentageBadge
            percentage={
              rp.organisationMembersSummary.hoursAvailable
                ? (rp.organisationMembersSummary.hoursAllocated /
                    rp.organisationMembersSummary.hoursAvailable) *
                    100 ?? 0
                : 0
            }
            className="ml-auto"
          />
        </div>
      </div>
      <div className="flex p-4 justify-end flex-1">
        <div className="flex items-center">
          <span className="text-base leading-6 font-bold text-gray-900 mr-2">
            Total
          </span>
          <span className="text-primary-600 text-2xl leading-6 font-bold">
            {
              displayHoursOrDays(settings, rp.projectsSummary.hoursAllocated)
                .value
            }
            {
              displayHoursOrDays(settings, rp.projectsSummary.hoursAllocated)
                .unit
            }
          </span>
          <span className="text-sm leading-5 font-medium text-gray-500 mx-2">
            of
          </span>
          <span className="text-primary-600 text-2xl leading-6 font-bold">
            {
              displayHoursOrDays(settings, rp.projectsSummary.hoursAvailable)
                .value
            }
            {
              displayHoursOrDays(settings, rp.projectsSummary.hoursAvailable)
                .unit
            }
          </span>
          <PercentageBadge
            percentage={
              rp.projectsSummary.hoursAvailable
                ? (rp.projectsSummary.hoursAllocated /
                    rp.projectsSummary.hoursAvailable) *
                    100 ?? 0
                : 0
            }
            className="ml-3"
          />
        </div>
      </div>
    </div>
  );
};

export default TotalRow;
