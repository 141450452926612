import { format, isSameDay, parseISO } from 'date-fns';
import { UseQueryResult } from 'react-query';
import { Settings } from '../types/resource-planner';

export const formatStringDate = (
  dateString?: string | null,
  empty: string = ''
) => {
  if (!dateString) return empty;

  return format(parseISO(dateString), 'dd/MM/yy');
};

export const getDeadlineClass = (dateString: string): string => {
  const now = new Date();
  if (isSameDay(parseISO(dateString), now)) {
    return 'bg-yellow-100 text-yellow-800';
  }
  if (parseISO(dateString) < now) {
    return 'bg-red-100 text-red-900';
  }

  return 'bg-green-100 text-green-800';
};

export const displayHoursOrDays = (
  settings: UseQueryResult<Settings, unknown>,
  value: number
) => {
  if (settings.isLoading || settings.isError || !settings.data) {
    return {
      unit: 'h',
      value: 0,
    };
  }

  if (settings.data.unitOfTime === 'DAYS') {
    return {
      unit: 'd',
      value: parseFloat((value / settings.data.workingHoursPerDay).toFixed(2)),
    };
  }

  return {
    unit: 'h',
    value: parseFloat(value.toFixed(2)),
  };
};
