import React, { useState } from 'react';

import { useBrand } from '../hooks/useBrands';
import { useIsAdmin } from '../hooks/useUser';

import DetailGroup from '../components/Brands/DetailGroup';
import Avatar from '../components/Avatar';
import EditBrandDrawer from '../components/Brands/EditBrandDrawer';
import { formatStringDate } from '../utils/helper';

const BrandOverview: React.FC = () => {
  const brand = useBrand();
  const [open, setOpen] = useState(false);
  const isAdmin = useIsAdmin();

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg max-w-screen-lg	mx-auto divide-y divide-gray-200">
      {/* Drawer to show when edit brand button is clicked*/}
      {!brand.isLoading && brand.data && isAdmin.data ? (
        <EditBrandDrawer
          open={open}
          onClose={() => setOpen(false)}
          data={brand.data}
        />
      ) : null}
      <div className="px-4 py-5 sm:px-6 flex items-center">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {brand.isLoading ? (
              <p className="h-6 w-48 animate-pulse bg-gray-300 rounded" />
            ) : (
              brand.data?.name
            )}
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">Overview</p>
        </div>
        {isAdmin.data ? (
          <div className="ml-auto">
            <button
              className="btn btn-primary"
              onClick={() => setOpen(true)}
              disabled={brand.isLoading}
            >
              Edit
            </button>
          </div>
        ) : null}
      </div>
      <DetailGroup name="Brand Management" description="Internal details">
        <DetailGroup.Item
          name="Project Manager"
          value={
            brand.data?.projectManager ? (
              <div className="flex items-center">
                <Avatar
                  className="w-5 h-5 mr-3 text-xs"
                  firstName={brand.data?.projectManager?.firstName}
                  lastName={brand.data?.projectManager?.firstName}
                />
                {brand.data?.projectManager?.firstName}{' '}
                {brand.data?.projectManager?.lastName}
              </div>
            ) : (
              ''
            )
          }
        />
        <DetailGroup.Item
          name="Contract End Date"
          value={formatStringDate(brand.data?.contractEnd, '-')}
        />
      </DetailGroup>
      <DetailGroup
        name="Key Contact"
        description="Contact details for primary Brand contact."
      >
        <DetailGroup.Item
          name="Contact Name"
          value={brand.data?.keyContact?.fullName}
        />
        <DetailGroup.Item
          name="Contact Number"
          value={brand.data?.keyContact?.phoneNumber}
        />
        <DetailGroup.Item
          name="Contact Email"
          value={brand.data?.keyContact?.email}
        />
      </DetailGroup>
      <DetailGroup
        name="Billing Contact"
        description="Contact details for Brand billing."
      >
        <DetailGroup.Item
          name="Contact Name"
          value={brand.data?.billingContact?.fullName}
        />
        <DetailGroup.Item
          name="Contact Number"
          value={brand.data?.billingContact?.phoneNumber}
        />
        <DetailGroup.Item
          name="Contact Email"
          value={brand.data?.billingContact?.email}
        />
        <DetailGroup.Item
          name="Billing Instructions"
          value={brand.data?.billingInstructions}
        />
      </DetailGroup>
    </div>
  );
};

export default BrandOverview;
