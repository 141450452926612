import React from 'react';
import { FastField, Field } from 'formik';
import FormikInput from '../FormikInput';
import FormikTextarea from '../FormikTextarea';
import FormikCombobox from '../FormikCombobox';
import Avatar from '../Avatar';
import { User } from '../../types/user';

type ProjectFormProps = {
  isSubmitting: boolean;
  users: User[];
};

const ProjectForm: React.FC<ProjectFormProps> = props => {
  return (
    <div className="px-4 divide-y divide-gray-200 sm:px-6">
      <fieldset className="space-y-6 pt-6 pb-5" disabled={props.isSubmitting}>
        <div className="flex items-center">
          <label className="text-base leading-6 font-semibold pb-0">
            Overview
          </label>
        </div>
        <FastField
          type="text"
          name="name"
          component={FormikInput}
          label="Project Name*"
          placeholder="My Great project"
          autoFocus
        />
        <FastField
          type="text"
          name="projectManager"
          component={FormikCombobox}
          label="Project Manager*"
          placeholder="Start typing to select user "
          data={props.users}
          itemToString={(user: User) =>
            user ? `${user.firstName} ${user.lastName}` : ''
          }
          renderItem={(user: User) => (
            <div className="flex items-center">
              <Avatar
                firstName={user.firstName}
                lastName={user.lastName}
                className="flex-shrink-0 h-6 w-6 rounded-full"
              />
              <span className="ml-3 block truncate">
                {`${user.firstName} ${user.lastName}`}
              </span>
            </div>
          )}
        />
        <FastField
          type="date"
          name="projectEndDate"
          component={FormikInput}
          label="Project End Date"
          placeholder="dd/mm/yy"
          max="9999-12-30"
          style={{ height: 38 }}
        />
        <div className="relative flex items-start">
          <div className="flex items-center h-5">
            <Field
              id="is_secret"
              aria-describedby="is_secret-description"
              name="isSecret"
              type="checkbox"
              className="form-checkbox focus:ring-secondary-700 h-4 w-4 text-secondary-700 border-gray-300 rounded cursor-pointer"
            />
          </div>
          <div className="ml-3 text-sm">
            <label
              htmlFor="is_secret"
              className="font-medium text-gray-700 cursor-pointer"
            >
              Private Project
            </label>
            <p id="comments-description" className="text-gray-500">
              Users need to be manually assigned to see this project.
            </p>
          </div>
        </div>
      </fieldset>
      <fieldset className="space-y-6 pt-6 pb-5" disabled={props.isSubmitting}>
        <div>
          <label className="text-base leading-6 font-semibold pb-0">
            Key Contact
          </label>
          <p className="text-sm text-gray-500">
            Optional, if contact is different from the primary Brand
          </p>
        </div>
        <FastField
          type="text"
          name="keyContact.fullName"
          component={FormikInput}
          label="Contact Name"
          placeholder="Contact Name"
        />
        <FastField
          type="email"
          name="keyContact.email"
          component={FormikInput}
          label="Contact Email"
          placeholder="tim@apple.com"
        />
        <FastField
          type="tel"
          name="keyContact.phoneNumber"
          component={FormikInput}
          label="Contact Number"
          placeholder="0123456789"
        />
      </fieldset>
      <fieldset className="space-y-6 pt-6 pb-5" disabled={props.isSubmitting}>
        <div>
          <label className="text-base leading-6 font-semibold pb-0">
            Billing Contact
          </label>
          <p className="text-sm text-gray-500">
            Optional, if contact is different from the primary Brand
          </p>
        </div>
        <FastField
          type="text"
          name="billingContact.fullName"
          component={FormikInput}
          label="Contact Name"
          placeholder="Contact Name"
        />
        <FastField
          type="email"
          name="billingContact.email"
          component={FormikInput}
          label="Contact Email"
          placeholder="tim@apple.com"
        />
        <FastField
          type="tel"
          name="billingContact.phoneNumber"
          component={FormikInput}
          label="Contact Number"
          placeholder="0123456789"
        />
        <FastField
          name="billingInstructions"
          component={FormikTextarea}
          label="Billing Instructions"
        />
      </fieldset>
    </div>
  );
};

export default ProjectForm;
