import React from 'react';

const TCSFooter: React.FC = () => {
  return (
    <p className="text-sm text-center text-gray-600 mt-12">
      By registering you agree to our{' '}
      <a href="/" className="text-link">
        Terms & Conditions
      </a>{' '}
      and{' '}
      <a
        href="https://firebasestorage.googleapis.com/v0/b/rhubarb-prod.appspot.com/o/rhubarb_privacy_policy.pdf?alt=media&token=eab22a84-262e-4931-b763-ef96baa7117f"
        className="text-link"
        target="_blank"
        rel="noreferrer"
      >
        Privacy Policy
      </a>
    </p>
  );
};

export default TCSFooter;
