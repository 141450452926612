import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { useUser } from '../../hooks/useUser';

const AuthGuard: React.FC = props => {
  const user = useUser();
  const location = useLocation();

  if (!user.data) return <Redirect to="/auth/login" />;

  if (user.data?.organisation?.subscriptionStatus === 'INCOMPLETE') {
    return <Redirect to="/auth/checkout" />;
  }

  if (
    user.data?.organisation?.subscriptionStatus === 'ACTIVE' ||
    user.data?.organisation?.subscriptionStatus === 'TRIALING' ||
    location.pathname === '/settings/billing' ||
    location.pathname === '/settings/organisation'
  ) {
    return <>{props.children}</>;
  }

  return <Redirect to="/settings/billing" />;
};

export default AuthGuard;
