import { useQuery } from 'react-query';

import organisationService from '../services/organisationService';
import { OrganisationError, SubscriptionDetails } from '../types/organisation';

export function useSubscription() {
  return useQuery<SubscriptionDetails, OrganisationError>(
    'subscription-details',
    organisationService.getSubscriptionDetails
  );
}
