import clsx from 'clsx';
import React from 'react';

import { displayHoursOrDays } from '../../utils/helper';
import { useSettings } from '../../hooks/useSettings';
import { usePermission } from '../../hooks/usePermission';

import PercentageBadge from './PercentageBadge';

type HeadingInputProps = {
  total: number;
  value: number;
  onChange: (newValue: string) => void;
  disabled: boolean;
  isBig?: boolean;
};

const HeadingInput: React.FC<HeadingInputProps> = props => {
  const settings = useSettings();
  const permission = usePermission();
  const canEdit = permission.data?.isAdmin || permission.data?.isMember;
  const percentage = props.value
    ? Math.round((props.total / props.value) * 100)
    : 0;

  const handleOnChange = (e: React.ChangeEvent<HTMLFormElement>) => {
    const value =
      settings.data?.unitOfTime === 'DAYS'
        ? parseFloat(e.target.value) * settings.data.workingHoursPerDay
        : e.target.value;

    props.onChange(value.toString());
  };

  const handleOnSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    const target = e.target as typeof e.target & {
      headingInput: { value: string };
    };

    const value =
      settings.data?.unitOfTime === 'DAYS'
        ? parseFloat(target.headingInput?.value) *
          settings.data.workingHoursPerDay
        : target.headingInput?.value;

    props.onChange(value.toString());
  };

  const totalTimeAndUnit = displayHoursOrDays(settings, props.total);
  const valueTimeAndUnit = displayHoursOrDays(settings, props.value);

  return (
    <form
      className="flex items-center justify-between mt-auto"
      onSubmit={handleOnSubmit}
      onBlur={handleOnChange}
      noValidate
    >
      <div className="flex items-center">
        <span
          className={clsx(
            'text-secondary-600 leading-5 font-semibold',
            props.isBig ? 'text-md' : 'text-sm'
          )}
        >
          {parseFloat(totalTimeAndUnit.value.toFixed(1))}
          {totalTimeAndUnit.unit}
        </span>
        <span className="text-sm leading-5 font-medium text-gray-500 mx-1">
          of
        </span>
        <input
          key={valueTimeAndUnit.value}
          type="number"
          onWheel={e => {
            if (e.target instanceof HTMLElement) {
              e.target.blur();
            }
          }}
          className="input p-1 w-12 text-center text-gray-500 mr-2 text-sm"
          defaultValue={valueTimeAndUnit.value}
          name="headingInput"
          disabled={props.disabled || !canEdit}
        />
      </div>
      <PercentageBadge percentage={percentage} />
    </form>
  );
};

export default HeadingInput;
