import React from 'react';

type FilterBadgeProps = {
  label: string;
  onRemove: () => void;
};

const FilterBadge: React.FC<FilterBadgeProps> = props => {
  return (
    <span className="inline-flex items-center py-0.5 pl-2 pr-0.5 mr-1 rounded-full text-xs font-medium bg-secondary-50 text-secondary-800">
      {props.label}
      <button
        type="button"
        className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-secondary-800 hover:bg-secondary-100 hover:text-secondary-800 focus:outline-none focus:bg-secondary-800 focus:text-secondary-50"
        onClick={props.onRemove}
      >
        <span className="sr-only">Remove from filter</span>
        <svg
          className="h-2 w-2"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 8 8"
        >
          <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
        </svg>
      </button>
    </span>
  );
};

export default FilterBadge;
