import React from 'react';

const CardSkeleton: React.FC = () => {
  return (
    <div className="flex animate-pulse flex-col h-full justify-center space-y-2">
      <div className="w-24 bg-gray-300 h-4 rounded" />
      <div className="w-full bg-gray-200 h-12 rounded" />
      <div className="flex space-x-5">
        <div className="w-1/2 space-y-2">
          <div className="w-24 bg-gray-300 h-4 rounded" />
          <div className="w-full bg-gray-200 h-12 rounded" />
        </div>
        <div className="w-1/2 space-y-2">
          <div className="w-24 bg-gray-300 h-4 rounded" />
          <div className="w-full bg-gray-200 h-12 rounded" />
        </div>
      </div>
      <div className="w-24 bg-gray-300 h-4 rounded" />
      <div className="w-full bg-gray-200 h-12 rounded" />
    </div>
  );
};

export default CardSkeleton;
