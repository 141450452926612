import { FastField, Field, FieldArray } from 'formik';
import React from 'react';
import { useBrands } from '../../hooks/useBrands';
import { useProjects } from '../../hooks/useProjects';
import FormikInput from '../FormikInput';
import FormikSelect from '../FormikSelect';
import ProjectSelectList from './ProjectSelectList';

type UserFormProps = {
  isSubmitting: boolean;
  userType?: 'INVITE' | 'USER';
};

const UserForm: React.FC<UserFormProps> = props => {
  const projects = useProjects();
  const brands = useBrands();

  return (
    <div className="px-4 divide-y divide-gray-200 sm:px-6 space-y-6">
      <fieldset className="space-y-6 pt-6 pb-5" disabled={props.isSubmitting}>
        <div className="flex items-center">
          <h2 className="text-base leading-6 font-semibold pb-0">Overview</h2>
          {props.userType === 'INVITE' ? (
            <span className="ml-auto inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
              Invited
            </span>
          ) : null}
          {props.userType === 'USER' ? (
            <span className="ml-auto inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-secondary-50 text-secondary-700">
              Active
            </span>
          ) : null}
        </div>
        <FastField
          type="text"
          name="firstName"
          component={FormikInput}
          label="First Name*"
          placeholder="John"
          autoFocus
        />
        <FastField
          type="text"
          name="lastName"
          component={FormikInput}
          label="Last Name"
          placeholder="Doe"
        />
        <FastField
          type="email"
          name="email"
          component={FormikInput}
          label="Email*"
          placeholder="you@example.com"
          disabled={!!props.userType} // if we have userType it means we are in editing mode, lets disable email edit
        />
        <FastField
          type="tel"
          name="mobileNumber"
          component={FormikInput}
          label="Phone Number"
          placeholder="+44 1234567"
        />
        <Field
          name="role"
          component={FormikSelect}
          label="Role*"
          options={[
            { name: 'Member', value: 'MEMBER' },
            { name: 'Viewer', value: 'VIEWER' },
            { name: 'Admin', value: 'ADMIN' },
          ]}
        />
        <FastField
          type="number"
          onWheel={(e: React.WheelEvent<HTMLInputElement>) => {
            if (e.target instanceof HTMLElement) {
              e.target.blur();
            }
          }}
          name="fullTimeEquivalent"
          component={FormikInput}
          label="Full Time Equivalent*"
          placeholder="1.0"
        />
      </fieldset>
      {projects.data && brands.data ? (
        <FieldArray
          name="projects"
          render={props => (
            <ProjectSelectList
              {...props}
              brands={brands.data}
              projects={projects.data}
            />
          )}
        />
      ) : null}
    </div>
  );
};

export default UserForm;
