import { useQuery, useQueryClient } from 'react-query';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { strategicPlannerKeys } from '../config';
import strategicPlanService from '../services/strategicPlanService';
import { StrategicPlan } from '../types/strategic-plan';
import { useUser } from './useUser';

export function usePlans() {
  const { brandId } = useParams<{ brandId: string }>();
  const user = useUser();
  const context = {
    brandId,
    organisationId: user.data?.organisation.id || '',
  };

  return useQuery(
    strategicPlannerKeys.plans(context),
    () => strategicPlanService.getPlansByBrand(context),
    {
      enabled: !user.isLoading && !!user.data,
      onError: () => {
        toast.error('Try again! Something went wrong.');
      },
    }
  );
}

export function usePlan() {
  const { SPId, brandId } = useParams<{ SPId: string; brandId: string }>();
  const queryClient = useQueryClient();
  const user = useUser();
  const context = {
    brandId,
    organisationId: user.data?.organisation.id || '',
  };

  return useQuery(
    strategicPlannerKeys.plan(SPId),
    () => strategicPlanService.getPlanByPlanId(SPId),
    {
      enabled: !!SPId,
      placeholderData: () => {
        const plans = queryClient.getQueryData<StrategicPlan[]>(
          strategicPlannerKeys.plans(context)
        );

        if (!plans) return undefined;

        return plans.find(plan => plan.id === SPId);
      },
      onError: () => {
        toast.error('Try again! Something went wrong.');
      },
    }
  );
}
