import React from 'react';

import { useBrands } from '../hooks/useBrands';
import DashboardMenu from './Menu/DashboardMenu';

const StrategicPlannerLayout: React.FC = props => {
  const brands = useBrands();

  if (brands.isLoading) return null;
  if (brands.isError || !brands.data) return <p>Error</p>;

  return (
    <div className="min-h-full bg-gray-50 flex flex-col">
      <div className="z-10 relative">
        <DashboardMenu />
      </div>
      {props.children}
    </div>
  );
};

export default StrategicPlannerLayout;
