import React, { useState } from 'react';
import { PaymentElement } from '@stripe/react-stripe-js';
import CardSkeleton from './CardSkeleton';

const CardDetails: React.FC = () => {
  const [loaded, setLoaded] = useState<boolean>(false);

  return (
    <div>
      <h3 className="text-lg font-medium text-gray-900 mb-3">
        Enter Payment Details
      </h3>
      <div style={{ minHeight: 220 }}>
        {loaded ? null : <CardSkeleton />}
        <PaymentElement
          onReady={() => setLoaded(true)}
          options={{
            terms: {
              card: 'never',
            },
            fields: {
              billingDetails: {
                address: {
                  country: 'auto',
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default CardDetails;
