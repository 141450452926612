import { useQuery } from 'react-query';
import toast from 'react-hot-toast';

import { Member } from '../types/organisation';
import organisationService from '../services/organisationService';
import { memberKeys } from '../config';

/**
 *
 * @param options Options object
 * @param {boolean} options.includeInvite Set true, if you want to include invites to the list
 * @param {Function} options.select Selector function for single item
 */
export function useMembers(options?: { includeInvite?: boolean }) {
  const includeInvite = options?.includeInvite ? 'all' : 'only-user';

  return useQuery<Member[]>(
    includeInvite ? memberKeys.all : memberKeys.onlyUser,
    organisationService.getTeamMembers,
    {
      select: data => {
        if (!options?.includeInvite) {
          return data.filter(member => {
            //include invites only if needed
            return !(!options?.includeInvite && member.type === 'INVITE');
          });
        }

        return data;
      },
      onError: () => {
        toast.error('Try again! Something went wrong.');
      },
    }
  );
}

/**
 *
 * @param id Select single user id
 */
export function useMember(id: string | null) {
  return useQuery<Member[], any, Member | undefined>(
    memberKeys.all,
    organisationService.getTeamMembers,
    {
      enabled: !!id,
      select: data => {
        return data.find(member => member.id === id);
      },
      onError: () => {
        toast.error('Try again! Something went wrong.');
      },
    }
  );
}
