import React from 'react';
import { Link } from 'react-router-dom';

import AuthLayoutHeader from '../components/auth/AuthLayoutHeader';
import SignUpForm from '../components/auth/SignUpForm';
import TCSFooter from '../components/auth/TCSFooter';

const SignUp: React.FC = () => {
  return (
    <>
      <AuthLayoutHeader
        title="Create Account"
        backButtonLink="/"
        backButtonText="Back to Homepage"
        subTitle={
          <p className="mt-2 text-sm text-gray-600">
            To{' '}
            <a href="/" className="font-medium text-link">
              start your 14-day free trial
            </a>
          </p>
        }
      />
      <div className="mt-6">
        <SignUpForm />
        <div className="relative py-6">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white text-gray-500">
              Already have an account?
            </span>
          </div>
        </div>
        <div>
          <Link to="/auth/login" className="btn btn-secondary btn-full">
            Sign in
          </Link>
        </div>
        <TCSFooter />
      </div>
    </>
  );
};

export default SignUp;
