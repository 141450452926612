import React from 'react';
import { FieldProps, getIn } from 'formik';

const FormikCheckbox: React.ComponentType<FieldProps & { label: string }> = ({
  field,
  form,
  label,
  ...rest
}) => {
  const error = getIn(form.errors, field.name);
  const isTouched = getIn(form.touched, field.name);
  const hasError = isTouched && error;

  return (
    <div className="relative flex items-start">
      <label
        htmlFor={field.name}
        className="text-gray-700 select-none text-sm cursor-pointer"
      >
        {label}
      </label>
      <div className="ml-auto flex items-center h-5">
        <input
          id={field.name}
          style={{ boxShadow: 'none' }}
          className="form-checkbox h-4 w-4 text-secondary-700 border-gray-300 rounded cursor-pointer focus:ring-0"
          type="checkbox"
          {...field}
          {...rest}
        />
      </div>
      {hasError ? (
        <p className="mt-1 text-xs text-red-900" id={`${field.name}-error`}>
          {error}
        </p>
      ) : null}
    </div>
  );
};

export default FormikCheckbox;
