import React from 'react';
import { Redirect } from 'react-router-dom';

import { usePermission } from '../../hooks/usePermission';
import LoadingScreen from '../LoadingScreen';

const MemberGuard: React.FC = props => {
  const permission = usePermission();

  if (permission.isLoading) {
    return <LoadingScreen />;
  }

  if (permission.data?.isMember || permission.data?.isAdmin) {
    return <>{props.children}</>;
  }

  return <Redirect to="/" />;
};

export default MemberGuard;
