import React, { useMemo, useState } from 'react';
import { FieldProps, getIn } from 'formik';
import clsx from 'clsx';
import { useCombobox } from 'downshift';
import { matchSorter } from 'match-sorter';
import { Transition } from '@headlessui/react';
import CheckIcon from '@heroicons/react/solid/CheckIcon';

import useDebounce from '../hooks/useDebouce';
import { SelectorIcon } from '@heroicons/react/solid';

type ComboboxTypes<DataItem extends {}> = {
  label: string;
  data: DataItem[];
  itemToString: (item: DataItem | null) => string;
  renderItem: (item: DataItem) => React.ReactElement;
  placeholder: string;
  /**
   * Can use this prop to specify which propery to select from an object
   * (in cases where you use the combobox to select from an array of objects
   *  but want the selected value to be of a property rather than the object)
   */
  objectPropertySelector?: string;
} & FieldProps;

/**
 * Use it with Formik Field/FastField only
 */
const Combobox = <DataItem extends {}>({
  field,
  form,
  label,
  data,
  itemToString,
  renderItem,
  placeholder,
  objectPropertySelector,
}: ComboboxTypes<DataItem>) => {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 0);

  const items = useMemo(
    () =>
      debouncedSearchTerm.trim() === ''
        ? data
        : matchSorter(data, debouncedSearchTerm, {
            keys: [item => itemToString(item)],
          }),
    [debouncedSearchTerm, data, itemToString]
  );

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
    reset,
    openMenu,
    closeMenu,
  } = useCombobox<DataItem>({
    items: items,
    itemToString: itemToString,
    initialSelectedItem: objectPropertySelector
      ? items.find(
          item =>
            item[objectPropertySelector as keyof typeof item] === field.value
        )
      : field.value,
    onInputValueChange: change => {
      setSearchTerm(change.inputValue || '');

      if (!change.inputValue) {
        form.setFieldValue(field.name, '');
        reset();
      }
    },
    onSelectedItemChange: change => {
      const { selectedItem } = change;

      let newValue;
      if (selectedItem) {
        if (objectPropertySelector) {
          newValue =
            selectedItem[objectPropertySelector as keyof typeof selectedItem] ||
            '';
        } else {
          newValue = selectedItem;
        }
      } else {
        newValue = '';
      }

      form.setFieldValue(field.name, newValue);
    },
  });

  const error = getIn(form.errors, field.name);
  const isTouched = getIn(form.touched, field.name);
  const hasError = isTouched && error;

  return (
    <div>
      <label
        className="block text-sm font-medium text-gray-700"
        {...getLabelProps()}
      >
        {label}
      </label>
      <div className="mt-1 relative">
        <div
          className={clsx({
            'input mt-1 relative': true,
            'input-error': hasError,
          })}
          {...getComboboxProps()}
        >
          <input
            className={clsx({
              'bg-white sm:text-sm border-0 focus:outline-none block w-full px-3 py-2 appearance-none rounded-md placeholder-gray-400 disabled:bg-gray-50 disabled:text-gray-400':
                true,
              'text-red-900 placeholder-red-300': hasError,
            })}
            placeholder={placeholder}
            {...getInputProps({
              onKeyDown: event => {
                if (event.key === 'Escape') {
                  event.stopPropagation();
                }
              },
              onFocus: e => {
                if (!e.target.value) {
                  if (!isOpen) {
                    openMenu();
                  }
                } else {
                  e.target.select();
                }
              },
            })}
          />
          <span
            className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
            role="button"
            onClick={() => {
              if (!isOpen) {
                setSearchTerm('');
                openMenu();
              } else {
                closeMenu();
              }
            }}
          >
            <SelectorIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </div>

        <div {...getMenuProps()}>
          <Transition
            show={isOpen}
            as="ul"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
          >
            {items
              .sort((a, b) => {
                if (itemToString(a) < itemToString(b)) return -1;
                if (itemToString(a) > itemToString(b)) return 1;
                return 0;
              })
              .map((item, index) => (
                <li
                  key={itemToString(item)}
                  className={clsx(
                    highlightedIndex === index
                      ? 'text-white bg-secondary-600'
                      : 'text-gray-900',
                    itemToString(selectedItem) === itemToString(item)
                      ? 'font-semibold'
                      : 'font-normal',
                    'cursor-pointer select-none relative py-2 pl-3 pr-9'
                  )}
                  value={item}
                  {...getItemProps({ item, index })}
                >
                  {renderItem(item)}
                  {itemToString(selectedItem) === itemToString(item) ? (
                    <span
                      className={clsx(
                        highlightedIndex === index
                          ? 'text-white'
                          : 'text-secondary-600',
                        'absolute inset-y-0 right-0 flex items-center pr-4'
                      )}
                    >
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  ) : null}
                </li>
              ))}
            {items.length < 1 ? (
              <p className="text-center py-2 text-gray-400">Not found</p>
            ) : null}
          </Transition>
        </div>
      </div>
      {hasError ? (
        <p className="mt-1 text-xs text-red-900" id={`${field.name}-error`}>
          {error}
        </p>
      ) : null}
    </div>
  );
};

export default Combobox;
