import React from 'react';
import { FastField, FieldArray, useFormikContext } from 'formik';
import { MinusCircleIcon, PlusIcon } from '@heroicons/react/solid';

import { FormikAnswer, Question } from '../../../types/strategic-plan';
import FormikInput from '../../FormikInput';
import QuestionContainer from '../QuestionContainer';

type PillarsProps = {
  index: number;
  question: Question;
};

const Pillars: React.FC<PillarsProps> = props => {
  const formik = useFormikContext<{
    questions: FormikAnswer[];
  }>();

  return (
    <QuestionContainer
      name={props.question.name}
      description={props.question.description}
    >
      <fieldset>
        <div className="grid gap-4 grid-cols-1">
          {/* Roof */}
          <FastField
            name={`questions[${props.index}].formData.roof`}
            type="text"
            component={FormikInput}
            placeholder="Title here"
            label="Roof"
          />

          {/* Pillars */}
          <FieldArray
            name={`questions[${props.index}].formData.pillars`}
            render={arrayHelpers => (
              <div className="grid gap-4 grid-cols-1">
                {formik.values.questions[props.index]?.formData?.pillars?.map(
                  (pillar: string, index: number) => (
                    <div key={index} className="flex items-end">
                      <div className="flex-1">
                        <FastField
                          name={`questions[${props.index}].formData.pillars[${index}]`}
                          type="text"
                          component={FormikInput}
                          placeholder="Title here"
                          label="Pillar"
                        />
                      </div>

                      <button
                        onClick={() => arrayHelpers.remove(index)}
                        type="button"
                        className="ml-2"
                      >
                        <MinusCircleIcon className="w-10 h-10  text-secondary-600 hover:text-secondary-900" />
                      </button>
                    </div>
                  )
                )}

                <div className="flex items-center">
                  <div className="border-gray-200 border-b w-full" />

                  <button
                    onClick={() => arrayHelpers.push('')}
                    type="button"
                    className="btn btn-white whitespace-nowrap"
                  >
                    <PlusIcon className="w-5 h-5  text-gray-900 mr-2" />
                    <span className="text-sm leading-5 font-medium">
                      Add another pillar
                    </span>
                  </button>
                </div>
              </div>
            )}
          />
        </div>
      </fieldset>
    </QuestionContainer>
  );
};

export default Pillars;
