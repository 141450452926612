import { useQuery } from 'react-query';

import authService from '../services/authService';
import { User } from '../types/user';

export function useUser() {
  return useQuery<User | null>('user', () => authService.getUser());
}

export function useIsAdmin() {
  return useQuery<User, any, boolean>('user', () => authService.getUser(), {
    select: data => {
      return data?.role === 'OWNER' || data?.role === 'ADMIN';
    },
  });
}

export function useIsOwner() {
  return useQuery<User, any, boolean>('user', () => authService.getUser(), {
    select: data => {
      return data?.role === 'OWNER';
    },
  });
}
