import React from 'react';
import { FieldProps } from 'formik';
import { RadioGroup } from '@headlessui/react';
import clsx from 'clsx';

const StatusRadio: React.ComponentType<FieldProps> = props => {
  return (
    <RadioGroup
      value={props.field.value}
      onChange={status => {
        props.form.setFieldValue(props.field.name, status);
      }}
    >
      <RadioGroup.Label className="sr-only">
        Choose a memory option
      </RadioGroup.Label>
      <div className="flex">
        <RadioGroup.Option
          value="TODO"
          className={({ checked }) =>
            clsx(
              checked
                ? 'bg-secondary-700 border-transparent text-white hover:bg-secondary-600'
                : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
              'rounded-l-md border py-2 px-3 flex items-center justify-center text-sm font-medium sm:flex-1 cursor-pointer focus:outline-none'
            )
          }
        >
          <RadioGroup.Label as="p">To Do</RadioGroup.Label>
        </RadioGroup.Option>
        <RadioGroup.Option
          value="IN_PROGRESS"
          className={({ checked }) =>
            clsx(
              checked
                ? 'bg-secondary-700 border-transparent text-white hover:bg-secondary-600'
                : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
              'border py-2 px-3 flex items-center justify-center text-sm font-medium sm:flex-1 cursor-pointer focus:outline-none'
            )
          }
        >
          <RadioGroup.Label as="p">In Progress</RadioGroup.Label>
        </RadioGroup.Option>
        <RadioGroup.Option
          value="DONE"
          className={({ checked }) =>
            clsx(
              checked
                ? 'bg-secondary-700 border-transparent text-white hover:bg-secondary-600'
                : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
              'rounded-r-md border py-2 px-3 flex items-center justify-center text-sm font-medium sm:flex-1 cursor-pointer focus:outline-none'
            )
          }
        >
          <RadioGroup.Label as="p">Done</RadioGroup.Label>
        </RadioGroup.Option>
      </div>
    </RadioGroup>
  );
};

export default StatusRadio;
