import React from 'react';
import { Redirect } from 'react-router-dom';

import { useUser } from '../../hooks/useUser';

const CheckoutGuard: React.FC = props => {
  const user = useUser();

  if (!user.data) return <Redirect to="/auth/login" />;

  if (user.data?.organisation?.subscriptionStatus === 'INCOMPLETE') {
    return <>{props.children}</>;
  }

  if (
    user.data?.organisation?.subscriptionStatus === 'ACTIVE' ||
    user.data?.organisation?.subscriptionStatus === 'TRIALING'
  ) {
    return <Redirect to="/" />;
  }

  //TODO: revisit if account pages are created
  return <Redirect to="/account/billing" />;
};

export default CheckoutGuard;
