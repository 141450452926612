import React from 'react';

import { Link } from 'react-router-dom';
import { HomeIcon } from '@heroicons/react/solid';

import DashboardMenu from './Menu/DashboardMenu';

const TaskTemplateLayout: React.FC = props => {
  return (
    <div className="min-h-full bg-gray-50">
      <DashboardMenu />
      <nav
        className="bg-white border-b border-t border-gray-200 flex mb-6 sm:mb-0"
        aria-label="Breadcrumb"
      >
        <ol className="max-w-screen-xl w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8">
          <li className="flex">
            <div className="flex items-center">
              <Link to="/" className="text-gray-400 hover:text-gray-500">
                <HomeIcon
                  className="flex-shrink-0 h-5 w-5"
                  aria-hidden="true"
                />
                <span className="sr-only">Home</span>
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="flex-shrink-0 w-6 h-full text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <Link
                to="/work-in-progress/to-do"
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                WIP
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="flex-shrink-0 w-6 h-full text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>

              <span className="ml-4 text-sm font-medium text-gray-500">
                Manage Templates
              </span>
            </div>
          </li>
        </ol>
      </nav>
      <main className="max-w-screen-xl mx-auto pb-10 sm:py-6 lg:px-8">
        {props.children}
      </main>
    </div>
  );
};

export default TaskTemplateLayout;
