import { Formik, FormikHelpers, FormikProps } from 'formik';
import React from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import * as Yup from 'yup';
import { memberKeys } from '../../config';
import authService from '../../services/authService';
import Spinner from '../../Spinner';
import {
  Member,
  MemberCount,
  SubscriptionDetails,
} from '../../types/organisation';
import { InvitationResponse, InviteError } from '../../types/sign-up';
import { NewUserValues } from '../../types/user';
import Drawer from '../Drawer';
import UserForm from './UserForm';

type AddUserProps = {
  open: boolean;
  onClose: () => void;
};

const schema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string(),
  email: Yup.string()
    .email('Must be a valid email')
    .required('Email is required'),
  mobileNumber: Yup.string(),
  role: Yup.string().required('Select user role'),
  fullTimeEquivalent: Yup.number().min(0).required(),
  projects: Yup.array(),
});

const initialValues: NewUserValues = {
  firstName: '',
  lastName: '',
  email: '',
  mobileNumber: '',
  role: 'MEMBER',
  fullTimeEquivalent: 1.0,
  projects: [],
};

const AddUser: React.FC<AddUserProps> = props => {
  const queryClient = useQueryClient();

  const addInvite = useMutation<InvitationResponse, InviteError, NewUserValues>(
    authService.createInvitation,
    {
      onSuccess: async invitation => {
        props.onClose();
        await queryClient.cancelQueries(memberKeys.all);
        const previousMemberList = queryClient.getQueryData<Member[]>(
          memberKeys.all
        );

        // update the member-count data dynamically
        const previousMemberCount =
          queryClient.getQueryData<MemberCount>('member-count');

        if (previousMemberCount) {
          queryClient.setQueryData<MemberCount | undefined>(
            'member-count',
            data => {
              if (!data) return;

              return {
                used: data.used + 1,
                nbOrganisationSeats: data.nbOrganisationSeats,
              };
            }
          );
        }

        queryClient.setQueryData<SubscriptionDetails | undefined>(
          'subscription-details',
          data => {
            if (!data) return;

            return {
              ...data,
              seats: {
                ...data.seats,
                used: data.seats.used + 1 || 0,
              },
            };
          }
        );

        if (previousMemberList) {
          queryClient.setQueryData<Member[]>(memberKeys.all, [
            ...previousMemberList,
            {
              ...invitation,
              lastName: invitation.lastName || '',
              mobileNumber: invitation.mobileNumber || '',
              blocked: false,
              isSubscribedToMarketing: false,
              type: 'INVITE',
              fullTimeEquivalent: 1.0,
              projects: [],
            },
          ]);
        }
      },
    }
  );

  const handleOnSubmit = async (
    values: NewUserValues,
    formikBag: FormikHelpers<NewUserValues>
  ): Promise<void> => {
    try {
      await addInvite.mutateAsync(values);
    } catch (e) {
      toast.error('Something went wrong');
      const error = e as InviteError;

      switch (error.code) {
        case 'invites/user-already-exists':
        case 'invites/email-already-invited':
          formikBag.setFieldError('email', error.detail);
          break;
      }
    }
  };

  return (
    <Drawer open={props.open} onClose={props.onClose}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleOnSubmit}
      >
        {({ isSubmitting, dirty }: FormikProps<NewUserValues>) => (
          <Drawer.FormContainer
            open={props.open}
            onClose={props.onClose}
            title="New user"
            description="Add new user to your organisation"
            Footer={
              <>
                <button
                  className="btn btn-white"
                  onClick={props.onClose}
                  disabled={isSubmitting}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary ml-4"
                  type="submit"
                  disabled={isSubmitting || !dirty}
                >
                  {isSubmitting ? (
                    <>
                      <Spinner
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      Loading
                    </>
                  ) : (
                    'Invite user'
                  )}
                </button>
              </>
            }
          >
            <UserForm isSubmitting={isSubmitting} />
          </Drawer.FormContainer>
        )}
      </Formik>
    </Drawer>
  );
};

export default AddUser;
