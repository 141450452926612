import React from 'react';

import BillingDetails from '../components/Settings/BillingDetails';
import Invoices from '../components/Settings/Invoices';
import ChangeSeatNumber from '../components/Settings/ChangeSeatNumber';
import PaymentFrequency from '../components/Settings/PaymentFrequency';
import ChangePayment from '../components/Settings/ChangePayment';

const Billing: React.FC = () => {
  return (
    <div>
      <h1 className="text-lg leading-6 font-medium mb-4 sm:mb-6 text-gray-900 ml-4 sm:ml-0">
        Overview
      </h1>
      <BillingDetails />
      <ChangePayment />
      <PaymentFrequency />
      <ChangeSeatNumber />
      <Invoices />
    </div>
  );
};

export default Billing;
