import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useMemo } from 'react';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useMutation } from 'react-query';

import checkoutService from '../../services/checkoutService';
import CardSkeleton from '../Checkout/CardSkeleton';
import ChangePaymentForm from './ChangePaymentForm';

type ChangePaymentModalProps = {
  open: boolean;
  onClose: () => void;
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '');

const ChangePaymentModal: React.FC<ChangePaymentModalProps> = props => {
  const { mutateAsync, isLoading, isIdle, data } = useMutation(
    checkoutService.setupIntent
  );

  useEffect(() => {
    if (!isIdle) return;

    const initIntent = async () => {
      await mutateAsync();
    };
    initIntent();
  }, [mutateAsync, isIdle]);

  const options: StripeElementsOptions = useMemo(
    () => ({
      clientSecret: data?.setupIntent?.clientSecret,
      appearance: {
        theme: 'stripe',
        variables: {
          colorPrimary: '#402B70',
          colorBackground: '#ffffff',
          colorText: '#374151',
          fontFamily: 'Inter, sans-serif',
          borderRadius: '6px',
        },
      },
    }),
    [data]
  );

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={props.onClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg p-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              {isLoading ? (
                <CardSkeleton />
              ) : (
                <Elements stripe={stripePromise} options={options}>
                  <ChangePaymentForm onClose={props.onClose} />
                </Elements>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ChangePaymentModal;
