import React from 'react';
import { Link } from 'react-router-dom';

import { StrategicPlan } from '../../types/strategic-plan';
import { formatStringDate } from '../../utils/helper';

import EditorLine from './EditorLine';

type PlanItemProps = {
  plan: StrategicPlan;
};

const PlanItem: React.FC<PlanItemProps> = props => {
  return (
    <div className="shadow rounded-lg p-4 bg-white flex-col">
      <h2 className="text-sm text-gray-900 leading-5 font-normal mb-6">
        {props.plan.name}
      </h2>
      {props.plan.project ? (
        <span className="mb-5 inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-pink-100 text-pink-800">
          {props.plan.project.name}
        </span>
      ) : null}

      <div className="space-y-3 mb-5">
        {/* Created by */}
        {props.plan.createdBy ? (
          <EditorLine
            text="Created"
            date={formatStringDate(props.plan.createdAt)}
            user={{
              firstName: props.plan.createdBy.firstName,
              lastName: props.plan.createdBy.lastName,
            }}
          />
        ) : null}

        {/* Updated by */}
        {props.plan.updatedBy ? (
          <EditorLine
            text="Updated"
            date={formatStringDate(props.plan.updatedAt, '-')}
            user={{
              firstName: props.plan.updatedBy.firstName,
              lastName: props.plan.updatedBy.lastName,
            }}
          />
        ) : null}
      </div>

      <Link
        className="btn btn-secondary btn-full"
        to={`/strategic-planning/${props.plan.brand.id}/${props.plan.id}/section/overview`}
      >
        View plan
      </Link>
    </div>
  );
};

export default PlanItem;
