import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { CheckoutForm, Price } from '../types/checkout';
import { User } from '../types/user';
import checkoutService from '../services/checkoutService';

import CardDetails from '../components/Checkout/CardDetails';
import Trial from '../components/Checkout/Trial';
import SubscriptionType from '../components/Checkout/SubscriptionType';
import Seats from '../components/Checkout/Seats';
import Summary from '../components/Checkout/Summary';
import Coupon from '../components/Checkout/Coupon';
import Spinner from '../Spinner';

const schema = Yup.object().shape({
  type: Yup.object()
    .shape({
      id: Yup.string().required(),
      name: Yup.string().required(),
      price: Yup.number().required(),
    })
    .required('Subscription Type is required'),
  numberOfSeats: Yup.number()
    .integer()
    .min(1)
    .required('Minimum 1 seat is required'),
  couponCode: Yup.object(),
});

const Checkout: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const queryClient = useQueryClient();

  const prices: Price[] = useMemo(
    () => [
      {
        id: process.env.REACT_APP_STRIPE_MONTHLY_ID || '',
        name: 'Monthly',
        price: parseFloat(process.env.REACT_APP_STRIPE_MONTHLY_PRICE || ''),
      },
      {
        id: process.env.REACT_APP_STRIPE_ANNUAL_ID || '',
        name: 'Annual',
        price: parseFloat(process.env.REACT_APP_STRIPE_ANNUAL_PRICE || ''),
      },
    ],
    []
  );

  const initialValues: CheckoutForm = {
    type: prices[0],
    numberOfSeats: 1,
    promotionCode: null,
  };

  const formik = useFormik<CheckoutForm>({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: schema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: CheckoutForm) => {
      if (!stripe || !elements) {
        return;
      }

      const { error } = await stripe.confirmSetup({
        elements,
        redirect: 'if_required',
      });

      if (error && error.message) {
        toast.error(error.message);
        return;
      }

      try {
        const newSubscription = await checkoutService.createSubscription({
          priceId: values.type.id,
          numberOfSeats: values.numberOfSeats,
          discountCode: values.promotionCode?.id,
        });

        //update organisation subscription status on user query in order to let it redirect
        await queryClient.cancelQueries('user');
        const previousUserData = queryClient.getQueryData<User>('user');

        if (previousUserData) {
          const newUserValue: User = {
            ...previousUserData,
            organisation: {
              ...previousUserData?.organisation,
              subscriptionStatus: newSubscription.status,
            },
          };
          queryClient.setQueryData('user', newUserValue);
        }

        history.push('/');
      } catch (e) {
        toast.error('Something went wrong! Try again later!');
      }
    },
  });

  return (
    <div className="relative grid grid-cols-1 gap-x-16 max-w-7xl mx-auto lg:px-8 lg:grid-cols-2">
      <h1 className="sr-only">Checkout</h1>
      <section
        aria-labelledby="summary-heading"
        className="bg-secondary-600 text-indigo-300 pt-6 pb-12 md:px-10 lg:max-w-lg lg:w-full lg:mx-auto lg:px-0 lg:pt-0 lg:pb-24 lg:bg-transparent lg:row-start-1 lg:col-start-2"
      >
        <div className="max-w-2xl mx-auto px-4 lg:max-w-none lg:px-0">
          <Coupon onChange={formik.setFieldValue} />
          <Summary values={formik.values} />
        </div>
      </section>

      <section
        aria-labelledby="payment-heading"
        className="pb-16 pt-8 lg:max-w-lg lg:w-full lg:mx-auto lg:pt-0 lg:pb-24 lg:row-start-1 lg:col-start-1"
      >
        <h2 id="payment-heading" className="sr-only">
          Payment details
        </h2>
        <div className="max-w-2xl mx-auto px-4 lg:max-w-none lg:px-0">
          <Trial />
          <div className="mb-10">
            <CardDetails />
          </div>
          <SubscriptionType
            value={formik.values.type}
            onChange={formik.setFieldValue}
            prices={prices}
            title="Subscription Type"
            description="Select how you’d like to be billed, and how many seats you need
          initially."
          />
          <Seats
            value={formik.values.numberOfSeats}
            onChange={formik.setFieldValue}
          />
          <div className="pt-8 mt-8 border-t border-gray-300">
            <button
              type="submit"
              className="btn btn-primary btn-full"
              disabled={formik.isSubmitting || !stripe}
              onClick={formik.submitForm}
            >
              {formik.isSubmitting ? <Spinner className="w-4 h-4" /> : 'Submit'}
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Checkout;
