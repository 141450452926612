import {
  collection,
  collectionGroup,
  doc,
  getDocs,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import assign from 'lodash/assign';

import { db } from '../utils/firebase';
import { Project, ProjectDTO } from '../types/project';
import axios from '../utils/axios';

class ProjectService {
  getProjectsByBrand = async (brandId: string): Promise<Project[]> => {
    const brandRef = doc(db, 'brands', brandId);
    const projectsRef = collection(brandRef, 'projects');
    const querySnapshot = await getDocs(
      query(projectsRef, orderBy('createdAt', 'desc'))
    );

    if (querySnapshot.empty) {
      return [];
    }

    return querySnapshot.docs.map(doc => {
      return {
        ...doc.data(),
        id: doc.id,
        brandId: brandId,
        projectEndDate: doc.data().projectEndDate
          ? doc.data().projectEndDate.toDate().toISOString()
          : null,
      } as Project;
    });
  };

  getProjects = async (organisationId: string): Promise<Array<Project>> => {
    const projects = query(
      collectionGroup(db, 'projects'),
      where('organisation.id', '==', organisationId)
    );

    const querySnapshot = await getDocs(projects);

    if (querySnapshot.empty) {
      return [];
    }

    return querySnapshot.docs.map(doc => {
      return {
        ...doc.data(),
        id: doc.id,
        brandId: doc.ref.parent?.parent?.id,
        projectEndDate: doc.data().projectEndDate
          ? doc.data().projectEndDate.toDate().toISOString()
          : null,
      } as Project;
    });
  };

  createProject = async (context: {
    data: ProjectDTO;
    brandId: string;
  }): Promise<Project> => {
    const postData = { ...context.data };
    if (context.data.projectManager) {
      assign(postData, { projectManager: context.data.projectManager.id });
    }
    if (!context.data.projectEndDate) {
      delete postData.projectEndDate;
    }

    const response = await axios.post<Project>(
      `/brands/${context.brandId}/projects`,
      postData
    );

    return response.data;
  };

  updateProject = async (context: {
    data: ProjectDTO;
    brandId: string;
    projectId: string;
  }): Promise<Project> => {
    const postData = { ...context.data };
    if (context.data.projectManager) {
      assign(postData, { projectManager: context.data.projectManager.id });
    }
    if (!context.data.projectEndDate) {
      delete postData.projectEndDate;
    }

    const response = await axios.patch<Project>(
      `/brands/${context.brandId}/projects/${context.projectId}`,
      postData
    );

    return response.data;
  };

  deleteProject = async (context: {
    brandId: string;
    projectId: string;
  }): Promise<Project> => {
    const response = await axios.delete<Project>(
      `/brands/${context.brandId}/projects/${context.projectId}`
    );

    return response.data;
  };
}

const projectService = new ProjectService();

export default projectService;
