import * as Yup from 'yup';
import React from 'react';
import { Formik, FormikProps } from 'formik';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-hot-toast';

import { taskTemplatesKey } from '../../config';
import { TaskTemplate, TaskTemplateDTO } from '../../types/task';
import taskService from '../../services/taskService';

import TemplateForm from './TemplateForm';
import Drawer from '../Drawer';
import Spinner from '../../Spinner';
import DeleteButton from '../DeleteButton';
import { usePermission } from '../../hooks/usePermission';

type EditTemplateDrawerDrawerProps = {
  onClose: () => void;
  open: boolean;
  data: TaskTemplate | null;
};

const schema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  activity: Yup.string().required('Activity is required'),
  tasks: Yup.array().of(Yup.string().required('Task name is required')),
});

const EditTemplateDrawer: React.FC<EditTemplateDrawerDrawerProps> = props => {
  const queryClient = useQueryClient();
  const permission = usePermission();
  const updateTaskTemplate = useMutation(taskService.updateTaskTemplate, {
    onSuccess: async () => {
      toast.success('The task template has been successfully updated!');
      await queryClient.refetchQueries(taskTemplatesKey.all);
    },
    onError: () => {
      toast.error('Something went wrong. Try again!');
    },
  });

  const deleteTask = useMutation(taskService.deleteTaskTemplate, {
    onSuccess: async () => {
      toast.success('The task template has been successfully deleted!');
      await queryClient.refetchQueries(taskTemplatesKey.all);
    },
    onError: () => {
      toast.error('Something went wrong. Try again!');
    },
  });

  const initialValues: TaskTemplateDTO = {
    name: props.data?.name || '',
    activity: props.data?.activity || '',
    tasks: props.data?.tasks || [],
  };

  const handleOnSubmit = async (values: TaskTemplateDTO) => {
    try {
      await updateTaskTemplate.mutateAsync({
        values: values,
        templateId: props.data?.id || '',
      });
      props.onClose();
    } catch (e) {
      //
    }
  };

  const handleDelete = async () => {
    try {
      await deleteTask.mutateAsync(props.data?.id || '');
      props.onClose();
    } catch (e) {
      //
    }
  };

  return (
    <Drawer open={props.open} onClose={props.onClose}>
      <Formik
        enableReinitialize={false}
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleOnSubmit}
      >
        {({ isSubmitting, dirty }: FormikProps<TaskTemplateDTO>) => (
          <Drawer.FormContainer
            open={props.open}
            onClose={props.onClose}
            title="Update Template"
            description="Add or remove tasks, update activity"
            Footer={
              <>
                <button
                  className="btn btn-white"
                  onClick={props.onClose}
                  disabled={isSubmitting}
                  type="button"
                >
                  Cancel
                </button>
                {permission.data?.isAdmin || permission.data?.isMember ? (
                  <DeleteButton
                    modalTitle="Delete template"
                    modalText="Once you delete this template, you will lose all data associated with it. This action is irreversible."
                    className="btn btn-delete capitalize ml-4"
                    disabled={isSubmitting || deleteTask.isLoading}
                    type="button"
                    onClick={handleDelete}
                  >
                    {deleteTask.isLoading ? 'Deleting' : 'Delete Template'}
                  </DeleteButton>
                ) : null}
                <button
                  className="btn btn-primary ml-4"
                  type="submit"
                  disabled={isSubmitting || !dirty}
                >
                  {isSubmitting ? (
                    <>
                      <Spinner
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      Loading
                    </>
                  ) : (
                    'Update'
                  )}
                </button>
              </>
            }
          >
            <TemplateForm isSubmitting={isSubmitting} />
          </Drawer.FormContainer>
        )}
      </Formik>
    </Drawer>
  );
};

export default EditTemplateDrawer;
