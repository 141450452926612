import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { useMaintenance } from '../../hooks/useMaintenance';

import LoadingScreen from '../LoadingScreen';

const MaintenanceGuard: React.FC = props => {
  const maintenance = useMaintenance();
  const location = useLocation();

  if (maintenance.isLoading) {
    return <LoadingScreen />;
  }

  const isMaintenanceModeOn = maintenance.data?.isMaintenanceModeOn;

  if (isMaintenanceModeOn && location.pathname !== '/maintenance') {
    return <Redirect to="/maintenance" />;
  }

  if (!isMaintenanceModeOn && location.pathname === '/maintenance') {
    return <Redirect to="/" />;
  }

  return <>{props.children}</>;
};

export default MaintenanceGuard;
