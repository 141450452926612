class CustomError<ErrorCodeType> extends Error {
  constructor(public readonly code: ErrorCodeType) {
    super();
    Object.setPrototypeOf(this, CustomError.prototype);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomError);
    }

    this.code = code;
  }
}

export default CustomError;
