import React from 'react';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';

import authService from '../../services/authService';
import Spinner from '../../Spinner';
import FormikPasswordInput from '../FormikPasswordInput';
import { auth } from '../../utils/firebase';

const schema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, 'Password must contain at least 8 characters')
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Password must contain at least 8 characters, one number, one uppercase letter'
    ),
});

const ChangePasswordForm: React.FC<{ id: string }> = props => {
  const changePassword = useMutation(authService.updateUser, {
    onSuccess: () => {
      toast.success('Your password has been updated!');
    },
    onError: () => {
      toast.error('Error occurred during the update.');
    },
  });

  const initialValues: { newPassword: string } = {
    newPassword: '',
  };

  const handleOnSubmit = async (
    values: { newPassword: string },
    formikBag: FormikHelpers<{ newPassword: string }>
  ) => {
    try {
      await changePassword.mutateAsync({
        data: {
          password: values.newPassword,
        },
        id: props.id,
      });
      await auth.currentUser?.getIdToken(true);
      formikBag.resetForm();
    } catch (e) {
      //
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleOnSubmit}
    >
      {({ isSubmitting, dirty }: FormikProps<{ newPassword: string }>) => (
        <Form
          className="space-y-6 mt-8 border-t border-gray-200 pt-8"
          noValidate
        >
          <p className="text-sm text-gray-500 leading-5 font-normal">
            You will be logged out after changing.
          </p>
          <fieldset disabled={isSubmitting} className="space-y-6">
            <Field
              component={FormikPasswordInput}
              label="Update Password"
              name="newPassword"
              autoComplete="new-password"
              helperText="Passwords should be at least 8 characters long."
            />
          </fieldset>
          <div className="w-ful flex">
            <button
              type="submit"
              className="btn btn-primary ml-auto"
              disabled={isSubmitting || !dirty}
            >
              {isSubmitting ? (
                <Spinner className="h-4 w-4 trailing-icon" />
              ) : null}
              Update
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
