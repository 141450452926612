import React from 'react';
import { useFormikContext } from 'formik';
import { Listbox, Transition } from '@headlessui/react';
import { SelectorIcon } from '@heroicons/react/solid';
import clsx from 'clsx';

import { FormikAnswer, Question } from '../../../types/strategic-plan';

import QuestionContainer from '../QuestionContainer';

type MultiSelectProps = {
  index: number;
  question: Question;
};

const MultiSelect: React.FC<MultiSelectProps> = props => {
  const formik = useFormikContext<{
    questions: FormikAnswer[];
  }>();

  if (
    !props.question.questionSpecifics ||
    !props.question.questionSpecifics.multiselectItems
  ) {
    return null;
  }

  const addItemToSelected = (item: string) => {
    if (
      Array.isArray(formik.values.questions[props.index].formData.selectedItems)
    ) {
      if (
        !formik.values.questions[props.index].formData.selectedItems.includes(
          item
        )
      ) {
        formik.setFieldValue(
          `questions[${props.index}].formData.selectedItems`,
          [...formik.values.questions[props.index].formData.selectedItems, item]
        );
      }
    } else {
      formik.setFieldValue(`questions[${props.index}].formData.selectedItems`, [
        item,
      ]);
    }
  };

  const removeItemFromSelected = (item: string) => {
    if (
      Array.isArray(formik.values.questions[props.index].formData.selectedItems)
    ) {
      formik.setFieldValue(
        `questions[${props.index}].formData.selectedItems`,
        formik.values.questions[props.index].formData.selectedItems.filter(
          (existingItem: string) => existingItem !== item
        )
      );
    } else {
      formik.setFieldValue(
        `questions[${props.index}].formData.selectedItems`,
        []
      );
    }
  };

  return (
    <QuestionContainer
      name={props.question.name}
      description={props.question.description}
    >
      <fieldset>
        <p className="text-gray-700 text-sm font-medium">
          Select all items that apply
        </p>

        <div className="mt-4 relative">
          <Listbox
            onChange={value => {
              addItemToSelected(value);
            }}
            value=""
          >
            {({ open }) => (
              <div>
                <div className="mt-1 relative">
                  <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-secondary-700 focus:border-indigo-500 sm:text-sm">
                    <span className="block truncate">Select an item...</span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <SelectorIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={React.Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                      {props.question.questionSpecifics?.multiselectItems?.map(
                        item => (
                          <Listbox.Option
                            key={item}
                            className={({ active }) =>
                              clsx(
                                active
                                  ? 'text-white bg-secondary-600'
                                  : 'text-gray-900',
                                'cursor-pointer select-none relative py-2 pl-3 pr-9'
                              )
                            }
                            value={item}
                          >
                            {item}
                          </Listbox.Option>
                        )
                      )}
                    </Listbox.Options>
                  </Transition>
                </div>
              </div>
            )}
          </Listbox>
        </div>

        <div className="mt-2">
          {formik.values.questions[props.index]?.formData?.selectedItems?.map(
            (item: string) => {
              return (
                <span
                  key={item}
                  className="inline-flex items-center py-0.5 pl-2 pr-0.5 mr-1 rounded-full text-xs font-medium bg-secondary-50 text-secondary-800"
                >
                  {item}

                  <button
                    type="button"
                    className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-secondary-800 hover:bg-secondary-100 hover:text-secondary-800 focus:outline-none focus:bg-secondary-800 focus:text-secondary-50"
                    onClick={() => {
                      removeItemFromSelected(item);
                    }}
                  >
                    <span className="sr-only">Remove</span>
                    <svg
                      className="h-2 w-2"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 8 8"
                    >
                      <path
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        d="M1 1l6 6m0-6L1 7"
                      />
                    </svg>
                  </button>
                </span>
              );
            }
          )}
        </div>
      </fieldset>
    </QuestionContainer>
  );
};

export default MultiSelect;
