import React from 'react';
import { FastField, Field, FieldArray } from 'formik';

import { Project } from '../../types/project';
import { Brand } from '../../types/brand';
import { User } from '../../types/user';

import FormikInput from '../FormikInput';
import FormikCombobox from '../FormikCombobox';
import FormikTextarea from '../FormikTextarea';
import clsx from 'clsx';
import { formatStringDate, getDeadlineClass } from '../../utils/helper';
import AssigneesFieldArray from './AssigneesFieldArray';

type TaskDetailsFormProps = {
  isSubmitting: boolean;
  users: User[];
  projects: Project[];
  brands: Brand[];
  deadline?: string; // only if edit screen
};

const TaskDetailsForm: React.FC<TaskDetailsFormProps> = props => {
  return (
    <div className="divide-y divide-gray-200">
      <fieldset className="space-y-6 pt-6 pb-5" disabled={props.isSubmitting}>
        <div className="flex items-center">
          <label className="text-base leading-6 font-semibold pb-0">
            Details
          </label>
          {props.deadline ? (
            <span
              className={clsx(
                getDeadlineClass(props.deadline),
                'ml-auto rounded-full text-xs py-1 px-2'
              )}
            >
              {formatStringDate(props.deadline)}
            </span>
          ) : null}
        </div>
        <FastField
          type="text"
          name="name"
          component={FormikInput}
          label="Task Name*"
          placeholder="Enter Task Name"
          autoFocus
        />
        <FastField
          type="text"
          name="activity"
          component={FormikInput}
          label="Activity"
          placeholder="Enter Activity that Task belongs to"
        />
        <FastField
          type="text"
          name="brand"
          component={FormikCombobox}
          label="Brand*"
          placeholder="Start typing to select brand "
          data={props.brands}
          itemToString={(brand: Brand) => (brand ? brand.name : '')}
          renderItem={(brand: Brand) => <span>{brand.name}</span>}
        />
        <Field
          type="text"
          name="project"
          component={FormikCombobox}
          label="Project*"
          placeholder="Start typing to select project "
          data={props.projects}
          itemToString={(project: Project) =>
            project ? `${project.name}` : ''
          }
          renderItem={(project: Project) => {
            const brand = props.brands.find(
              brand => brand.id === project?.brandId
            );
            if (brand) {
              return (
                <span className="block truncate">
                  {project.name} -{' '}
                  <span className="text-gray-500">{brand.name}</span>
                </span>
              );
            }

            return <span className="block truncate">{project.name}</span>;
          }}
        />
        <FastField
          type="date"
          name="deadline"
          component={FormikInput}
          label="Deadline Date"
          placeholder="dd/mm/yy"
          max="9999-12-30"
          style={{ height: 38 }}
        />
      </fieldset>

      <fieldset className="space-y-6 pt-6 pb-5" disabled={props.isSubmitting}>
        <div className="flex items-center">
          <label className="text-base leading-6 font-semibold pb-0">
            Assignees
          </label>
        </div>
        <FieldArray
          name="assignees"
          render={renderProps => (
            <AssigneesFieldArray {...renderProps} users={props.users} />
          )}
        />
      </fieldset>

      <fieldset className="space-y-6 pt-6 pb-5" disabled={props.isSubmitting}>
        <div className="flex items-center">
          <label className="text-base leading-6 font-semibold pb-0">
            Additional Information
          </label>
        </div>
        <FastField
          name="description"
          component={FormikTextarea}
          label="Description"
        />
      </fieldset>
    </div>
  );
};

export default TaskDetailsForm;
