import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

import { strategicPlannerKeys } from '../config';
import strategicPlanService from '../services/strategicPlanService';

export function useSPQuestions() {
  const { sectionId } = useParams<{ sectionId: string }>();

  return useQuery(
    strategicPlannerKeys.questions(sectionId),
    () => strategicPlanService.getQuestionsBySectionId(sectionId),
    {
      enabled: !!sectionId && sectionId !== 'overview', //it doesnt have db fields
      onError: () => {
        toast.error('Try again! Something went wrong.');
      },
      staleTime: 1000 * 60 * 60, // 1 hour
    }
  );
}
