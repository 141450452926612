import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import toast from 'react-hot-toast';
import { parseISO } from 'date-fns';

import organisationService from '../../services/organisationService';
import { useIsOwner, useUser } from '../../hooks/useUser';

import DeleteButton from '../DeleteButton';
import Spinner from '../../Spinner';
import { User } from '../../types/user';
import { Organisation } from '../../types/organisation';

const DeleteOrganisation: React.FC = () => {
  const queryClient = useQueryClient();
  const isOwner = useIsOwner();
  const user = useUser();

  const deleteOrg = useMutation(organisationService.deleteOrganisation, {
    onSuccess: async re => {
      queryClient.setQueryData<User | undefined>('user', user => {
        if (!user) return;

        return {
          ...user,
          organisation: {
            ...user.organisation,
            subscriptionStatus: re.subscriptionStatus,
            deletedAt: re.deletedAt,
          },
        };
      });

      queryClient.setQueryData<Organisation | undefined>(
        'organisation',
        organisation => {
          if (!organisation) return;

          return {
            ...organisation,
            subscriptionStatus: re.subscriptionStatus,
            deletedAt: re.deletedAt,
          };
        }
      );
    },
    onError: () => {
      toast.error('Something went wrong. Try again!');
    },
  });

  const resumeOrganisation = useMutation(
    organisationService.cancelOrganisationDeletion,
    {
      onSuccess: async re => {
        queryClient.setQueryData<User | undefined>('user', user => {
          if (!user) return;

          return {
            ...user,
            organisation: {
              ...user.organisation,
              subscriptionStatus: re.subscriptionStatus,
              deletedAt: re.deletedAt,
            },
          };
        });

        queryClient.setQueryData<Organisation | undefined>(
          'organisation',
          organisation => {
            if (!organisation) return;

            return {
              ...organisation,
              subscriptionStatus: re.subscriptionStatus,
              deletedAt: re.deletedAt,
            };
          }
        );
      },
      onError: () => {
        toast.error('Something went wrong. Try again!');
      },
    }
  );

  // only owners can to this
  if (!isOwner.data) {
    return null;
  }
  const date = user?.data?.organisation?.deletedAt
    ? parseISO(user?.data?.organisation?.deletedAt)
    : null;

  return (
    <div className="mt-8">
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        {date
          ? 'Your account has been deactivated!'
          : 'Delete your Organisation'}
      </h3>
      <p className="text-sm text-gray-500 leading-5 font-normal">
        {date
          ? null
          : 'Once you delete your account, you will lose all data associated with it. This action is irreversible after 30 days. Please continue carefully.'}
      </p>
      {date ? (
        <button
          className="btn btn-primary mt-4"
          disabled={resumeOrganisation.isLoading}
          onClick={() => resumeOrganisation.mutate()}
        >
          {resumeOrganisation.isLoading ? (
            <Spinner className="h-4 w-4 trailing-icon" />
          ) : null}
          Reactivate account
        </button>
      ) : (
        <DeleteButton
          className="btn btn-delete mt-4"
          modalTitle="Deactivate account"
          modalText="Once you delete your account, you will lose all data associated with
          it. This action is irreversible after 30 days. Please continue
          carefully."
          disabled={deleteOrg.isLoading}
          onClick={() => deleteOrg.mutate()}
        >
          {deleteOrg.isLoading ? (
            <Spinner className="h-4 w-4 trailing-icon" />
          ) : null}
          Delete account
        </DeleteButton>
      )}
    </div>
  );
};

export default DeleteOrganisation;
