import { TaskFilter } from './types/task';

export const APP_TITLE = 'Rhubarb Plans';

export const brandKeys = {
  all: ['brands'] as const,
  single: (id: string) => [...brandKeys.all, id] as const,
};

export const projectKeys = {
  all: ['projects'] as const,
  allByBrand: (brandId: string) => ['projects', brandId] as const,
  single: (brandId: string, id: string) =>
    [...projectKeys.allByBrand(brandId), id] as const,
};

export const taskKeys = {
  all: ['tasks'] as const,
  filtered: (filter: TaskFilter) => [...taskKeys.all, filter] as const,
};

export const taskTemplatesKey = {
  all: ['task-templates'] as const,
};

export const userKeys = {
  pm: ['users', 'only-pm'] as const,
};

export const memberKeys = {
  all: ['members'] as const,
  onlyUser: ['members', 'only-user'] as const,
  pm: ['members', 'only-pm'],
};

export const resourcePlannerKeys = {
  all: ['resource-planner'] as const,
  month: (date: string) => [...resourcePlannerKeys.all, date] as const,
  settings: ['resource-planner-settings'],
};

export const strategicPlannerKeys = {
  sections: ['sections'] as const,
  plans: (data: { brandId: string; organisationId: string }) =>
    ['plans', data] as const,
  plan: (SPId: string) => ['plans', SPId] as const,
  questions: (sectionId: string) => ['questions', sectionId] as const,
};

export const defaultTaskFilter: TaskFilter = {
  status: 'in-progress',
  text: '',
  orderBy: [],
  projects: [],
  brands: [],
  assignees: [],
  deadlineFrom: '',
  deadlineTo: '',
};
