import { FastField, Form, Formik, FormikProps } from 'formik';
import React from 'react';
import { toast } from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import * as Yup from 'yup';
import organisationService from '../../services/organisationService';
import Spinner from '../../Spinner';
import { User } from '../../types/user';
import FormikInput from '../FormikInput';

type OrganisationFormProps = {
  name: string;
  workingHoursPerWeek: number;
};

const schema = Yup.object().shape({
  name: Yup.string().required('Company name is required!'),
  workingHoursPerWeek: Yup.number()
    .min(1)
    .required('Working hours per week is required!'),
});

const OrganisationForm: React.FC<OrganisationFormProps> = props => {
  const queryClient = useQueryClient();

  const updateOrganisation = useMutation(
    organisationService.updateOrganisation,
    {
      onError: () => {
        toast.error('Something went wrong. Try again!');
      },
      onSuccess: variables => {
        queryClient.setQueryData<User | undefined>('user', data => {
          if (!data) return;

          return {
            ...data,
            organisation: {
              ...data?.organisation,
              name: variables.name,
              workingHoursPerWeek: variables.workingHoursPerWeek,
            },
          };
        });
      },
    }
  );

  const initialValues: OrganisationFormProps = {
    name: props.name,
    workingHoursPerWeek: props.workingHoursPerWeek,
  };

  const handleOnSubmit = async (values: OrganisationFormProps) => {
    try {
      await updateOrganisation.mutateAsync(values);
    } catch (e) {
      //
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleOnSubmit}
    >
      {({ isSubmitting, dirty }: FormikProps<OrganisationFormProps>) => (
        <Form className="space-y-6 mt-8" noValidate>
          <fieldset disabled={isSubmitting} className="space-y-6">
            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <FastField
                  type="text"
                  name="name"
                  component={FormikInput}
                  label="Organisation Name*"
                  placeholder="Company name"
                />
              </div>

              <div className="sm:col-span-3">
                <FastField
                  type="number"
                  onWheel={(e: React.WheelEvent<HTMLInputElement>) => {
                    if (e.target instanceof HTMLElement) {
                      e.target.blur();
                    }
                  }}
                  name="workingHoursPerWeek"
                  component={FormikInput}
                  label="Working hours per week*"
                  placeholder="37.5"
                />
              </div>
            </div>
          </fieldset>

          <div className="w-full flex">
            <button
              type="submit"
              className="btn btn-primary ml-auto"
              disabled={isSubmitting || !dirty}
            >
              {isSubmitting ? (
                <Spinner className="h-4 w-4 trailing-icon" />
              ) : null}
              Update
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default OrganisationForm;
