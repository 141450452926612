import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Form } from 'formik';

type FormContainerProps = {
  open: boolean;
  onClose: () => void;
  title?: string;
  description?: string;
  Footer: React.ReactElement;
};

type DrawerProps = {
  open: boolean;
  onClose: () => void;
};

const FormContainer: React.FC<FormContainerProps> = props => {
  return (
    <Form
      className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl"
      noValidate
    >
      <div className="flex-1 h-0 overflow-y-auto">
        <div className="py-6 px-4 bg-secondary-700 sm:px-6">
          <div className="flex items-center justify-between">
            <Dialog.Title className="text-lg font-medium text-white">
              {props.title}
            </Dialog.Title>
            <div className="ml-3 h-7 flex items-center">
              <button
                type="button"
                tabIndex={-1}
                className="bg-secondary-600 rounded-md text-secondary-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                onClick={props.onClose}
              >
                <span className="sr-only">Close panel</span>
                <XIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
          <div className="mt-1">
            <p className="text-sm text-secondary-50">{props.description}</p>
          </div>
        </div>
        <div className="flex-1 flex flex-col justify-between">
          {props.children}
        </div>
      </div>
      <div className="flex-shrink-0 px-4 py-4 flex justify-end">
        {props.Footer}
      </div>
    </Form>
  );
};

const Drawer: React.FC<DrawerProps> & {
  FormContainer: React.FC<FormContainerProps>;
} = props => {
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden z-50"
        onClose={props.onClose}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

          <div className="fixed inset-y-0 max-w-full right-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">{props.children}</div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

Drawer.FormContainer = FormContainer;

export default Drawer;
