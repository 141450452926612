import React from 'react';

import { useUser } from '../hooks/useUser';

import AccountDetailsForm from '../components/Account/AccountDetailsForm';
import InputSkeleton from '../components/InputSkeleton';
import ErrorPage from '../components/ErrorPage';
import ChangePasswordForm from '../components/Settings/ChangePasswordForm';

const Account: React.FC = () => {
  const user = useUser();

  const UpdateForm: React.FC = () => {
    if (user.isLoading)
      return (
        <div className="space-y-6">
          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <InputSkeleton />
            </div>
            <div className="sm:col-span-3">
              <InputSkeleton />
            </div>
          </div>
          <InputSkeleton />
          <InputSkeleton />
        </div>
      );

    if (!user.data) return <ErrorPage />;

    return <AccountDetailsForm data={user.data} id={user.data.id} />;
  };

  const ChangeForm: React.FC = () => {
    if (user.isLoading) {
      return <InputSkeleton />;
    }

    if (!user.data) return <ErrorPage />;

    return <ChangePasswordForm id={user.data.id} />;
  };

  return (
    <section className="bg-white p-6 shadow sm:rounded-md sm:overflow-hidden mb-6 max-w-screen-lg w-full ml-auto mr-auto">
      <h1 className="text-lg leading-6 font-medium text-gray-900">Profile</h1>
      <p className="text-sm text-gray-500 leading-5 font-normal">
        Manage your personal details
      </p>
      <UpdateForm />
      <ChangeForm />
    </section>
  );
};

export default Account;
