import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import assign from 'lodash/assign';

import { db } from '../utils/firebase';
import { Brand, BrandDTO } from '../types/brand';
import axios from '../utils/axios';
import transformDoc from '../utils/transform-doc';
import CustomError from '../utils/custom-error';

class BrandService {
  getBrands = async (organisationId: string): Promise<Brand[]> => {
    const q = query(
      collection(db, 'brands'),
      where('organisation.id', '==', organisationId),
      orderBy('name', 'asc')
    );

    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return [];
    }

    return querySnapshot.docs.map(doc => {
      return {
        ...doc.data(),
        id: doc.id,
        contractEnd: doc.data().contractEnd
          ? doc.data().contractEnd.toDate().toISOString()
          : null,
      } as Brand;
    });
  };

  getBrand = async (brandId: string): Promise<Brand> => {
    const docRef = doc(db, 'brands', brandId);
    const brand = await transformDoc<Omit<Brand, 'id'>>(docRef);

    if (!brand) {
      throw new CustomError('i-brand/no-brand-found');
    }

    return {
      id: brandId,
      ...brand,
      contractEnd: brand.contractEnd
        ? // @ts-ignore
          brand.contractEnd?.toDate().toISOString()
        : null,
    };
  };

  createBrand = async (data: BrandDTO): Promise<Brand> => {
    const postData = { ...data };
    if (data.projectManager) {
      assign(postData, { projectManager: data.projectManager.id });
    }
    if (!data.contractEnd) {
      delete postData.contractEnd;
    }

    const response = await axios.post<Brand>('/brands', postData);

    return response.data;
  };

  updateBrand = async (mutation: {
    data: BrandDTO;
    id: string;
  }): Promise<Brand> => {
    const postData = { ...mutation.data };
    if (mutation.data.projectManager) {
      assign(postData, { projectManager: mutation.data.projectManager.id });
    }
    if (!mutation.data.contractEnd) {
      delete postData.contractEnd;
    }

    const response = await axios.patch<Brand>(
      `/brands/${mutation.id}`,
      postData
    );

    return response.data;
  };

  deleteBrand = async (id: string): Promise<Brand> => {
    const response = await axios.delete<Brand>(`/brands/${id}`);
    return response.data;
  };
}

const brandService = new BrandService();

export default brandService;
