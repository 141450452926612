import React, { useState } from 'react';
import { FieldProps, getIn } from 'formik';
import clsx from 'clsx';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid';

const FormikPasswordInput: React.ComponentType<
  FieldProps & { label: string; helperText?: string }
> = ({ field, form, label, helperText, ...rest }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const error = getIn(form.errors, field.name);
  const isTouched = getIn(form.touched, field.name);
  const hasError = isTouched && error;

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <div>
      <label
        htmlFor={field.name}
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div
        className={clsx({
          'input mt-1 relative': true,
          'input-error': hasError,
        })}
      >
        <input
          id={field.name}
          className={clsx({
            'sm:text-sm border-0 focus:outline-none block w-full px-3 py-2 appearance-none rounded-md placeholder-gray-400':
              true,
            'text-red-900 placeholder-red-300': hasError,
          })}
          {...field}
          {...rest}
          type={showPassword ? 'text' : 'password'}
        />
        <button
          type="button"
          aria-label="toggle password visibility"
          onClick={togglePasswordVisibility}
          className="inset-y-0 pr-3 flex items-center"
        >
          {showPassword ? (
            <EyeOffIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          ) : (
            <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          )}
        </button>
      </div>
      {helperText ? (
        <p className="mt-2 text-xs text-gray-500" id="email-description">
          {helperText}
        </p>
      ) : null}
      {hasError ? (
        <p className="mt-1 text-xs text-red-900" id={`${field.name}-error`}>
          {error}
        </p>
      ) : null}
    </div>
  );
};

export default FormikPasswordInput;
