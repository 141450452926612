import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/outline';

import { useSPSections } from '../../hooks/useSPSections';
import Spinner from '../../Spinner';
import SectionNavigationList from './SectionNavigationList';

type SectionNavigationProps = {
  strategicPlanId?: string;
};

const SectionNavigation: React.FC<SectionNavigationProps> = props => {
  const sections = useSPSections();
  const { brandId } = useParams<{ brandId: string }>();

  if (sections.isLoading) {
    return (
      <div className="hidden md:flex md:w-64 xl:w-80 md:flex-col md:flex">
        <div className="flex flex-col flex-grow pt-6 px-4 bg-white overflow-y-auto">
          <p className="text-gray-900 text-sm leading-5 font-medium mb-6">
            Plan sections
          </p>
          <Spinner className="w-6 h-6" />
        </div>
      </div>
    );
  }

  return (
    <div className="hidden md:flex md:w-64 xl:w-80 md:flex-col md:flex">
      <div className="flex flex-col flex-grow pt-6 px-4 bg-white overflow-y-auto">
        <p className="text-gray-900 text-sm leading-5 font-medium mb-6 mt-5">
          Plan sections
        </p>
        <SectionNavigationList strategicPlanId={props.strategicPlanId} />
        <Link
          to={`/strategic-planning/${brandId}`}
          className="text-sm text-gray-800 flex items-center mt-12"
        >
          <ArrowLeftIcon className="w-4 h-4 mr-3" />
          Back to plans
        </Link>
      </div>
    </div>
  );
};

export default SectionNavigation;
