import React from 'react';
import { MinusCircleIcon, PlusIcon } from '@heroicons/react/solid';
import { FastField, FieldArray, useFormikContext } from 'formik';

import { FormikAnswer, Question } from '../../../types/strategic-plan';
import QuestionContainer from '../QuestionContainer';
import FormikInput from '../../FormikInput';

type TwoColLineByLineProps = {
  index: number;
  question: Question;
};

const TwoColLineByLine: React.FC<TwoColLineByLineProps> = props => {
  const formik = useFormikContext<{
    questions: FormikAnswer[];
  }>();

  return (
    <QuestionContainer
      name={props.question.name}
      description={props.question.description}
    >
      <fieldset>
        <FieldArray
          name={`questions[${props.index}].formData.rows`}
          render={arrayHelpers => (
            <div>
              <div className="space-y-4">
                {formik.values.questions[props.index]?.formData?.rows?.map(
                  (
                    row: { col1Val: string; col2Val: string }[],
                    rowNumber: number
                  ) => (
                    <div className="flex space-x-4" key={rowNumber}>
                      <div className="flex-grow">
                        <FastField
                          name={`questions[${props.index}].formData.rows[${rowNumber}].col1Val`}
                          type="text"
                          component={FormikInput}
                        />
                      </div>
                      <div className="flex-grow">
                        <FastField
                          name={`questions[${props.index}].formData.rows[${rowNumber}].col2Val`}
                          type="text"
                          component={FormikInput}
                        />
                      </div>
                      <button
                        onClick={() => arrayHelpers.remove(rowNumber)}
                        type="button"
                        className="ml-2"
                      >
                        <MinusCircleIcon className="w-10 h-10  text-secondary-600 hover:text-secondary-900" />
                      </button>
                    </div>
                  )
                )}
              </div>
              <div className="flex items-center mt-4">
                <div className="border-gray-200 border-b w-full" />

                <button
                  onClick={() =>
                    arrayHelpers.push({ col1Val: '', col2Val: '' })
                  }
                  type="button"
                  className="btn btn-white whitespace-nowrap"
                >
                  <PlusIcon className="w-5 h-5  text-gray-900 mr-2" />
                  <span className="text-sm leading-5 font-medium">Add Row</span>
                </button>
              </div>
            </div>
          )}
        />
      </fieldset>
    </QuestionContainer>
  );
};

export default TwoColLineByLine;
