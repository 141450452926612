import React from 'react';
import { useUpdateResourcePlanner } from '../../hooks/useUpdateResourcePlanner';
import HeadingInput from './HeadingInput';

type ProjectColumnProps = {
  project: string;
  brand: string;
  monthId: string;
  projectId: string;
  hoursAllocated: number;
  hoursAvailable: number;
};

const ProjectColumn: React.FC<ProjectColumnProps> = props => {
  const updateRP = useUpdateResourcePlanner();

  const handleUpdate = async (newValue: string) => {
    if (parseFloat(props.hoursAvailable.toString()) === parseFloat(newValue)) {
      return;
    }

    try {
      await updateRP.mutateAsync({
        resourcePlannerId: props.monthId,
        values: {
          projects: [
            {
              id: props.projectId,
              hoursAvailable: parseFloat(newValue),
            },
          ],
        },
      });
    } catch (e) {
      //
    }
  };

  return (
    <div className="track bg-gray-50 border-t">
      <div className="heading-item h-full" style={{ width: 190 }}>
        <div>
          <span className="text-gray-900 text-base leading-6 font-normal block truncate">
            {props.project}
          </span>
          <span className="text-gray-500 text-sm leading-5 font-normal block truncate">
            {props.brand}
          </span>
        </div>
        <HeadingInput
          total={props.hoursAllocated}
          value={props.hoursAvailable}
          onChange={handleUpdate}
          disabled={updateRP.isLoading}
          isBig
        />
      </div>
    </div>
  );
};

export default ProjectColumn;
