import React from 'react';
import { FastField, Field } from 'formik';
import { Project } from '../../types/project';
import { Brand } from '../../types/brand';

import FormikInput from '../FormikInput';
import FormikCombobox from '../FormikCombobox';

type TemplateDetailsFormProps = {
  isSubmitting: boolean;
  projects: Project[];
  brands: Brand[];
};

const TemplateDetailsForm: React.FC<TemplateDetailsFormProps> = props => {
  return (
    <div className="divide-y divide-gray-200">
      <fieldset className="space-y-6 pt-6 pb-5" disabled={props.isSubmitting}>
        <div className="flex items-center">
          <label className="text-base leading-6 font-semibold pb-0">
            Details
          </label>
        </div>

        <FastField
          type="text"
          name="brand"
          component={FormikCombobox}
          label="Brand*"
          placeholder="Start typing to select brand "
          data={props.brands}
          itemToString={(brand: Brand) => (brand ? brand.name : '')}
          renderItem={(brand: Brand) => <span>{brand.name}</span>}
          autoFocus
        />
        <Field
          type="text"
          name="project"
          component={FormikCombobox}
          label="Project*"
          placeholder="Start typing to select project "
          data={props.projects}
          itemToString={(project: Project) =>
            project ? `${project.name}` : ''
          }
          renderItem={(project: Project) => {
            const brand = props.brands.find(
              brand => brand.id === project?.brandId
            );
            if (brand) {
              return (
                <span className="block truncate">
                  {project.name} -{' '}
                  <span className="text-gray-500">{brand.name}</span>
                </span>
              );
            }

            return <span className="block truncate">{project.name}</span>;
          }}
        />
        <FastField
          type="date"
          name="deadline"
          component={FormikInput}
          label="Deadline Date"
          placeholder="dd/mm/yy"
          max="9999-12-30"
          style={{ height: 38 }}
        />
      </fieldset>
    </div>
  );
};

export default TemplateDetailsForm;
