import React from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import { Brand } from '../../types/brand';

type BrandMenuProps = {
  brands: Brand[];
};

const BrandMenu: React.FC<BrandMenuProps> = props => {
  return (
    <nav className="flex-1 px-2 pb-4 space-y-1">
      {props.brands.map(brand => (
        <NavLink
          key={brand.name}
          to={`/strategic-planning/${brand.id}`}
          className="text-gray-600 hover:text-gray-900 hover:bg-gray-100 group rounded-md px-3 py-2 flex items-center text-sm font-medium"
          activeClassName="bg-gray-100 text-gray-900 hover:bg-gray-100 hover:text-gray-900"
        >
          <span className="truncate">{brand.name}</span>
          {true ? (
            <span
              className={clsx(
                false ? 'bg-white' : 'bg-gray-100 group-hover:bg-gray-200',
                'ml-3 inline-block py-0.5 px-3 text-xs font-medium rounded-full'
              )}
            >
              {brand.planCount ?? 0}
            </span>
          ) : null}
        </NavLink>
      ))}
    </nav>
  );
};

export default BrandMenu;
