import React from 'react';
import { Column } from 'react-table';

import { TaskTemplate } from '../../types/task';
import { formatStringDate } from '../../utils/helper';
import Table from '../Table';

type TemplatesTableProps = {
  data: TaskTemplate[];
  onEdit: React.Dispatch<
    React.SetStateAction<{ open: boolean; template: TaskTemplate | null }>
  >;
};

const TemplatesTable: React.FC<TemplatesTableProps> = ({ onEdit, data }) => {
  const columns = React.useMemo<Column<TaskTemplate>[]>(() => {
    const handleEdit = (data: TaskTemplate) => {
      onEdit({ open: true, template: data });
    };

    return [
      {
        Header: 'Template Name',
        accessor: 'name',
      },
      {
        Header: 'Activity',
        accessor: 'activity',
      },
      {
        Header: 'Number of tasks',
        accessor: template => template.tasks.length || 0,
      },
      {
        Header: 'Date Created',
        accessor: ({ createdAt }) => formatStringDate(createdAt, '-'),
      },
      {
        Header: 'Last Updated',
        accessor: ({ updatedAt }) => formatStringDate(updatedAt, '-'),
      },
      {
        id: 'view',
        disableSortBy: true,
        accessor: template => {
          return (
            <button
              className="text-link"
              title="View Template"
              onClick={() => handleEdit(template)}
            >
              View
            </button>
          );
        },
      },
    ];
  }, [onEdit]);

  return (
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="overflow-hidden">
          <Table columns={columns} data={data} />
        </div>
      </div>
    </div>
  );
};

export default TemplatesTable;
