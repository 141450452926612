import React from 'react';
import { Link } from 'react-router-dom';

import { APP_TITLE } from '../../config';
import logo from '../../assets/logo-primary.svg';

const CheckoutHeader: React.FC = () => {
  return (
    <header className="relative max-w-7xl mx-auto bg-secondary-600 py-6 lg:bg-transparent lg:grid lg:grid-cols-2 lg:gap-x-16 lg:px-8 lg:pt-16 lg:pb-10">
      <div className="max-w-2xl mx-auto flex-col px-4 lg:max-w-lg lg:w-full lg:px-0 ">
        <Link to="/" title={APP_TITLE}>
          <img className="h-12 w-auto" src={logo} alt={APP_TITLE} />
        </Link>
        <h2 className="mt-4 text-3xl font-extrabold text-gray-900 hidden lg:block">
          Your Subscription
        </h2>
      </div>
      <div className="mx-auto px-4 lg:max-w-lg lg:w-full lg:px-0 items-end hidden lg:flex">
        <h2 id="summary-heading" className="text-white font-extrabold text-3xl">
          Summary
        </h2>
      </div>
    </header>
  );
};

export default CheckoutHeader;
