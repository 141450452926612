import React from 'react';
import * as Yup from 'yup';
import {
  FastField,
  Field,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from 'formik';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { SignUp, SignUpError } from '../../types/sign-up';
import { User } from '../../types/user';
import authService from '../../services/authService';

import FormikInput from '../FormikInput';
import FormikPasswordInput from '../FormikPasswordInput';
import Spinner from '../../Spinner';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Password must contain at least 8 characters')
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Password must contain at least 8 characters, one number, one uppercase letter'
    ),
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  company: Yup.string().required('Company is required'),
  phone: Yup.string().required('Phone number is required'),
  marketing_email: Yup.boolean(),
});

const initialValues: SignUp = {
  first_name: '',
  last_name: '',
  company: '',
  email: '',
  phone: '',
  password: '',
  marketing_email: false,
};

const SignUpForm: React.FC = () => {
  const history = useHistory();

  const signUp = useMutation<User, any, SignUp>(authService.registerOwner, {
    onError: () => {
      toast.error('Error occurred during the sign up process.');
    },
    onSuccess: data => {
      history.push('/auth/verify-email', { email: data.email });
    },
  });

  const handleOnSubmit = async (
    values: SignUp,
    formikBag: FormikHelpers<SignUp>
  ): Promise<void> => {
    try {
      await signUp.mutateAsync(values);
    } catch (e) {
      const error = e as SignUpError;
      switch (error.code) {
        case 'forbidden/is-not-firebase-user':
        case 'auth/email-already-in-use':
          formikBag.setFieldError(
            'email',
            'That email address is already in use!'
          );
          break;
      }
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleOnSubmit}
    >
      {({ isSubmitting }: FormikProps<SignUp>) => (
        <Form className="space-y-6" noValidate>
          <fieldset disabled={isSubmitting}>
            <div className="space-y-6">
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <FastField
                    type="text"
                    name="first_name"
                    component={FormikInput}
                    label="First Name"
                    placeholder="John"
                    autoFocus
                  />
                </div>

                <div className="sm:col-span-3">
                  <FastField
                    type="text"
                    name="last_name"
                    component={FormikInput}
                    label="Last Name"
                    placeholder="Doe"
                  />
                </div>
              </div>
              <FastField
                type="text"
                name="company"
                component={FormikInput}
                label="Company"
                placeholder="Great Company Inc."
              />
              <FastField
                type="email"
                name="email"
                component={FormikInput}
                label="Email address"
                placeholder="you@example.com"
              />
              <FastField
                type="tel"
                name="phone"
                component={FormikInput}
                label="Phone Number"
                placeholder="+44 1234567"
              />
              <Field
                component={FormikPasswordInput}
                label="Create Password"
                name="password"
                helperText="Passwords should be at least 8 characters long."
              />
            </div>
          </fieldset>
          <div className="relative flex items-start">
            <div className="flex items-center h-5">
              <Field
                id="marketing_email"
                aria-describedby="marketing_email-description"
                name="marketing_email"
                type="checkbox"
                className="form-checkbox focus:ring-secondary-700 h-4 w-4 text-secondary-700 border-gray-300 rounded cursor-pointer"
              />
            </div>
            <div className="ml-3 text-sm">
              <label
                htmlFor="marketing_email"
                className="font-medium text-gray-700 cursor-pointer"
              >
                Subscribe to Updates?
              </label>
              <p id="comments-description" className="text-gray-500">
                Get notified when we have feature updates, hints & tips
              </p>
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="btn btn-primary btn-full"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Spinner className="h-4 w-4 trailing-icon" />
              ) : (
                'Create Account'
              )}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SignUpForm;
