import clsx from 'clsx';
import React from 'react';
import { useUpdateResourcePlanner } from '../../hooks/useUpdateResourcePlanner';
import { useSettings } from '../../hooks/useSettings';
import { displayHoursOrDays } from '../../utils/helper';
import { usePermission } from '../../hooks/usePermission';

type RPInputProps = {
  value: number;
  isPM: boolean;
  userId: string;
  projectId: string;
  brandId: string;
  monthId: string;
};

const RPInput: React.FC<RPInputProps> = props => {
  const updateRP = useUpdateResourcePlanner();
  const settings = useSettings();
  const convertedValue = displayHoursOrDays(settings, props.value);
  const permission = usePermission();
  const canEdit = permission.data?.isAdmin || permission.data?.isMember;

  const handleUpdate = async (newValue: string) => {
    if (parseFloat(convertedValue.value.toString()) === parseFloat(newValue)) {
      return;
    }

    try {
      await updateRP.mutateAsync({
        resourcePlannerId: props.monthId,
        values: {
          organisationMembers: [
            {
              id: props.userId,
              hoursAllocatedPerProject: [
                {
                  hoursAllocated:
                    settings.data?.unitOfTime === 'DAYS'
                      ? parseFloat(newValue) * settings.data.workingHoursPerDay
                      : parseFloat(newValue),
                  project: {
                    id: props.projectId,
                    brand: {
                      id: props.brandId,
                    },
                  },
                },
              ],
            },
          ],
        },
      });
    } catch (e) {
      //
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLFormElement>) => {
    handleUpdate(e.target.value);
  };

  const handleOnSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    const target = e.target as typeof e.target & {
      rpInput: { value: string };
    };

    handleUpdate(target.rpInput?.value);
  };

  return (
    <form
      className="entry items-center"
      onSubmit={handleOnSubmit}
      onBlur={handleOnChange}
      noValidate
    >
      <div className="input relative flex items-center">
        <input
          key={convertedValue.value}
          type="number"
          onWheel={e => {
            if (e.target instanceof HTMLElement) {
              e.target.blur();
            }
          }}
          name="rpInput"
          className={clsx(
            'p-2 bg-white border-0 focus:outline-none block w-full appearance-none rounded-md placeholder-gray-400 disabled:bg-gray-50 disabled:text-gray-400',
            { 'pr-14': props.isPM }
          )}
          defaultValue={convertedValue.value}
          disabled={updateRP.isLoading || !canEdit}
        />
        {props.isPM ? (
          <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
            <span className="inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-400">
              PM
            </span>
          </div>
        ) : null}
      </div>
    </form>
  );
};

export default RPInput;
