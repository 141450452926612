import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';

import authService from '../services/authService';
import AuthLayoutHeader from '../components/auth/AuthLayoutHeader';
import authValidationImage from '../assets/auth_validation_undraw.svg';
import Spinner from '../Spinner';
import SuccessMessage from '../components/SuccessMessage';

const VerifyEmail: React.FC = () => {
  const { state } = useLocation<{ email?: string }>();
  const [sent, setSent] = useState<boolean>(false);
  const resendValidation = useMutation(authService.resendEmailValidationEmail, {
    onSuccess: async () => {
      toast.success('Email sent! Check your inbox.');
      setSent(true);
    },
    onError: () => {
      toast.error('Something went wrong. Please try again.');
    },
  });

  const handleResendValidation = async (): Promise<void> => {
    if (state.email) {
      await resendValidation.mutateAsync(state.email);
    } else {
      toast.error('Something went wrong. Please login again.');
    }
  };

  return (
    <div>
      <AuthLayoutHeader />
      <img
        src={authValidationImage}
        alt="Verify Email address"
        className="mb-8 mt-12"
      />
      <h1 className="mb-2 text-lg text-gray-900">Verify your Email</h1>
      <p className="text-sm text-gray-500 mb-6">
        In order to verify your account, please follow the link inside the email
        we’ve just sent you.
      </p>
      <p className="text-sm text-gray-500 mb-6">
        If you’re not received an email, please press the button to try again.
      </p>
      {sent ? (
        <SuccessMessage
          title="Validation email sent to your email address."
          text={
            <p>
              We have sent an email to your address. If you have not received
              the verification email, please check your "Spam" folder.
            </p>
          }
        />
      ) : (
        <button
          className="btn btn-primary btn-full"
          onClick={handleResendValidation}
          disabled={resendValidation.isLoading}
        >
          {resendValidation.isLoading ? (
            <Spinner className="h-4 w-4 trailing-icon" />
          ) : (
            `Resend validation`
          )}
        </button>
      )}
    </div>
  );
};

export default VerifyEmail;
