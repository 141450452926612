import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { usePlan } from '../hooks/usePlans';
import { useNextSection } from '../hooks/useSPSections';
import SectionNavigation from '../components/StrategicPlanner/SectionNavigation';
import PlanContainer from '../components/ResourcePlanner/PlanContainer';

const StrategicPlan: React.FC = () => {
  const { SPId } = useParams<{ SPId: string }>();
  const plan = usePlan();
  const nextSectionUrl = useNextSection();
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const isNewlyCreated = searchParams.get('created');

  useEffect(() => {
    if (!isNewlyCreated || !nextSectionUrl) return;
    history.push(nextSectionUrl);
  }, [isNewlyCreated, history, nextSectionUrl]);

  return (
    <div className="flex flex-col md:flex-row flex-1">
      <SectionNavigation strategicPlanId={SPId} />

      {plan.data && <PlanContainer plan={plan.data} />}
    </div>
  );
};

export default StrategicPlan;
