import toast from 'react-hot-toast';
import { useQuery } from 'react-query';

import organisationService from '../services/organisationService';
import { memberKeys } from '../config';
import { Member } from '../types/organisation';

export function usePMUsers() {
  return useQuery<Member[]>(memberKeys.pm, organisationService.getTeamMembers, {
    select: data => {
      return data.filter(member => {
        //viewers cant be project managers
        return member.role !== 'VIEWER';
      });
    },
    onError: () => {
      toast.error('Try again! Something went wrong.');
    },
  });
}
