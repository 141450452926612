import { Field } from 'formik';
import React from 'react';

import { Brand } from '../../types/brand';
import { Project } from '../../types/project';
import { SPDTO } from '../../types/strategic-plan';
import { useProjects } from '../../hooks/useProjects';
import { useBrands } from '../../hooks/useBrands';

import FormikInput from '../FormikInput';
import FormikCombobox from '../FormikCombobox';

type OverviewFormProps = {
  isSubmitting: boolean;
  values: SPDTO;
};

const OverviewForm: React.FC<OverviewFormProps> = props => {
  const brands = useBrands();
  const projects = useProjects();

  return (
    <div className="shadow sm:rounded-lg bg-white px-4 py-6 sm:p-6 md:p-8 lg:p-12">
      <fieldset
        className="grid sm:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-6"
        disabled={props.isSubmitting}
      >
        <Field
          name="brand"
          component={FormikCombobox}
          label="Brand*"
          data={brands.data}
          placeholder="Start typing to select brand"
          itemToString={(brand: Brand) => (brand ? brand.name : '')}
          renderItem={(brand: Brand) => brand.name}
          autoComplete="off"
        />
        <Field
          name="project"
          component={FormikCombobox}
          label="Project"
          data={
            projects.data?.filter(
              project =>
                project.brandId ===
                (props.values.brand ? props.values.brand.id : '')
            ) || []
          }
          placeholder="Start typing to select project"
          itemToString={(project: Project) => (project ? project.name : '')}
          renderItem={(project: Project) => project.name}
        />
        <Field
          name="name"
          component={FormikInput}
          label="Plan title*"
          placeholder="Name your plan"
        />
      </fieldset>
    </div>
  );
};

export default OverviewForm;
