import React from 'react';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useMutation } from 'react-query';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import clsx from 'clsx';

import authService from '../../services/authService';
import FormikPasswordInput from '../FormikPasswordInput';

const schema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, 'Password must contain at least 8 characters')
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Password must contain at least 8 characters, one number, one uppercase letter'
    ),
});

const initialValues: { newPassword: string } = {
  newPassword: '',
};

const ResetPasswordForm: React.FC = () => {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const oobCode = query.get('oobCode');
  const resetPassword = useMutation(authService.resetPassword, {
    onSuccess: () => {
      history.push('/auth/login?password_reset=true');
    },
    onError: () => {
      toast.error('Your verification code may have expired.');
    },
  });

  if (!oobCode) {
    return <Redirect to="/auth/login" />;
  }

  const handleOnSubmit = async (values: {
    newPassword: string;
  }): Promise<void> => {
    try {
      await resetPassword.mutateAsync({
        newPassword: values.newPassword,
        oobCode: oobCode,
      });
    } catch {}
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleOnSubmit}
    >
      {({ isSubmitting }: FormikProps<{ newPassword: string }>) => (
        <Form className="space-y-6 mt-16" noValidate>
          <Field
            component={FormikPasswordInput}
            label="New Password"
            name="newPassword"
            autoComplete="new-password"
            helperText="Passwords should be at least 8 characters long."
            autoFocus
          />
          <button
            type="submit"
            className={clsx({
              'btn btn-primary btn-full': true,
              'animate__animated animate__shakeX': resetPassword.isError,
            })}
            disabled={isSubmitting}
          >
            Send Password Reset
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
