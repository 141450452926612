import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/solid';

type QuestionContainerProps = {
  name: string;
  description?: string;
};

const QuestionContainer: React.FC<QuestionContainerProps> = props => {
  return (
    <div className="p-4 sm:p-6 shadow sm:rounded-lg bg-white">
      <p className="text-base leading-6 font-semibold">{props.name}</p>
      {props.description ? (
        <div className="rounded-md bg-green-50 p-4 mt-6">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon
                className="h-5 w-5 text-green-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-1">
              <p className="text-sm font-medium text-green-800">Tips</p>
            </div>
          </div>
          <div className="mt-2 text-sm text-green-700">
            <p>{props.description}</p>
          </div>
        </div>
      ) : null}
      <div className="border-gray-200 border-b w-full mt-6" />
      <div className="mt-6">{props.children}</div>
    </div>
  );
};

export default QuestionContainer;
