import React from 'react';
import { UserGroupIcon } from '@heroicons/react/solid';

import { CheckoutForm } from '../../types/checkout';

type SummaryProps = {
  values: CheckoutForm;
};

const Summary: React.FC<SummaryProps> = ({ values }) => {
  const discount = values.promotionCode;

  const generatePrice = (): { price: string; discount: string | null } => {
    if (discount?.id && discount.coupon.amount_off) {
      const total =
        values.type.price * values.numberOfSeats - discount.coupon.amount_off;
      return {
        price: Math.max(0, total).toFixed(2),
        discount: discount.coupon.amount_off,
      };
    }

    if (discount?.id && discount.coupon.percent_off) {
      const discountInPounds =
        (values.type.price *
          values.numberOfSeats *
          discount.coupon.percent_off) /
        100;

      const total =
        (values.type.price *
          values.numberOfSeats *
          (100 - discount.coupon.percent_off)) /
        100;

      return {
        price: Math.max(0, total).toFixed(2),
        discount: discountInPounds.toFixed(2),
      };
    }

    const total = values.type.price * values.numberOfSeats;
    return {
      price: total.toFixed(2),
      discount: null,
    };
  };

  return (
    <div>
      <span className="text-secondary-100 text-lg font-semibold">Plan</span>
      <h4 className="text-2xl md:text-4xl text-white font-medium mb-8 md:mb-12">
        {values.type.name}
      </h4>
      <ul className="text-lg md:text-xl font-medium">
        <li className="flex items-start space-x-4">
          <div className="bg-secondary-500 w-16 h-16 rounded-md p-2">
            <UserGroupIcon className="flex-none object-center object-cover text-white" />
          </div>
          <div className="flex-auto space-y-1">
            <h3 className="text-white">Number of Seats</h3>
            <p className="text-secondary-100">{values.numberOfSeats}</p>
          </div>
          <p className="flex-none text-base font-medium text-secondary-100 text-lg md:text-xl">
            £{values.type.price.toFixed(2)}/mo
          </p>
        </li>
      </ul>
      <dl className="text-lg md:text-xl text-secondary-100 font-medium space-y-3 md:space-y-6 pt-6">
        <div className="flex items-center justify-between">
          <dt>Subtotal</dt>
          <dd>
            £{(values.type.price * values.numberOfSeats).toFixed(2)}
            /mo
          </dd>
        </div>

        <p className="border-t border-secondary-100 flex w-full mb-0" />
        {discount?.id ? (
          <div className="flex items-center justify-between">
            <dt>Discount Applied</dt>
            <dd>-£{generatePrice().discount}</dd>
          </div>
        ) : null}

        <div className="flex items-center justify-between text-white">
          <dt>Total</dt>
          <dd>£{generatePrice().price}/mo</dd>
        </div>
      </dl>
    </div>
  );
};

export default Summary;
