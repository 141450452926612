import React from 'react';
import clsx from 'clsx';
import { NavLink, useLocation } from 'react-router-dom';
import { CheckCircleIcon } from '@heroicons/react/solid';

import { SPSection } from '../../types/strategic-plan';

type SectionNavigationItemProps = {
  type: 'new' | 'edit';
  section: SPSection;
  brandId: string;
  strategicPlanId: string | undefined;
  isFinished: boolean;
};

const SectionNavigationItem: React.FC<SectionNavigationItemProps> = props => {
  const location = useLocation();

  if (props.type === 'new') {
    return (
      <span className="flex items-center text-gray-500 font-medium">
        <svg
          className="mr-1.5 h-4 w-4 rounded-full text-gray-300 border-4 border-white"
          fill="currentColor"
          viewBox="0 0 8 8"
        >
          <circle cx={4} cy={4} r={4} />
        </svg>

        {props.section.name}
      </span>
    );
  }

  return (
    <NavLink
      className="flex items-center text-gray-500 font-medium"
      activeClassName="text-secondary-600"
      to={`/strategic-planning/${props.brandId}/${props.strategicPlanId}/section/${props.section.id}`}
    >
      {props.isFinished &&
      `/strategic-planning/${props.brandId}/${props.strategicPlanId}/section/${props.section.id}` !==
        location.pathname ? (
        <CheckCircleIcon className="w-4 h-4 text-secondary-600 mr-1.5" />
      ) : (
        <svg
          className={clsx(
            'mr-1.5 h-4 w-4 rounded-full text-gray-300 border-4 border-white',
            {
              'border-secondary-200 text-secondary-600':
                `/strategic-planning/${props.brandId}/${props.strategicPlanId}/section/${props.section.id}` ===
                location.pathname,
            }
          )}
          fill="currentColor"
          viewBox="0 0 8 8"
        >
          <circle cx={4} cy={4} r={4} />
        </svg>
      )}
      {props.section.name}
    </NavLink>
  );
};

export default SectionNavigationItem;
