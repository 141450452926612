import React from 'react';

type DetailGroupProps = {
  name: string;
  description: string;
};

type DetailItemProps = {
  name: string;
  value: string | React.ReactElement | undefined;
};

const DetailGroup: React.FC<DetailGroupProps> & {
  Item: React.FC<DetailItemProps>;
} = props => {
  return (
    <div className="px-4 py-5 sm:p-0">
      <dl className="sm:divide-y sm:divide-gray-200">
        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-12 sm:gap-4 sm:px-6">
          <div className="block sm:mt-px sm:pt-2 sm:col-span-3">
            <p className="text-gray-700 font-medium">{props.name}</p>
            <p className="text-gray-500 text-sm">{props.description}</p>
          </div>
          <div className="mt-1 sm:mt-0 sm:col-span-9 sm:grid sm:gap-4 sm:px-6">
            <dl>{props.children}</dl>
          </div>
        </div>
      </dl>
    </div>
  );
};

const DetailItem: React.FC<DetailItemProps> = props => {
  return (
    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt className="text-sm font-medium text-gray-500">{props.name}</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        {props.value ? props.value : '-'}
      </dd>
    </div>
  );
};

DetailGroup.Item = DetailItem;

export default DetailGroup;
