import { DocumentReference, getDoc, DocumentData } from 'firebase/firestore';

const transformDoc = async <Type>(
  docRef: DocumentReference<DocumentData>
): Promise<Type | null> => {
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data() as Type;
  }
  return null;
};

export default transformDoc;
