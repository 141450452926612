import React from 'react';
import ErrorPage from '../components/ErrorPage';
import InputSkeleton from '../components/InputSkeleton';
import DeleteOrganisation from '../components/Settings/DeleteOrganisation';
import OrganisationForm from '../components/Settings/OrganisationForm';
import RequestData from '../components/Settings/RequestData';
import { useUser } from '../hooks/useUser';

const Organisation: React.FC = () => {
  const user = useUser();

  const UpdateForm: React.FC = () => {
    if (user.isLoading)
      return (
        <div className="space-y-6">
          <InputSkeleton />
        </div>
      );

    if (!user.data) return <ErrorPage />;

    return (
      <OrganisationForm
        name={user.data.organisation.name ?? ''}
        workingHoursPerWeek={user.data.organisation.workingHoursPerWeek ?? 0}
      />
    );
  };

  return (
    <section className="bg-white p-6 shadow sm:rounded-md sm:overflow-hidden mb-6 max-w-screen-lg w-full ml-auto mr-auto">
      <h1 className="text-lg leading-6 font-medium text-gray-900">
        Manage Organisation
      </h1>
      <p className="text-sm text-gray-500 leading-5 font-normal">
        Changes will be reflected immediately.
      </p>
      <UpdateForm />
      <RequestData />
      <DeleteOrganisation />
    </section>
  );
};

export default Organisation;
