import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { SelectorIcon } from '@heroicons/react/solid';
import clsx from 'clsx';

type FilterSelectProps = {
  label: string;
  mapItem: (item: { id: string; label: string }) => React.ReactElement;
  selectPlaceholder: string;
  data: Array<{ id: string; label: string }> | undefined;
  onChange: (a: any) => void;
};

const FilterSelect: React.FC<FilterSelectProps> = props => {
  return (
    <Listbox
      onChange={props.onChange}
      disabled={!props.data || props.data.length < 1}
      value=""
    >
      {({ open }) => (
        <div>
          <Listbox.Label className="block text-sm font-medium text-gray-700">
            {props.label}
          </Listbox.Label>
          <div className="mt-1 relative">
            <Listbox.Button className="cursor-pointer bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-secondary-700 focus:border-indigo-500 sm:text-sm">
              <span className="block truncate">{props.selectPlaceholder}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {props.data?.map(item => (
                  <Listbox.Option
                    key={item.id}
                    className={({ active }) =>
                      clsx(
                        active
                          ? 'text-white bg-secondary-600'
                          : 'text-gray-900',
                        'cursor-pointer select-none relative py-2 pl-3 pr-9'
                      )
                    }
                    value={item}
                  >
                    {props.mapItem(item)}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
};

export default FilterSelect;
