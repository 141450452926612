import React from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { UserGroupIcon } from '@heroicons/react/solid';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useMutation, useQueryClient } from 'react-query';
import toast from 'react-hot-toast';

import { useSubscription } from '../../hooks/useSubscription';
import organisationService from '../../services/organisationService';
import { MemberCount, SubscriptionDetails } from '../../types/organisation';

const ChangeSeatNumber: React.FC = () => {
  const details = useSubscription();
  const queryClient = useQueryClient();

  const changeSeats = useMutation(organisationService.changeNumberOfSeats, {
    onSuccess: async (_, values) => {
      toast.success('The number of seats has been successfully updated.');
      // update the member-count data dynamically
      const previousMemberCount =
        queryClient.getQueryData<MemberCount>('member-count');
      const previousSubscription =
        queryClient.getQueryData<SubscriptionDetails>('subscription-details');

      if (previousSubscription) {
        const newSubscription: SubscriptionDetails = {
          ...previousSubscription,
          seats: {
            ...previousSubscription.seats,
            nbOrganisationSeats: values.newNumberOfSeats || 0,
          },
        };

        queryClient.setQueryData<SubscriptionDetails>(
          'subscription-details',
          newSubscription
        );
      }

      if (previousMemberCount) {
        const newMemberCount = {
          used: previousMemberCount?.used || 0,
          nbOrganisationSeats:
            parseInt(values.newNumberOfSeats.toString()) || 0,
        };
        queryClient.setQueryData<MemberCount | undefined>(
          'member-count',
          newMemberCount
        );
      }
    },
    onError: (err, variables, previousValue) => {
      toast.error('Updating the number of seats failed. Try again!');
      queryClient.setQueryData('member-count', previousValue);
    },
  });

  const handleOnSubmit = async (
    values: { seatNumber: number },
    formikBag: FormikHelpers<{ seatNumber: number }>
  ) => {
    try {
      await changeSeats.mutateAsync({
        newNumberOfSeats: values.seatNumber,
      });
    } catch (e) {
      //
    }
  };

  const initialValues: { seatNumber: number } = {
    seatNumber: details.data?.seats.nbOrganisationSeats || 0,
  };

  const schema = Yup.object().shape({
    seatNumber: Yup.number()
      .typeError('You must specify a number')
      .min(
        details.data?.seats.used || 0,
        'You must delete users before you can reduce the number of seats.'
      )
      .required('You must specify a number'),
  });

  if (details.isLoading) {
    return (
      <section aria-labelledby="number-of-seats-heading">
        <div className="bg-white pt-6 shadow sm:rounded-md sm:overflow-hidden mb-6">
          <div className="px-4 sm:px-6">
            <h2
              id="number-of-seats-heading"
              className="text-lg leading-6 font-medium text-gray-900"
            >
              Number of Seats
            </h2>
            <p className="text-sm text-gray-500 leading-5 font-normal">
              Update how many seats you require. Changes will be reflected
              immediately. You must delete users before you can reduce the
              number of seats.
            </p>
          </div>
          <div className="p-6">
            <div className="w-full h-24 w-60 bg-gray-100 border-gray-300 rounded-lg shadow-sm animate-pulse" />
          </div>
        </div>
      </section>
    );
  }

  return (
    <section aria-labelledby="number-of-seats-heading">
      <div className="bg-white pt-6 shadow sm:rounded-md sm:overflow-hidden mb-6">
        <div className="px-4 sm:px-6">
          <h2
            id="number-of-seats-heading"
            className="text-lg leading-6 font-medium text-gray-900"
          >
            Number of Seats
          </h2>
          <p className="text-sm text-gray-500 leading-5 font-normal">
            Update how many seats you require. Changes will be reflected
            immediately. You must delete users before you can reduce the number
            of seats.
          </p>
        </div>
        <div className="p-6">
          <div className="bg-gray-50 p-6 border-gray-300 border rounded-xl">
            <p className="text-sm text-gray-500 mb-3">
              {details.data?.seats.used} of{' '}
              {details.data?.seats.nbOrganisationSeats} seats used currently.
            </p>
            <label className="block text-sm font-medium text-gray-700">
              Number of Seats
            </label>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={schema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={handleOnSubmit}
            >
              {({
                isSubmitting,
                dirty,
                values,
                setFieldValue,
                errors,
              }: FormikProps<{ seatNumber: number }>) => (
                <Form className="flex space-x-3 items-start" noValidate>
                  <div className="w-full">
                    <div
                      className={clsx({
                        'input mt-1 relative bg-white': true,
                        'input-error': errors.seatNumber,
                      })}
                    >
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <UserGroupIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        type="tel"
                        name="seatNumber"
                        value={values.seatNumber}
                        onChange={e =>
                          setFieldValue('seatNumber', e.currentTarget.value)
                        }
                        disabled={isSubmitting}
                        className={clsx({
                          'ml-7 sm:text-sm border-0 focus:outline-none block w-full px-3 py-2 appearance-none rounded-md placeholder-gray-400':
                            true,
                        })}
                      />
                    </div>
                    {errors.seatNumber ? (
                      <p
                        className="mt-1 text-xs text-red-900"
                        id={`seatNumber-error`}
                      >
                        {errors.seatNumber}
                      </p>
                    ) : null}
                  </div>
                  <div className="mt-1">
                    <button
                      className="btn btn-secondary"
                      type="submit"
                      disabled={!dirty || isSubmitting}
                    >
                      Update
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChangeSeatNumber;
