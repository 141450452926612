import assign from 'lodash/assign';
import { QueryFunctionContext } from 'react-query';
import isNumber from 'lodash/isNumber';

import axios from '../utils/axios';
import {
  AlgoliaTask,
  EditTaskDTO,
  Task,
  TaskDTO,
  TaskFilter,
  TaskTemplate,
  TaskTemplateDTO,
} from '../types/task';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { db } from '../utils/firebase';
import algolia from '../utils/algolia';
import { UserRole } from '../types/user';

class TaskService {
  createTask = async (data: TaskDTO): Promise<Task> => {
    // delete, because of the API's validation
    const { template, ...taskData } = data;
    if (!taskData.deadline) {
      delete taskData.deadline;
    }
    if (taskData.project) {
      assign(taskData, { project: taskData.project.id });
    }

    if (taskData.brand) {
      assign(taskData, { brand: taskData.brand.id });
    }

    // Create new task (NOT from template)
    if (data.template === 'new') {
      if (!taskData.assignees) {
        delete taskData.assignees;
      } else {
        assign(taskData, {
          assignees: taskData.assignees.map(assignee => {
            const estimateInHours = Number(assignee.estimateInHours);

            return {
              userOrInviteId: assignee.userOrInviteId,
              estimateInHours: isNumber(estimateInHours)
                ? estimateInHours
                : null,
            };
          }),
        });
      }

      const response = await axios.post<Task>(`/tasks/`, taskData);

      return response.data;
    }

    // Create task from template
    const response = await axios.post<Task>(
      `/tasks/actions/create-from-template`,
      {
        template: data.template,
        brand: taskData.brand,
        project: taskData.project,
        deadline: taskData.deadline,
      }
    );

    return response.data;
  };

  getTasks = async (
    organisationId: string,
    filter: TaskFilter,
    context: QueryFunctionContext,
    userRole: UserRole,
    enabledProjects: string[]
  ): Promise<AlgoliaTask> => {
    let indexName = `${process.env.REACT_APP_ALGOLIA_INDEX_PREFIX}tasks`;

    /**
     * Make sure if you added the corresponding index to algolia too
     */
    if (filter.orderBy.length > 0) {
      indexName = `${process.env.REACT_APP_ALGOLIA_INDEX_PREFIX}tasks_${
        filter.orderBy[0].id
      }_${filter.orderBy[0].desc ? 'desc' : 'asc'}`;
    }

    const index = algolia.initIndex(indexName);
    const filters = [`organisation.id:${organisationId}`];

    /**
     * status filter (tab navigation)
     */
    if (filter.status && filter.status !== 'all') {
      let queryString;

      switch (filter.status) {
        case 'done':
          queryString = 'DONE';
          break;
        case 'to-do':
          queryString = 'TODO';
          break;
        case 'in-progress':
          queryString = 'IN_PROGRESS';
          break;
      }

      filters.push(`status:${queryString}`);
    }

    /**
     * filter for brands
     */
    if (filter.brands.length > 0) {
      filters.push(
        filter.brands.map(brand => `brand.id:${brand.id}`).join(' OR ')
      );
    }

    /**
     * filter for assignees
     */
    if (filter.assignees.length > 0) {
      filters.push(
        filter.assignees
          .map(assignee => `assigneeIds:${assignee.id}`)
          .join(' OR ')
      );
    }

    /**
     * filter for projects
     */
    if (filter.projects.length > 0) {
      filters.push(
        filter.projects.map(project => `project.id:${project.id}`).join(' OR ')
      );
    } else {
      // VIEWER or MEMBER users can access only those project's tasks which are assigned to their profile
      if (userRole === 'VIEWER' || userRole === 'MEMBER') {
        // no projects were assigned to the user, that's why no task are available for sure
        if (enabledProjects.length < 1) {
          return {
            hits: [],
            page: 0,
            nbHits: 0,
            nbPages: 0,
            hitsPerPage: 25,
          };
        }
        filters.push(
          enabledProjects
            .map(projectId => `project.id:${projectId}`)
            .join(' OR ')
        );
      }
    }

    /**
     * Deadline filter
     */
    if (filter.deadlineFrom) {
      const deadlineFrom = new Date(filter.deadlineFrom);
      filters.push(`deadline >= ${deadlineFrom.getTime()}`);
    }
    if (filter.deadlineTo) {
      const deadlineTo = new Date(filter.deadlineTo);
      filters.push(`deadline <= ${deadlineTo.getTime()}`);
    }

    const response = await index.search<Exclude<Task, 'id'>>(filter.text, {
      filters: filters.join(' AND '),
      hitsPerPage: 25,
      page: context.pageParam || 0,
      cacheable: false,
    });

    return {
      ...response,
      hits: response.hits.map(task => ({
        ...task,
        id: task.objectID,
        deadline: task.deadline ? new Date(task.deadline).toISOString() : null,
      })),
    };
  };

  getTemplates = async (organisationId: string): Promise<TaskTemplate[]> => {
    const q = query(
      collection(db, 'task-templates'),
      where('organisation.id', '==', organisationId),
      orderBy('createdAt', 'desc')
    );

    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return [];
    }

    return querySnapshot.docs.map(doc => {
      return {
        ...doc.data(),
        id: doc.id,
        createdAt: doc.data().createdAt
          ? doc.data().createdAt.toDate().toISOString()
          : null,
        updatedAt: doc.data().updatedAt
          ? doc.data().updatedAt.toDate().toISOString()
          : null,
      } as TaskTemplate;
    });
  };

  updateTask = async (data: {
    values: EditTaskDTO;
    id: string;
  }): Promise<Task> => {
    const { ...taskData } = data.values;

    if (!taskData.deadline) {
      delete taskData.deadline;
    }
    if (taskData.project) {
      assign(taskData, { project: taskData.project.id });
    }

    if (taskData.brand) {
      assign(taskData, { brand: taskData.brand.id });
    }

    if (!taskData.assignees) {
      delete taskData.assignees;
    } else {
      assign(taskData, {
        assignees: taskData.assignees.map(assignee => {
          const estimateInHours = Number(assignee.estimateInHours);

          return {
            userOrInviteId: assignee.userOrInviteId,
            estimateInHours: isNumber(estimateInHours) ? estimateInHours : null,
          };
        }),
      });
    }

    const response = await axios.patch<Task>(`/tasks/${data.id}`, taskData);

    return response.data;
  };

  createTaskTemplate = async (data: TaskTemplateDTO): Promise<TaskTemplate> => {
    const response = await axios.post<TaskTemplate>('/task-templates', data);

    return response.data;
  };

  updateTaskTemplate = async (data: {
    values: TaskTemplateDTO;
    templateId: string;
  }): Promise<TaskTemplate> => {
    const response = await axios.patch<TaskTemplate>(
      `/task-templates/${data.templateId}`,
      data.values
    );

    return response.data;
  };

  deleteTaskTemplate = async (templateId: string): Promise<TaskTemplate> => {
    const response = await axios.delete<TaskTemplate>(
      `/task-templates/${templateId}`
    );

    return response.data;
  };

  deleteTask = async (taskId: string): Promise<Task> => {
    const response = await axios.delete<Task>(`/tasks/${taskId}`);

    return response.data;
  };
}

const taskService = new TaskService();

export default taskService;
