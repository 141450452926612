import React from 'react';
import { Column } from 'react-table';

import { Task } from '../../types/task';
import Table from '../Table';

const WIPTableSkeleton: React.FC = () => {
  const columns = React.useMemo<Column<Task>[]>(
    () => [
      {
        id: 'project',
        Header: 'Project',
        disableSortBy: true,
        accessor: () => (
          <span className="block w-36 h-3  bg-gray-200 rounded animate-pulse" />
        ),
        enableFooter: true,
        Footer: () => <span className="text-gray-900">Total Estimate</span>,
      },
      {
        id: 'activity',
        Header: 'Activity',
        disableSortBy: true,

        accessor: () => (
          <span className="block w-36 h-3  bg-gray-200 rounded animate-pulse" />
        ),
      },
      {
        id: 'name',
        Header: 'Task',
        disableSortBy: true,

        accessor: () => (
          <span className="block w-36 h-3  bg-gray-200 rounded animate-pulse" />
        ),
      },
      {
        id: 'estimateInHours',
        Header: 'Estimate (hours)',
        disableSortBy: true,
        accessor: () => (
          <span className="block w-20 h-3  bg-gray-200 rounded animate-pulse" />
        ),
      },
      {
        id: 'assignees',
        Header: 'Assignees',
        disableSortBy: true,
        accessor: () => (
          <span className="block w-36 h-3  bg-gray-200 rounded animate-pulse" />
        ),
      },
      {
        id: 'deadline',
        Header: 'Deadline',
        disableSortBy: true,
        accessor: () => (
          <span className="block w-28 h-3  bg-gray-200 rounded animate-pulse" />
        ),
      },
      {
        id: 'status',
        Header: 'Status',
        disableSortBy: true,
        accessor: () => (
          <span className="block w-28 h-3  bg-gray-200 rounded animate-pulse" />
        ),
      },
      {
        id: 'edit',
        disableSortBy: true,
        accessor: () => (
          <span className="block w-16 h-3  bg-gray-200 rounded animate-pulse" />
        ),
      },
    ],
    []
  );

  const data = new Array(6).fill('');

  return <Table columns={columns} data={data} />;
};

export default WIPTableSkeleton;
