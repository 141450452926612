import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';

import authService from '../services/authService';

import AuthLayoutHeader from '../components/auth/AuthLayoutHeader';
import AcceptInvitationForm from '../components/auth/AcceptInvitationForm';
import InputSkeleton from '../components/InputSkeleton';
import ErrorPage from '../components/ErrorPage';

const AcceptInvitation: React.FC = () => {
  const query = new URLSearchParams(useLocation().search);
  const inviteToken = query.get('inviteToken');

  const invitation = useQuery(
    ['invitation', inviteToken],
    () => authService.getInvitation(inviteToken || ''),
    {
      enabled: !!inviteToken,
    }
  );

  if (!inviteToken) {
    return <Redirect to="/auth/login" />;
  }

  if (invitation.isError) {
    return (
      <ErrorPage text="The invitation has already been redeemed or has expired." />
    );
  }

  return (
    <>
      <AuthLayoutHeader
        title="Create Account"
        backButtonLink="/"
        backButtonText="Back to Homepage"
        subTitle={
          invitation.isLoading ? (
            <p className="mt-2 text-sm text-gray-600">You've been invited</p>
          ) : (
            <p className="mt-2 text-sm text-gray-600">
              You've been invited to join{' '}
              <span className="font-medium text-secondary-500">
                {invitation.data?.organisation.name}
              </span>
            </p>
          )
        }
      />
      <div className="mt-8">
        {invitation.isLoading || !invitation.data ? (
          <div className="space-y-6">
            <div className="flex space-x-5">
              <div className="w-1/2 space-y-2">
                <InputSkeleton />
              </div>
              <div className="w-1/2 space-y-2">
                <InputSkeleton />
              </div>
            </div>
            <InputSkeleton />
            <InputSkeleton />
            <InputSkeleton />
            <div className="h-16 bg-gray-200 rounded w-full animate-pulse" />
            <div className="h-9 bg-secondary-500 rounded w-full animate-pulse" />
          </div>
        ) : (
          <AcceptInvitationForm {...invitation.data} />
        )}
      </div>
    </>
  );
};

export default AcceptInvitation;
