import { useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import { brandKeys } from '../config';
import brandService from '../services/brandService';
import { useUser } from './useUser';
import { Brand } from '../types/brand';

export function useBrands() {
  const user = useUser();

  return useQuery(
    brandKeys.all,
    () => brandService.getBrands(user?.data?.organisation.id || ''),
    {
      enabled: !user.isLoading && !!user?.data?.organisation.id,
    }
  );
}

export function useBrand() {
  const { brandId } = useParams<{ brandId: string }>();
  const history = useHistory();
  const queryClient = useQueryClient();

  return useQuery(
    brandKeys.single(brandId),
    () => brandService.getBrand(brandId),
    {
      enabled: !!brandId,
      placeholderData: () => {
        const brands = queryClient.getQueryData<Brand[]>(brandKeys.all);

        if (!brands) return undefined;

        return brands.find(brand => brand.id === brandId);
      },
      onError: () => {
        history.push('/brands');
      },
    }
  );
}
