import { useQuery } from 'react-query';
import toast from 'react-hot-toast';

import { useUser } from './useUser';
import { resourcePlannerKeys } from '../config';
import resourcePlannerService from '../services/resourcePlannerService';

export function useSettings() {
  const user = useUser();

  return useQuery(
    resourcePlannerKeys.settings,
    () => resourcePlannerService.getSettings(user.data?.organisation.id || ''),
    {
      enabled: !user.isLoading && !!user.data,
      onError: () => {
        toast.error('Try again! Something went wrong.');
      },
    }
  );
}
