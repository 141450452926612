import React from 'react';
import { FieldProps, getIn } from 'formik';
import clsx from 'clsx';
import { ExclamationCircleIcon } from '@heroicons/react/outline';

const FormikInput: React.ComponentType<FieldProps & { label: string }> = ({
  field,
  form,
  label,
  ...rest
}) => {
  const error = getIn(form.errors, field.name);
  const isTouched = getIn(form.touched, field.name);
  const hasError = isTouched && error;

  return (
    <div>
      <label
        htmlFor={field.name}
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div
        className={clsx({
          'input mt-1 relative': true,
          'input-error': hasError,
        })}
      >
        <input
          id={field.name}
          className={clsx({
            'bg-white sm:text-sm border-0 focus:outline-none block w-full px-3 py-2 appearance-none rounded-md placeholder-gray-400 disabled:bg-gray-50 disabled:text-gray-400':
              true,
            'text-red-900 placeholder-red-300': hasError,
          })}
          {...field}
          {...rest}
          value={field.value ?? ''} // to avoid React initial value warning
        />
        {hasError ? (
          <div className="inset-y-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-400"
              aria-hidden="true"
            />
          </div>
        ) : null}
      </div>
      {hasError ? (
        <p className="mt-1 text-xs text-red-900" id={`${field.name}-error`}>
          {error}
        </p>
      ) : null}
    </div>
  );
};

export default FormikInput;
