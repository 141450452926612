import { QueryClient } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: process.env.NODE_ENV === 'production', //in development it would refetch all the time..
      refetchOnReconnect: true,
      staleTime: 1000 * 60,
      retry: false,
    },
  },
});

export { queryClient };
