import { useQuery } from 'react-query';
import { User } from '../types/user';
import authService from '../services/authService';

export function usePermission() {
  return useQuery<
    User,
    any,
    {
      isAdmin: boolean;
      isMember: boolean;
      isOwner: boolean;
      isViewer: boolean;
      canEditProjectById: (id: string) => boolean;
      canViewProjectById: (id: string) => boolean;
    }
  >('user', () => authService.getUser(), {
    select: data => {
      return {
        isOwner: data.role === 'OWNER',
        isAdmin: data.role === 'ADMIN' || data.role === 'OWNER',
        isMember: data.role === 'MEMBER',
        isViewer: data.role === 'VIEWER',
        canEditProjectById: projectId => {
          if (data.role === 'ADMIN' || data.role === 'OWNER') {
            return true;
          }

          if (data.role === 'VIEWER') return false;

          if (!data.projects) return false;

          return data.projects.includes(projectId);
        },
        canViewProjectById: projectId => {
          if (data.role === 'ADMIN' || data.role === 'OWNER') {
            return true;
          }

          return data.projects.includes(projectId);
        },
      };
    },
  });
}
