import * as Yup from 'yup';
import React from 'react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { usePMUsers } from '../../hooks/usePMUsers';
import { usePermission } from '../../hooks/usePermission';
import { Project, ProjectDTO, ProjectError } from '../../types/project';
import projectService from '../../services/projectService';
import { projectKeys } from '../../config';

import Drawer from '../Drawer';
import Spinner from '../../Spinner';
import ProjectForm from './ProjectForm';
import DeleteButton from '../DeleteButton';

type EditProjectDrawerProps = {
  onClose: () => void;
  open: boolean;
  data: Project | null;
};

const schema = Yup.object().shape({
  name: Yup.string().required('First name is required'),
  projectManager: Yup.object().required('Select a project manager'),
  projectEndDate: Yup.date()
    .min(new Date(), 'Select date in the future')
    .max(new Date('9999-12-30'), 'Must be a valid date.'),
  keyContact: Yup.object().shape({
    fullName: Yup.string(),
    email: Yup.string().email('Must be a valid email'),
    phoneNumber: Yup.string(),
  }),
  billingContact: Yup.object().shape({
    fullName: Yup.string(),
    email: Yup.string().email('Must be a valid email'),
    phoneNumber: Yup.string(),
  }),
  billingInstructions: Yup.string(),
  isSecret: Yup.boolean(),
});

const EditProjectDrawer: React.FC<EditProjectDrawerProps> = props => {
  const queryClient = useQueryClient();
  const users = usePMUsers();
  const { brandId } = useParams<{ brandId: string }>();
  const permission = usePermission();

  const updateProject = useMutation(projectService.updateProject, {
    onError: () => {
      toast.error('Something went wrong. Try again!');
    },
    onSettled: async () => {
      await queryClient.refetchQueries(projectKeys.allByBrand(brandId));
    },
  });

  const deleteProject = useMutation(projectService.deleteProject, {
    onError: () => {
      toast.error('Something went wrong. Try again!');
    },
    onSuccess: () => {
      props.onClose();
      toast.success('The project has been successfully deleted!');
    },
    onSettled: async () => {
      await queryClient.refetchQueries(projectKeys.allByBrand(brandId));
    },
  });

  const handleProjectDelete = () => {
    deleteProject.mutate({
      brandId: brandId,
      projectId: props.data?.id || '',
    });
  };

  const initialValues: ProjectDTO = {
    name: props.data?.name || '',
    projectManager: props.data?.projectManager || '',
    projectEndDate: props.data?.projectEndDate
      ? format(parseISO(props.data.projectEndDate), 'yyyy-MM-dd')
      : '',
    keyContact: props.data?.keyContact || {
      fullName: '',
      email: '',
      phoneNumber: '',
    },
    billingContact: props.data?.billingContact || {
      fullName: '',
      email: '',
      phoneNumber: '',
    },
    billingInstructions: props.data?.billingInstructions || '',
    isSecret: props.data?.isSecret || false,
  };

  const handleOnSubmit = async (
    values: ProjectDTO,
    formikBag: FormikHelpers<ProjectDTO>
  ) => {
    try {
      await updateProject.mutateAsync({
        data: values,
        brandId: brandId,
        projectId: props.data?.id || '',
      });
      props.onClose();
    } catch (e) {
      const error = e as ProjectError;

      switch (error.code) {
        case 'projects/project-name-already-in-use':
          formikBag.setFieldError('name', error.detail);
          break;
        case 'projects/cannot-set-project-manager':
          formikBag.setFieldError('projectManager', error.detail);
          break;
      }
    }
  };

  return (
    <Drawer open={props.open} onClose={props.onClose}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleOnSubmit}
      >
        {({ isSubmitting, dirty }: FormikProps<ProjectDTO>) => (
          <Drawer.FormContainer
            open={props.open}
            onClose={props.onClose}
            title="Edit Project"
            description="Update details relating to this project."
            Footer={
              <>
                <button
                  className="btn btn-white"
                  onClick={props.onClose}
                  disabled={isSubmitting}
                  type="button"
                >
                  Cancel
                </button>
                {permission.data?.isAdmin ? (
                  <DeleteButton
                    modalTitle="Delete project"
                    modalText="Once you delete this project, you will lose all data associated with it. This action is irreversible."
                    className="btn btn-delete capitalize ml-4"
                    disabled={isSubmitting || deleteProject.isLoading}
                    type="button"
                    onClick={handleProjectDelete}
                  >
                    {deleteProject.isLoading ? 'Deleting' : 'Delete'}
                  </DeleteButton>
                ) : null}
                <button
                  className="btn btn-primary ml-4"
                  type="submit"
                  disabled={isSubmitting || !dirty}
                >
                  {isSubmitting ? (
                    <>
                      <Spinner
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      Loading
                    </>
                  ) : (
                    'Update Project'
                  )}
                </button>
              </>
            }
          >
            <ProjectForm isSubmitting={isSubmitting} users={users.data || []} />
          </Drawer.FormContainer>
        )}
      </Formik>
    </Drawer>
  );
};

export default EditProjectDrawer;
