import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import omit from 'lodash/omit';
import { QueryFunctionContext } from 'react-query';
import {
  Invoice,
  Member,
  MemberCount,
  Organisation,
  PaymentMethod,
  SubscriptionDetails,
} from '../types/organisation';
import { InvitationResponse, MembersResponse } from '../types/sign-up';
import { EditUserValues, User } from '../types/user';
import axios from '../utils/axios';
import { db } from '../utils/firebase';
import transformDoc from '../utils/transform-doc';

class OrganisationService {
  getOrganisation = async (id: string): Promise<Organisation> => {
    const docRef = doc(db, 'organisations', id);

    const organisation = await transformDoc<Organisation>(docRef);

    if (!organisation) {
      throw Error('User not logged in');
    }

    return organisation;
  };

  getTeamMembers = async (): Promise<Member[]> => {
    const response = await axios.get<Array<MembersResponse>>(
      '/organisations/members'
    );

    const usersAndInvitesList = response.data;

    return usersAndInvitesList.map(item => {
      if (item.type === 'USER') {
        return {
          id: item.user.id,
          firstName: item.user.firstName,
          lastName: item.user.lastName,
          role: item.user.role,
          type: 'USER',
          email: item.user.email,
          mobileNumber: item.user?.mobileNumber || '',
          blocked: item.user.blocked,
          fullTimeEquivalent: item.user.fullTimeEquivalent,
          isSubscribedToMarketing: item.user.isSubscribedToMarketing,
          projects: item.user.projects,
          organisation: item.user.organisation,
          createdAt: item.user.createdAt,
        };
      }

      //its an invite
      return {
        id: item.invite.id,
        firstName: item.invite.firstName,
        lastName: item.invite?.lastName || '',
        role: item.invite.role,
        type: 'INVITE',
        email: item.invite.email,
        mobileNumber: item.invite?.mobileNumber || '',
        fullTimeEquivalent: item.invite.fullTimeEquivalent,
        blocked: false,
        isSubscribedToMarketing: false,
        organisation: item.invite.organisation,
        projects: item.invite.projects,
        createdAt: item.invite.createdAt,
      };
    });
  };

  updateInvitation = async (
    id: string,
    data: EditUserValues
  ): Promise<InvitationResponse> => {
    let formatedData = data;
    if (data.role === 'OWNER' || data.role === 'ADMIN') {
      delete formatedData.projects;
    }
    const response = await axios.patch<InvitationResponse>(
      `/invites/${id}`,
      formatedData
    );
    return response.data;
  };

  updateUser = async (id: string, data: EditUserValues): Promise<User> => {
    let formatedData = data;
    if (data.role === 'OWNER' || data.role === 'ADMIN') {
      delete formatedData.projects;
    }

    const response = await axios.patch<User>(
      `/users/${id}`,
      omit(formatedData, ['email'])
    );

    return response.data;
  };

  deleteInvitation = async (id: string): Promise<InvitationResponse> => {
    const response = await axios.delete<InvitationResponse>(`/invites/${id}`);
    return response.data;
  };

  deleteUser = async (id: string): Promise<User> => {
    const response = await axios.delete<User>(`/users/${id}`);
    return response.data;
  };

  getMemberCount = async (): Promise<MemberCount> => {
    const response = await axios.get<{ seats: MemberCount }>(
      `/organisations/members/count`
    );
    return response.data.seats;
  };

  getSubscriptionDetails = async (): Promise<SubscriptionDetails> => {
    const response = await axios.get<SubscriptionDetails>(
      `/billing/subscriptions`
    );
    return response.data;
  };

  getInvoices = async (
    context: QueryFunctionContext
  ): Promise<{ data: Invoice[]; has_more: boolean }> => {
    const params = ['limit=10'];

    if (context.pageParam) {
      params.push(`starting_after=${context.pageParam}`);
    }

    const response = await axios.get<{ data: Invoice[]; has_more: boolean }>(
      `/billing/invoices?${params.join('&')}`
    );
    return response.data;
  };

  changeNumberOfSeats = async (data: {
    newNumberOfSeats: number;
  }): Promise<Organisation> => {
    const response = await axios.post<Organisation>(
      `/billing/actions/change-no-of-seats`,
      data
    );
    return response.data;
  };

  changeBillingFrequency = async (data: {
    newBillingFrequency: string;
  }): Promise<
    Pick<
      SubscriptionDetails,
      'billingFrequency' | 'currentPeriodEnd' | 'currentPeriodStart'
    >
  > => {
    const response = await axios.post<
      Pick<
        SubscriptionDetails,
        'billingFrequency' | 'currentPeriodEnd' | 'currentPeriodStart'
      >
    >(`/billing/actions/change-billing-frequency`, data);
    return response.data;
  };

  getPaymentMethod = async (): Promise<PaymentMethod> => {
    const response = await axios.get<PaymentMethod>(
      `/billing/payment-methods/default`
    );
    return response.data;
  };

  deleteOrganisation = async (): Promise<Organisation> => {
    const response = await axios.delete<Organisation>('/organisations');
    return response.data;
  };

  updateOrganisation = async (data: {
    name: string;
    workingHoursPerWeek: number;
  }) => {
    const response = await axios.patch<Organisation>('/organisations', data);
    return response.data;
  };

  requestOrganisationData = async () => {
    const response = await axios.post('/data-requests/organisation');
    return response.data;
  };

  cancelOrganisationDeletion = async () => {
    const response = await axios.post<Organisation>(
      '/organisations/actions/cancel-organisation-delete'
    );
    return response.data;
  };

  /**
   *
   * @param organisationId
   * Get a list who can be a project manager
   * users with VIEWER role are excluded
   */
  getPMUsers = async (organisationId: string): Promise<User[]> => {
    const q = query(
      collection(db, 'users'),
      where('organisation.id', '==', organisationId),
      where('role', '!=', 'VIEWER'),
      orderBy('role', 'desc'),
      orderBy('createdAt', 'desc')
    );

    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return [];
    }

    return querySnapshot.docs.map(doc => {
      return {
        id: doc.id,
        ...doc.data(),
      } as User;
    });
  };
}

const organisationService = new OrganisationService();

export default organisationService;
