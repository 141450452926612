import React, { ChangeEvent } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { PencilAltIcon, PlusIcon } from '@heroicons/react/solid';
import { usePermission } from '../../hooks/usePermission';

type StatusFilterProps = {
  onNewTask: () => void;
};

const StatusFilter: React.FC<StatusFilterProps> = props => {
  const history = useHistory();
  const permission = usePermission();

  const tabs = [
    { name: 'To Do', href: '/work-in-progress/to-do' },
    { name: 'In Progress', href: '/work-in-progress/in-progress' },
    { name: 'Done', href: '/work-in-progress/done' },
    { name: 'All', href: '/work-in-progress/all' },
  ];

  const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    history.push(e.currentTarget.value);
  };

  return (
    <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 mb-6">
      <div>
        <div className="sm:hidden pt-4 px-4">
          <label className="sr-only">Select a status</label>
          <select
            className="block w-full focus:ring-secondary-500 focus:border-secondary-500 select"
            defaultValue={history.location.pathname}
            onChange={handleSelect}
          >
            {tabs.map(tab => (
              <option key={tab.name} value={tab.href}>
                {tab.name}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="flex space-x-4" aria-label="Tabs">
            {tabs.map(tab => (
              <NavLink
                key={tab.name}
                to={tab.href}
                activeClassName="bg-secondary-50 text-secondary-700 hover:bg-secondary-50"
                className="text-gray-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md"
              >
                {tab.name}
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
      {permission.data?.isAdmin || permission.data?.isMember ? (
        <div className="md:ml-auto px-4 sm:p-0">
          <Link
            role="button"
            className="btn btn-secondary"
            to="/work-in-progress/templates"
          >
            <PencilAltIcon className="leading-icon" aria-hidden="true" />
            Manage Templates
          </Link>
          <button
            type="button"
            className="ml-4 btn btn-primary"
            onClick={props.onNewTask}
          >
            <PlusIcon className="leading-icon" aria-hidden="true" />
            New Task
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(StatusFilter);
