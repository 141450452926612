import React from 'react';
import {
  FastField,
  Field,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { UpdateUserDTO } from '../../types/user';
import authService from '../../services/authService';

import FormikInput from '../FormikInput';
import Spinner from '../../Spinner';

type AccountDetailsFormProps = {
  id: string;
  data: UpdateUserDTO;
};

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .required('Email is required'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  mobileNumber: Yup.string().required('Phone number is required'),
  isSubscribedToMarketing: Yup.boolean(),
});

const AccountDetailsForm: React.FC<AccountDetailsFormProps> = props => {
  const queryClient = useQueryClient();
  const updateUser = useMutation(authService.updateUser, {
    onError: () => {
      toast.error('Error occurred during the update.');
    },
    onSuccess: values => {
      queryClient.setQueryData('user', values);
    },
  });

  const initialValues: UpdateUserDTO = {
    firstName: props.data.firstName || '',
    lastName: props.data.lastName || '',
    email: props.data.email,
    mobileNumber: props.data.mobileNumber || '',
    isSubscribedToMarketing: props.data.isSubscribedToMarketing,
  };

  const handleOnSubmit = async (
    values: UpdateUserDTO,
    formikBag: FormikHelpers<UpdateUserDTO>
  ) => {
    try {
      await updateUser.mutateAsync({ data: values, id: props.id });
      formikBag.resetForm();
    } catch (e) {
      //
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleOnSubmit}
    >
      {({ isSubmitting, dirty }: FormikProps<UpdateUserDTO>) => (
        <Form className="space-y-6 mt-8" noValidate>
          <fieldset disabled={isSubmitting} className="space-y-6">
            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <FastField
                  type="text"
                  name="firstName"
                  component={FormikInput}
                  label="First Name"
                  placeholder="John"
                  autoFocus
                />
              </div>

              <div className="sm:col-span-3">
                <FastField
                  type="text"
                  name="lastName"
                  component={FormikInput}
                  label="Last Name"
                  placeholder="Doe"
                />
              </div>
            </div>
            <FastField
              type="email"
              name="email"
              component={FormikInput}
              label="Email address"
            />
            <FastField
              type="tel"
              name="mobileNumber"
              component={FormikInput}
              label="Phone Number"
              placeholder="+44 1234567"
            />
          </fieldset>
          <div className="relative flex items-start">
            <div className="flex items-center h-5">
              <Field
                id="marketing_email"
                aria-describedby="marketing_email-description"
                name="isSubscribedToMarketing"
                type="checkbox"
                className="form-checkbox focus:ring-secondary-700 h-4 w-4 text-secondary-700 border-gray-300 rounded cursor-pointer"
              />
            </div>
            <div className="ml-3 text-sm">
              <label
                htmlFor="marketing_email"
                className="font-medium text-gray-700 cursor-pointer"
              >
                Subscribe to Updates?
              </label>
              <p id="comments-description" className="text-gray-500">
                Get notified when we have feature updates, hints & tips
              </p>
            </div>
          </div>
          <div className="w-ful flex">
            <button
              type="submit"
              className="btn btn-primary ml-auto"
              disabled={isSubmitting || !dirty}
            >
              {isSubmitting ? (
                <Spinner className="h-4 w-4 trailing-icon" />
              ) : null}
              Update
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AccountDetailsForm;
