import React from 'react';
import { RadioGroup } from '@headlessui/react';
import clsx from 'clsx';
import { CheckCircleIcon } from '@heroicons/react/solid';

import { Price } from '../../types/checkout';

type SubscriptionTypeProps = {
  onChange: (fieldName: string, value: Price) => void;
  value: Price;
  prices: Array<Price>;
  title: string;
  description: string;
};

const Radio: React.FC<{ value: Price; price: number; name: string }> =
  props => {
    return (
      <RadioGroup.Option
        value={props.value}
        className={({ checked, active }) =>
          clsx(
            checked ? 'border-transparent' : 'border-gray-300',
            active ? 'ring-2 ring-secondary-500' : '',
            'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
          )
        }
      >
        {({ checked, active }) => (
          <>
            <div className="flex-1 flex">
              <div className="flex flex-col">
                <RadioGroup.Label
                  as="span"
                  className="block text-sm font-medium text-gray-900"
                >
                  {props.name}
                </RadioGroup.Label>
                <RadioGroup.Description
                  as="span"
                  className="flex items-center text-sm text-gray-500"
                >
                  Per seat
                </RadioGroup.Description>
                <RadioGroup.Description
                  as="span"
                  className="mt-2 text-sm font-medium text-gray-900"
                >
                  £{props.price.toFixed(2)}
                  <span className="text-gray-500"> /mo</span>
                </RadioGroup.Description>
              </div>
            </div>
            {checked ? (
              <CheckCircleIcon
                className="h-5 w-5 text-secondary-600"
                aria-hidden="true"
              />
            ) : null}
            <div
              className={clsx(
                active ? 'border' : 'border-2',
                checked ? 'border-secondary-500' : 'border-transparent',
                'absolute -inset-px rounded-lg pointer-events-none'
              )}
              aria-hidden="true"
            />
          </>
        )}
      </RadioGroup.Option>
    );
  };

const SubscriptionType: React.FC<SubscriptionTypeProps> = props => {
  const handleValueChange = (value: Price) => props.onChange('type', value);

  return (
    <div className="mb-6">
      <RadioGroup value={props.value} onChange={handleValueChange}>
        <RadioGroup.Label className="text-lg font-medium text-gray-900">
          {props.title}
        </RadioGroup.Label>
        <RadioGroup.Description className="text-sm text-gray-500">
          {props.description}
        </RadioGroup.Description>
        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
          <Radio
            price={props.prices[0].price}
            name="Monthly"
            value={props.prices[0]}
          />
          <Radio
            price={props.prices[1].price}
            name="Annual"
            value={props.prices[1]}
          />
        </div>
      </RadioGroup>
    </div>
  );
};

export default SubscriptionType;
