import { useQuery } from 'react-query';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { projectKeys } from '../config';
import { Project } from '../types/project';
import projectService from '../services/projectService';
import { usePermission } from './usePermission';
import { useUser } from './useUser';

export function useProjectsByBrand() {
  const { brandId } = useParams<{ brandId: string }>();
  const permission = usePermission();

  return useQuery(
    projectKeys.allByBrand(brandId),
    () => projectService.getProjectsByBrand(brandId),
    {
      enabled: !!brandId && !permission.isLoading && !!permission.data,
      onError: () => {
        toast.error('Try again! Something went wrong.');
      },
      select: projects => {
        return projects.filter(project => {
          if (!project.isSecret) return true;

          return permission.data?.canViewProjectById(project.id);
        });
      },
    }
  );
}

export function useProjects() {
  const permission = usePermission();
  const user = useUser();

  return useQuery(
    projectKeys.all,
    () => projectService.getProjects(user.data?.organisation.id || ''),
    {
      enabled:
        !permission.isLoading &&
        !!permission.data &&
        !user.isLoading &&
        !!user.data,
      onError: () => {
        toast.error('Try again! Something went wrong.');
      },
      select: projects => {
        return projects.filter(project => {
          if (!project.isSecret) return true;

          return permission.data?.canViewProjectById(project.id);
        });
      },
    }
  );
}
/**
 *
 * @param id Select single project by id
 */
export function useProject(id: string | null) {
  const { brandId } = useParams<{ brandId: string }>();

  return useQuery<Project[], any, Project | undefined>(
    projectKeys.allByBrand(brandId),
    () => projectService.getProjects(brandId),
    {
      enabled: !!brandId,
      select: data => {
        return data.find(project => project.id === id);
      },
      onError: () => {
        toast.error('Try again! Something went wrong.');
      },
    }
  );
}
